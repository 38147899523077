<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <div class="page-titles">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span style="cursor: pointer" (click)="back()">Classes</span>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:void(0)">Batch Detail View</a>
        </li>
      </ol>
    </div>

    <div class="batches_section">
      <h1 class="heading">Batches</h1>

      <div class="batches_div" *ngFor="let dt of detail">
        <div class="main_content">
          <div class="flex_class" style="margin: 25px 0px">
            <div>
              <p class="title">Batch Name</p>

              <p class="subject">{{ dt.batchName }}</p>
            </div>

            <div>
              <p class="title">Status</p>
              <p class="subject">{{dt.status}}</p>
            </div>
          </div>

          <div class="session" *ngFor="let timeTable of dt.timeTable">
            <div class="" style="width: 85% !important">
              <div>
                <p class="title">Session Name</p>
                <p class="subject">{{ timeTable.title }}</p>
              </div>

              <div class="" style="margin-left: 5px">
                <p class="title">Material</p>

                <div class="material">
                  <a
                    [href]="Server + material.path"
                    target="_blank"
                    *ngFor="let material of timeTable.materialId"
                  >
                    <div class="material_bg">
                      <div class="round_bg">
                        <img
                          src="../../assets/images/pdf_file_icon.png"
                          style="object-fit: cover"
                          alt=""
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <p class="title" style="margin-top: 25px">Description</p>

            <p class="subject">
              <span [innerHTML]="timeTable.description"></span>
            </p>

            <div class="flex_class">
              <div>
                <p class="title" style="margin-top: 25px">Date</p>

                <p class="subject">
                  {{
                    timeTable.date | date: "medium":"IST" | date: "dd/MM/yyyy"
                  }}
                </p>
              </div>
              <div>
                <p class="title" style="margin-top: 25px">Start Time</p>

                <p class="subject">
                  {{ timeTable.date | date: "medium":"IST" | date: "HH:mm a" }}
                </p>
              </div>

              <div>
                <p class="title" style="margin-top: 25px">End Time</p>

                <p class="subject">
                  {{
                    timeTable.endDate | date: "medium":"IST" | date: "HH:mm a"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--**********************************
    Content body end
***********************************-->

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { EducatorUserService } from './educator-user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-educator-user',
  templateUrl: './educator-user.component.html',
  styleUrls: ['./educator-user.component.css']
})
export class EducatorUserComponent implements OnInit {

  Form: FormGroup;

  search;
  fullName;
  legalName;
  email;
  mobile;
  address;
  credit;
  active;
  reason;
  id;
  index;
  data;
  educatorUserList = [];
  dropDownData = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;
  url;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private educatorUserService: EducatorUserService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      active: ['-', Validators.required],
      reason: ['-', Validators.nullValidator]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.url = this.apiConstant.url;
    this.total = "0";
    this.search = "";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    
    this.dropDownData = [
      {
        id: "-",
        text: "-- Select Status --",
        disabled: true,
        selected: true
      },
      {
        id: "Active",
        text: "Active",
      },
      {
        id: "Deactive",
        text: "Deactive",
      },
      {
        id: "Reject",
        text: "Reject",
      }
    ]
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  searchBtn() {
    this.pagination();
  }

  pagination() {
    if(this.search == "") {
      this.educatorUserService.total().then(data => {
        this.total = data.total;
        this.list();
      });
    }
    else {
      this.educatorUserService.totalSearch(this.search).then(data => {
        this.total = data.total;
        this.list();
      });
    }
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    if(this.search == "") {
      this.educatorUserService.pagination(data).then(data => this.educatorUserList = data.data);
    }
    else {
      this.educatorUserService.paginationSearch(data, this.search).then(data => this.educatorUserList = data.data);
    }
  }

  // insert(addmodel) {
  //   this.modalService.open(addmodel, { size: 'lg' });
  //   this.clear();
  // }

  clear() {
    // this.Form.reset();
    // this.addbtn = false;
    // this.updatebtn = false;
    this.deletebtn = false;
  }

  // add() {
  //   this.addbtn = true;
  //   this.educatorUserService.add(this.Form.value).then(data => {
  //     if(data.flag == true) {;
  //       this.educatorUserList.unshift(data.data);
  //       this.total = (parseInt(this.total) + 1).toString();
  //       this.toastr.successToastr(data.message, 'Educator User Added!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.clear();
  //     }
  //     else {
  //       this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.addbtn = false; 
  //       this.modalService.dismissAll();
  //     }
  //   });
  // }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    if(type == 'act' || type == 'del') {
      this.modalService.open(type, { size: 'sm' });
    }
    else {
      this.modalService.open(type, { size: 'xl' });
    }
    this.index = i;
    this.id = data._id;
    this.fullName = data.fullName;
    this.legalName = data.legalName;
    this.email = data.email;
    this.mobile = data.mobile;
    this.address = data.address;
    this.credit = data.credit;
    this.active = data.active;
    this.reason = data.accountSuspended == null || data.accountSuspended.reason == undefined ? "" : data.accountSuspended.reason;
    this.data = data;
  }

  // update() {
  //   this.updatebtn = true;
  //   this.educatorUserService.update(this.Form.value, this.id).then(data => {
  //     if(data.flag == true) {
  //       this.educatorUserList[this.index] = data.data;
  //       this.toastr.successToastr(data.message, 'Educator User Updated!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.clear();
  //     }
  //     else {
  //       this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.updatebtn = false;
  //     }
  //   });
  // }

  delete() {
    this.deletebtn = true;
    this.educatorUserService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.educatorUserList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Educator User Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  status() {
    // var json = {
    //   active: this.active
    // }
    this.educatorUserService.active(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.educatorUserList[this.index].active = data.data.active;
        this.educatorUserList[this.index].accountSuspended.reason = data.data.reason;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }

  openFile(url: string) {
    window.open(url, "_blank");
  }
}
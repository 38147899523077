<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <div class="page-titles">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span style="cursor: pointer" (click)="back()">Classes</span>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:void(0)">Class Detail View</a>
        </li>
      </ol>
    </div>

    <div class="my_classes_section">
      <h1 class="heading">My Classes</h1>

      <div class="class_detail_div">
        <h2>Class Details</h2>

        <div class="main_content">
          <div class="flex_class">
            <div>
              <p class="title">Class Title</p>
              <p class="subject">{{ detail.title }}</p>
            </div>

            <div>
              <p class="title">Status</p>
              <p class="subject">{{detail.inReview == true ? 'In Review' : detail.status}}</p>
            </div>
          </div>

          <p class="title">Class Description</p>

          <p class="subject">
            <span [innerHTML]="detail.courseDescription"></span>
          </p>

          <div class="flex_class">
            <div>
              <p class="title">Category</p>

              <p class="subject">{{ detail.category.name }}</p>
            </div>

            <div>
              <p class="title">Sub Category</p>

              <p class="subject">{{ detail.subCategory.name }}</p>
            </div>
          </div>

          <div class="flex_class">
            <div>
              <p class="title">Class primary language will be</p>

              <p class="subject">{{ detail.primaryLanguage.name }}</p>
            </div>

            <div>
              <p class="title">Class secondary language will be</p>

              <p class="subject">{{ detail.language }}</p>
            </div>
          </div>

          <p class="title" style="margin-top: 25px">Images</p>

          <div class="image_grid" *ngFor="let image of detail.images">
            <img [hidden]="image.path == ''" [src]="Server + image.path" alt="" />
            
          </div>

          <p class="title">Video</p>
          <video
            [src]="detail.video.path"
            style="
              width: 233px;
              height: 200px;
              object-fit: cover;
              border-radius: 15px;
            "
            controls
          >
            <source type="video/mp4" />
            <source type="video/ogg" />
            <source type="video/webm" />
          </video>

          <div class="flex_class" style="margin-top: 25px">
            <div>
              <p class="title">Age Minimum</p>

              <p class="subject">{{ detail.ageGroup.minimum }}</p>
            </div>

            <div>
              <p class="title">Age Maximum</p>

              <p class="subject">{{ detail.ageGroup.maximum }}</p>
            </div>

            <div>
              <p class="title">Class Size</p>

              <p class="subject">{{ detail.classSize }}</p>
            </div>
          </div>

          <div class="flex_class">
            <div>
              <p class="title">Price</p>

              <p class="subject" [hidden]="detail.free">₹ {{ detail.price }} Per Session</p>
              <p class="subject" [hidden]="!detail.free">Free</p>
            </div>

            <div>
              <p class="title">Keywords</p>

              <p class="subject">{{ detail.keyword }}</p>
            </div>
          </div>

          <p class="title" style="margin-top: 25px">Requirements</p>
          <div class="requirements">
            <img
              *ngFor="let requirementIcon of detail.requirementIcon"
              [src]="Server + requirementIcon.path"
              style="
                width: 125px;
                height: 125px;
                object-fit: contain;
                border-radius: 15px;
              "
              alt=""
            />
          </div>

          <div style="margin-top: 25px">
            <p class="title">Additional Requirements</p>

            <p class="subject">
              <span [innerHTML]="detail.materialDescription"></span>
            </p>

            <p class="title">Parental Guidance</p>

            <p class="subject">
              <span [innerHTML]="detail.parentalGuidance"></span>
            </p>

            <p class="title">Additional Notes</p>

            <p class="subject">
              <span [innerHTML]="detail.additionalNotes"></span>
            </p>
          </div>
        </div>
      </div>

      <div class="material_div">
        <h2>Material</h2>

        <div>
          <!------------ Material ------------->

          <div style="margin-bottom: 25px">
            <p class="title">Material</p>

            <div class="material_flex_class">
              <a
                [href]="Server + image.path"
                target="_blank"
                *ngFor="let image of detail.images"
              >
                <div class="material_bg">
                  <div class="round_bg">
                    <img
                      src="../../assets/images/pdf_file_icon.png"
                      style="object-fit: cover"
                      alt=""
                    />
                  </div>
                </div>
              </a>

              
            </div>
          </div>

          <!------------ cetificate ------------->
          <div style="margin-bottom: 25px" [hidden]="!detail.certificate">
            <p class="title">Certificate</p>
            <div class="material_flex_class">
              <img [hidden]="detail.certificateData == null || detail.certificateData.path == ''"
                [src]="detail.certificateData != null ? Server + detail.certificateData.path : ''"
                style="
                  width: 295px;
                  height: 200px;
                  object-fit: cover;
                  border-radius: 3px;
                "
                alt=""
              />
            </div>
          </div>

          <!------------ Signature ------------->

          <div [hidden]="!detail.certificate">
            <p class="title">Signature</p>

            <img [hidden]="detail.signature.path == ''"
              [src]="Server + detail.signature.path"
              style="
                width: 233px;
                height: 200px;
                object-fit: cover;
                border-radius: 15px;
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--**********************************
    Content body end
***********************************-->

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  signin(data) {
    return this.httpmanagerService.HttpPost('admin/signin',JSON.stringify(data),"").then(data => {
      return data;
    });
  }

  forgotPassword(data) {
    return this.httpmanagerService.HttpPost('admin/forgotPassword',JSON.stringify(data),"").then(data => {
      return data;
    });
  }

  resetPassword(data, id) {
    return this.httpmanagerService.HttpPost('admin/resetPassword',JSON.stringify(data), id).then(data => {
      return data;
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-available-ticket',
  templateUrl: './available-ticket.component.html',
  styleUrls: ['./available-ticket.component.css']
})
export class AvailableTicketComponent implements OnInit {

constructor() { }

  ngOnInit(): void {
  }
  
  chartOptions = {
      series: [90],
      chart: {
        height: 110,
        width: 110,
        type: "radialBar",
		zoom: {
			enabled: false
		},
		sparkline: {
			enabled: true
		}
      },
      legend: {
		show:false,
      },
	  colors:['#FE634E'],
	  plotOptions: {
        radialBar: {
          
          hollow: {
            margin: 0,
            size: "50%",
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
			 offsetY: 7,
              show: true
            }
          }
        }
      },
      labels: ["Events"]
    };

}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class EducatorUserService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  // list() {
  //   return this.httpmanagerService.HttpGet('educator', "").then(data => {
  //     return data.data;
  //   });
  // }

  // update(data, id) {
  //   return this.httpmanagerService.HttpPut('educator', JSON.stringify(data), id).then(data => {
  //     return data;
  //   });
  // }

  delete(id) {
    return this.httpmanagerService.HttpDelete('educator', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('educator/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('educator/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('educator/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  totalSearch(id) {
    return this.httpmanagerService.HttpGet('educator/search', id).then(data => {
      return data;
    });
  }

  paginationSearch(data, id) {
    return this.httpmanagerService.HttpPost('educator/search', JSON.stringify(data), id).then(data => {
      return data;
    });
  }
}

<div class="card-header flex-wrap border-0 pb-0">
	<h4 class="fs-20 text-black">Latest Sales</h4>
	<mat-form-field class="dashboard-select" appearance="fill">
	  <mat-select [(value)]="selected">
		<mat-option *ngFor="let timeVal of timePeriod" [value]="timeVal.value">
		  {{timeVal.viewValue}}
		</mat-option>
	  </mat-select>
	</mat-form-field> 
</div>
<div class="card-body pb-0 dz-scroll latest-salebx loadmore-content" id="latestSalesContent" [perfectScrollbar]>
	<div class="pb-3 mb-3 border-bottom">
		<p class="font-w600"><a [routerLink]="['/admin/event']" class="text-black">The Story’s of Danau Toba (Drama Theater)</a></p>
		<div class="d-flex align-items-end">
			<img src="assets/images/profile/20.jpg" alt="" width="42" class="rounded-circle mr-2">
			<div class="mr-auto">
				<h4 class="fs-14 mb-0"><a [routerLink]="['/admin/app-profile']"  class="text-black">Steffany Humble</a></h4>
				<span class="fs-12">2m ago</span>
			</div>
			<span class="badge badge-sm light badge-primary">4 Ticket</span>
		</div>
	</div>
	<div class="pb-3 mb-3 border-bottom">
		<p class="font-w600"><a [routerLink]="['/admin/event']" class="text-black">Envato Author SF Meetup</a></p>
		<div class="d-flex align-items-end">
			<img src="assets/images/profile/21.jpg" alt="" width="42" class="rounded-circle mr-2">
			<div class="mr-auto">
				<h4 class="fs-14 mb-0"><a [routerLink]="['/admin/app-profile']" class="text-black">Jacob Swing Swing</a></h4>
				<span class="fs-12">6h ago</span>
			</div>
			<span class="badge badge-sm light badge-primary">2 Ticket</span>
		</div>
	</div>
	<div class="pb-3 mb-3 border-bottom">
		<p class="font-w600"><a [routerLink]="['/admin/event']" class="text-black">Envato Atuhor Community Fun Hiking at Sibayak Mountaint</a></p>
		<div class="d-flex align-items-end">
			<img src="assets/images/profile/22.jpg" alt="" width="42" class="rounded-circle mr-2">
			<div class="mr-auto">
				<h4 class="fs-14 mb-0"><a [routerLink]="['/admin/app-profile']" class="text-black">Robert Carloz</a></h4>
				<span class="fs-12">10h ago</span>
			</div>
			<span class="badge badge-sm light badge-primary">1 Ticket</span>
		</div>
	</div>
	<div class="pb-3 mb-3 border-bottom">
		<p class="font-w600"><a [routerLink]="['/admin/event']" class="text-black">Indonesia Envato Authors National Meetup</a></p>
		<div class="d-flex align-items-end">
			<img src="assets/images/profile/23.jpg" alt="" width="42" class="rounded-circle mr-2">
			<div class="mr-auto">
				<h4 class="fs-14 mb-0"><a [routerLink]="['/admin/app-profile']" class="text-black">Kevin Stefanus</a></h4>
				<span class="fs-12">2m ago</span>
			</div>
			<span class="badge badge-sm light badge-primary">1 Ticket</span>
		</div>
	</div>
	<div class="pb-3 mb-3 border-bottom">
		<p class="font-w600"><a [routerLink]="['/admin/event']" class="text-black">Envato Author SF Meetup</a></p>
		<div class="d-flex align-items-end">
			<img src="assets/images/profile/21.jpg" alt="" width="42" class="rounded-circle mr-2">
			<div class="mr-auto">
				<h4 class="fs-14 mb-0"><a [routerLink]="['/admin/app-profile']" class="text-black">Jacob Swing Swing</a></h4>
				<span class="fs-12">6h ago</span>
			</div>
			<span class="badge badge-sm light badge-primary">2 Ticket</span>
		</div>
	</div>
</div>
<div class="card-footer pt-0 border-0">
</div>
import { Component, OnInit } from '@angular/core';
import { TestimonialEducatorService } from './testimonial-educator.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-testimonial-educator',
  templateUrl: './testimonial-educator.component.html',
  styleUrls: ['./testimonial-educator.component.css']
})
export class TestimonialEducatorComponent implements OnInit {

  testimonialEducatorList = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  server;

  constructor(
    private testimonialEducatorService: TestimonialEducatorService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination()
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.testimonialEducatorService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.testimonialEducatorService.pagination(data).then(data => this.testimonialEducatorList = data.data);
  }

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }
    
    var json = {
      active: active
    }
    this.testimonialEducatorService.active(json, id).then(data => {
      if(data.flag == true) {
        this.testimonialEducatorList[i].testimonial.showStatus = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
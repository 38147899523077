import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ReferralFriendService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }
  
  total(status) {
    return this.httpmanagerService.HttpGet('referralfriend/pagination', status).then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('referralfriend/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BookingService } from './booking.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ApiConstant } from '../api.constant';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
        `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
        '';
  }
}

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class BookingComponent implements OnInit {

  // Form: FormGroup;

  search;
  // name;
  // id;
  // index;
  bookingList = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  // addbtn;
  // updatebtn;
  // deletebtn;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  footerTemplate;

  constructor(
    private modalService: NgbModal,
    // private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private toastr: ToastrManager,
    private calendar: NgbCalendar,
    private apiConstant: ApiConstant
  ) { 
    // this.form();
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  // form() {
  //   this.Form = this.formBuilder.group({
  //     startDate: ['-', Validators.required], 
  //     endDate: ['-', Validators.required]
  //   });
  // }

  ngOnInit(): void {
    this.total = "0";
    this.search = "";
    // this.selectPageLimit = $("#SELECTPAGES").val();

    var dateObj = new Date();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: firstDay.getDate(),
      month: month,
      year: year
    }

    this.endDate = {
      day: lastDay.getDate(),
      month: month,
      year: year
    }

    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;

    this.pagination();
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    var search = this.search;
    if(search == "") {
      search = "-";
    }

    var startDate = this.startDate.year + '-' + this.startDate.month + '-' + this.startDate.day;
    startDate = new Date(startDate).toISOString();
    var endDate = this.endDate.year + '-' + this.endDate.month + '-' + this.endDate.day;
    endDate = new Date(endDate).toISOString();

    var json = {
      startDate: startDate,
      endDate: endDate
    }
    
    this.bookingService.total(json, search).then(data => {
        this.total = data.total;
        this.list();
    });
  }

  filter() {
    this.pagination();
  }

  clear() {
    this.search = "";

    var dateObj = new Date();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: firstDay.getDate(),
      month: month,
      year: year
    }

    this.endDate = {
      day: lastDay.getDate(),
      month: month,
      year: year
    }

    this.pagination();
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var search = this.search;
    if(search == "") {
      search = "-";
    }

    var startDate = this.startDate.year + '-' + this.startDate.month + '-' + this.startDate.day;
    startDate = new Date(startDate).toISOString();
    var endDate = this.endDate.year + '-' + this.endDate.month + '-' + this.endDate.day;
    endDate = new Date(endDate).toISOString();

    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit),
      startDate: startDate,
      endDate: endDate
    }
    this.bookingService.pagination(data, search).then(data => this.bookingList = data.data);
  }

  searchBtn() {
    this.pagination();
  }

  // clear() {
  //   this.Form.reset();
  //   this.addbtn = false;
  //   this.updatebtn = false;
  //   this.deletebtn = false;
  // }

  // show(data, type, i) {
  //   this.updatebtn = false;
  //   this.deletebtn = false;
  //   this.modalService.open(type, { size: 'lg' });
  //   this.index = i;
  //   this.id = data._id;
  //   this.name = data.name;
  // }
}
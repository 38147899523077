import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ChildHelpDeskService } from './child-help-desk.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
// import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ApiConstant } from '../api.constant';


// @Injectable()
// export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
//   parse(value: string): NgbDateStruct | null {
//     if (value) {
//       const dateParts = value.trim().split('-');

//       let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
//       const dateLabels = Object.keys(dateObj);

//       dateParts.forEach((datePart, idx) => {
//         dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
//       });
//       return dateObj;
//     }
//     return null;
//   }

//   format(date: NgbDateStruct | null): string {
//     return date ?
//         `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
//         '';
//   }
// }

// function padNumber(value: number | null) {
//   if (!isNaN(value) && value !== null) {
//     return `0${value}`.slice(-2);
//   } else {
//     return '';
//   }
// }

@Component({
  selector: 'app-child-help-desk',
  templateUrl: './child-help-desk.component.html',
  styleUrls: ['./child-help-desk.component.css'],
  // providers: [
  //   {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  //  ]
})
export class ChildHelpDeskComponent implements OnInit {

  Form: FormGroup;

  message;
  status;
  type;
  educatorId;
  educatorName;
  educatorProfilePic;
  childId;
  childName;
  childProfilePic;
  // bankDetail;
  collections;
  server;
  id;
  index;
  childHelpDeskList = [];
  // statusData;
  // startDate: NgbDateStruct;
  // endDate: NgbDateStruct;
  ckeConfig: any;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private childHelpDeskService: ChildHelpDeskService,
    private toastr: ToastrManager,
    // private calendar: NgbCalendar,
    private apiConstant: ApiConstant,
    private router: Router,
    private titleService: Title,
  ) {
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);

    this.ckeConfig = {
      extraPlugins:
        "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
        "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
        "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
        "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
        "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
        "indentblock,indentlist,justify,link,list,liststyle,magicline," +
        "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
        "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
        "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
        "tabletools,templates,toolbar,undo,wsc,wysiwygarea"
    };
  }

  form() {
    this.Form = this.formBuilder.group({
      // status: ['-', Validators.required],
      message: ['', Validators.required]
      // startDate: ['-', Validators.required],
      // endDate: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();

    // this.statusData = [{
    //   id: "Pending",
    //   text: "Pending",
    //   disabled: true
    // },
    // {
    //   id: 'In Progress',
    //   text: 'In Progress',
    // },
    // {
    //   id: 'Close',
    //   text: 'Close'
    // }];

    // var dateObj = new Date();
    // var month = dateObj.getUTCMonth() + 1; //months from 1-12
    // var day = dateObj.getUTCDate();
    // var year = dateObj.getUTCFullYear();

    // this.startDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
    // this.endDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    // var data = {
    //   status: this.status
    // }

    // if(this.FormEud.value.startDate != '-') {
    //   this.FormEud.value.startDate = this.FormEud.value.startDate.year + '-' + this.FormEud.value.startDate.month + '-' + this.FormEud.value.startDate.day;
    //   this.FormEud.value.startDate = new Date(this.FormEud.value.startDate).toISOString();
    // }
    // if(this.FormEud.value.endDate != '-') {
    //   this.FormEud.value.endDate = this.FormEud.value.endDate.year + '-' + this.FormEud.value.endDate.month + '-' + (this.FormEud.value.endDate.day + 1);
    //   this.FormEud.value.endDate = new Date(this.FormEud.value.endDate).toISOString();
    // }


    this.childHelpDeskService.total().then(data => {
        this.total = data.total;
        this.list();
    });
    // this.list();
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.childHelpDeskService.pagination(data).then(data => this.childHelpDeskList = data.data);
  }

  // onType(event) {
  //   this.status = event;
  // }

  show(data, type, i) {
    console.log(data)
    this.addbtn = false;
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.status = data.status;
    this.type = data.type;
    this.educatorId = data.educatorId;
    this.educatorName = data.educatorName;
    this.educatorProfilePic = data.educatorProfilePic;
    this.childId = data.childId;
    this.childName = data.childName;
    this.childProfilePic = data.childProfilePic;
    // this.bankDetail = data.bankDetail;
    this.collections = data.collections;
    this.message = '';
    // if(this.status == 'In Progress') {
    //   var istatus = this.statusData.findIndex(x => x.id == this.status);
    //   if(istatus != -1) {
    //     this.statusData[istatus].disabled = true;
    //   }
    // }
  }

  add() {
    this.Form.value.id = this.id;
    this.Form.value.type = 'Admin';
    this.addbtn = true;
    this.childHelpDeskService.add(this.Form.value).then(data => {
      if(data.flag == true) {
        this.collections.push(data.data);
        if(this.status != 'In Progress' && this.status != 'Approved' && this.status != 'Rejected') {
          this.status = 'In Progress';
        }
        // this.Form.reset();
        // this.Form.markAsPristine();
	      // this.Form.markAsUntouched();
        this.message = '';
        this.toastr.successToastr(data.message, 'Ticket Added!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.addbtn = false;
    });
  }

  ticketClose() {
    var json = {
      id: this.id,
      status: 'Close'
    }

    this.childHelpDeskService.status(json).then(data => {
      if(data.flag == true) {;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
        this.childHelpDeskList[this.index].status = data.data;
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.modalService.dismissAll();
    });
  }

  ticketApproved() {
    this.childHelpDeskService.refundApprove(this.id).then(data => {
      if(data.flag == true) {;
        this.childHelpDeskList[this.index].status = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.modalService.dismissAll();
    });
  }

  ticketRejected() {
    this.childHelpDeskService.refundReject(this.id).then(data => {
      if(data.flag == true) {
        this.childHelpDeskList[this.index].status = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.modalService.dismissAll();
    });
  }

  // beneficiary() {
  //   this.modalService.dismissAll();
  //   this.router.navigate(['/admin/beneficiary-parent']);
  //   this.titleService.setTitle('Add Beneficiary Parent' + ' - Meeko');
  // }
}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data, video, image) {
    return this.httpmanagerService.HttpPostMultiFile('tutorial', image, video, JSON.stringify(data), "",).then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('tutorial', "").then(data => {
      return data.data;
    });
  }

  update(data, id, video, image, oldvideo, oldimage) {
    if(image.length > 0) {
      data.oldvideo = oldvideo; 
      data.oldimage = oldimage; 
      return this.httpmanagerService.HttpPostMultiFile('tutorial/update', image, video, JSON.stringify(data), id).then(data => {
        return data;
      });
    }
    else {
      return this.httpmanagerService.HttpPut('tutorial', JSON.stringify(data), id).then(data => {
        return data;
      });
    }
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('tutorial', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('tutorial/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('tutorial/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('tutorial/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { PreferenceService } from './preference.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css']
})
export class PreferenceComponent implements OnInit {

  FormCF: FormGroup;
  FormRCF: FormGroup;
  FormMC: FormGroup;
  FormRF: FormGroup;
  FormAG: FormGroup;
  FormFD: FormGroup;
  FormPD: FormGroup;
  FormTU: FormGroup;

  active = 1;
  convenienceFees;
  referCommissionFees;
  referAmount;
  meekoCoin;
  amount;
  referByELearner;
  referToELearner;
  referByEducator;
  referToEducator;
  index;
  id;
  boyPath;
  // boyTopPath;
  // boyBottomPath;
  girlPath;
  // girlTopPath;
  // girlBottomPath;
  minimumAge;
  maximumAge;
  AGBtn = false;
  ageGroupList;
  FDBtn = false;
  packageData = [];
  packageName;
  PDBtn = false;
  educatorPackageData = [];
  educatorPackageName;
  dropDownData = [];
  updatebtn;
  hours;
  hourPrice;
  driveSpace;
  driveSpacePrice;

  constructor(
    private formBuilder: FormBuilder,
    private preferenceService: PreferenceService,
    private toastr: ToastrManager
  ) {
    this.formCF();
    this.formRCF();
    this.formMC();
    this.formRF();
    this.formAG();
    this.formFD();
    this.formPD();
    this.formTU();
    this.dropDownData = [
      {
        id: "Days",
        text: "Days"
      },
      {
        id: "Months",
        text: "Months"
      },
      {
        id: "Years",
        text: "Years"
      }
    ];
  }

  formCF() {
    this.FormCF = this.formBuilder.group({
      convenienceFees: ['-', Validators.required]
    });
  }

  formRCF() {
    this.FormRCF = this.formBuilder.group({
      referCommissionFees: ['-', Validators.required],
      referAmount: ['-', Validators.required]
    });
  }

  formMC() {
    this.FormMC = this.formBuilder.group({
      meekoCoin: ['-', Validators.required],
      amount: ['-', Validators.required],
    });
  }

  formRF() {
    this.FormRF = this.formBuilder.group({
      referByELearner: ['-', Validators.required],
      referToELearner: ['-', Validators.required],
      referByEducator: ['-', Validators.required],
      referToEducator: ['-', Validators.required]
    });
  }

  formAG() {
    this.FormAG = this.formBuilder.group({
      id: ['', Validators.required],
      boyPath: ['', Validators.required],
      // boyTopPath: ['', Validators.required],
      // boyBottomPath: ['', Validators.required],
      girlPath: ['', Validators.required],
      // girlTopPath: ['', Validators.required],
      // girlBottomPath: ['', Validators.required],
      minimumAge: ['', Validators.required],
      maximumAge: ['', Validators.required]
    });
  }

  formFD() {
    this.FormFD = this.formBuilder.group({
      packageName: ['-', Validators.required]
    });
  }

  formPD() {
    this.FormPD = this.formBuilder.group({
      educatorPackageName: ['-', Validators.required]
    });
  }

  formTU() {
    this.FormTU = this.formBuilder.group({
      hours: ['-', Validators.required],
      hourPrice: ['-', Validators.required],
      driveSpace: ['-', Validators.required],
      driveSpacePrice: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.preferenceService.list().then(data => {
      if(data.length > 0) {
        data.forEach(element => {
          if(element.key == 'ConvenienceFees') {
            this.convenienceFees = JSON.parse(element.value).convenienceFees
          }
          if(element.key == 'ReferCommissionFees') {
            this.referCommissionFees = JSON.parse(element.value).referCommissionFees,
            this.referAmount = JSON.parse(element.value).referAmount
          }
          else if(element.key == 'MeekoCoin') {
            this.meekoCoin = JSON.parse(element.value).meekoCoin;
            this.amount = JSON.parse(element.value).amount;
          }
          else if(element.key == 'ReferFriend') {
            this.referByELearner = JSON.parse(element.value).referByELearner;
            this.referToELearner = JSON.parse(element.value).referToELearner;
            this.referByEducator = JSON.parse(element.value).referByEducator;
            this.referToEducator = JSON.parse(element.value).referToEducator;
          }
          else if(element.key == 'AgeGroup') {
            this.ageGroupList = JSON.parse(element.value);
          }
          else if(element.key == 'PackageDetail') {
            this.packageData = JSON.parse(element.value);
          }
          else if(element.key == 'EducatorPackageDetail') {
            this.educatorPackageData = JSON.parse(element.value);
          }
          else if(element.key == 'TopUp') {
            var topUpData = JSON.parse(element.value);
            this.hours = parseInt(topUpData.hours);
            this.hourPrice = parseInt(topUpData.hourPrice);
            this.driveSpace = parseInt(topUpData.driveSpace);
            this.driveSpacePrice = parseInt(topUpData.driveSpacePrice);
          }
        });
      }
    });
  }

  updateCF() {
    this.updatebtn = true;
    this.FormCF.value.convenienceFees = parseInt(this.FormCF.value.convenienceFees);
    var json = {
      key: 'ConvenienceFees',
      value: JSON.stringify(this.FormCF.value)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.toastr.successToastr(data.message, 'Convenience Fees Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.updatebtn = false;
    });
  }

  updateRCF() {
    this.updatebtn = true;
    this.FormRCF.value.referCommissionFees = parseInt(this.FormRCF.value.referCommissionFees);
    this.FormRCF.value.referAmount = parseInt(this.FormRCF.value.referAmount);
    var json = {
      key: 'ReferCommissionFees',
      value: JSON.stringify(this.FormRCF.value)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.toastr.successToastr(data.message, 'Refer Commission Fees Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.updatebtn = false;
    });
  }

  updateMC() {
    this.updatebtn = true;
    this.FormMC.value.meekoCoin = parseInt(this.FormMC.value.meekoCoin);
    this.FormMC.value.amount = parseFloat(this.FormMC.value.amount);
    var json = {
      key: 'MeekoCoin',
      value: JSON.stringify(this.FormMC.value)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.toastr.successToastr(data.message, 'Meeko Coin Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.updatebtn = false;
    });
  }

  updateRF() {
    this.updatebtn = true;
    this.FormRF.value.referByELearner = parseInt(this.FormRF.value.referByELearner);
    this.FormRF.value.referToELearner = parseInt(this.FormRF.value.referToELearner);
    this.FormRF.value.referByEducator = parseInt(this.FormRF.value.referByEducator);
    this.FormRF.value.referToEducator = parseInt(this.FormRF.value.referToEducator);
    var json = {
      key: 'ReferFriend',
      value: JSON.stringify(this.FormRF.value)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.toastr.successToastr(data.message, 'Refer Friend Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.updatebtn = false;
    });
  }

  updateAG() {
    this.updatebtn = true;
    if(this.AGBtn == true && this.index != undefined && this.index >= 0) {
      this.ageGroupList[this.index] = this.FormAG.value;
    }
    else {
      this.ageGroupList.push(this.FormAG.value);
    }
    var json = {
      key: 'AgeGroup',
      value: JSON.stringify(this.ageGroupList)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.toastr.successToastr(data.message, 'Age Group Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.ageGroupList.splice(-1,1);
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.FormAG.reset();
      this.updatebtn = false;
      this.AGBtn = false;
      this.index = -1;
    });
  }

  action(data, type, i) {
    if(type == 'edit') {
      this.index = i;
      this.id = data.id;
      this.minimumAge = data.minimumAge;
      this.maximumAge = data.maximumAge;
      this.boyPath = data.boyPath;
      // this.boyTopPath = data.boyTopPath;
      // this.boyBottomPath = data.boyBottomPath;
      this.girlPath = data.girlPath;
      // this.girlTopPath = data.girlTopPath;
      // this.girlBottomPath = data.girlBottomPath;
      this.AGBtn = true;
    }
    else {
      this.ageGroupList.splice(i,1);
      var json = {
        key: 'AgeGroup',
        value: JSON.stringify(this.ageGroupList)
      }
      this.preferenceService.add(json).then(data => {
        if(data.flag == true) {
          this.toastr.successToastr(data.message, 'Age Group Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        }
        else {
          this.ageGroupList.splice(-1,1);
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        }
      });
    }
  }

  AGCancel() {
    this.FormAG.reset();
    this.updatebtn = false;
    this.AGBtn = false;
    this.index = -1;
  }

  FDCancel() {
    this.FormFD.reset();
    this.updatebtn = false;
    this.FDBtn = false;
    this.index = -1;
  }

  updateFD() {
    this.updatebtn = true;
    if(this.FDBtn == true && this.index != undefined && this.index >= 0) {
      this.packageData[this.index] = this.FormFD.value;
    }
    else {
      this.packageData.push(this.FormFD.value);
    }

    var json = {
      key: 'PackageDetail',
      value: JSON.stringify(this.packageData)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.packageName = "";
        this.toastr.successToastr(data.message, 'Package Detail Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.updatebtn = false;
      this.FormFD.reset();
      this.FDBtn = false;
      this.index = -1;
    });
  }

  actionFD(data, type, i) {
    if(type == 'edit') {
      this.index = i;
      this.FDBtn = true;
      this.packageName = data.packageName;
    }
    else {
      this.packageData.splice(i, 1);

      var json = {
        key: 'PackageDetail',
        value: JSON.stringify(this.packageData)
      }
      this.preferenceService.add(json).then(data => {
        if(data.flag == true) {
          this.packageName = "";
          this.toastr.successToastr(data.message, 'Package Detail Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        }
        this.updatebtn = false;
        this.FormFD.reset();
        this.FDBtn = false;
        this.index = -1;
      });
    }
  }

  PDCancel() {
    this.FormPD.reset();
    this.updatebtn = false;
    this.PDBtn = false;
    this.index = -1;
  }

  updatePD() {
    this.updatebtn = true;
    if(this.PDBtn == true && this.index != undefined && this.index >= 0) {
      this.educatorPackageData[this.index] = this.FormPD.value;
    }
    else {
      this.educatorPackageData.push(this.FormPD.value);
    }

    var json = {
      key: 'EducatorPackageDetail',
      value: JSON.stringify(this.educatorPackageData)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.educatorPackageName = "";
        this.toastr.successToastr(data.message, 'Educator Package Detail Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.updatebtn = false;
      this.FormPD.reset();
      this.PDBtn = false;
      this.index = -1;
    });
  }

  actionPD(data, type, i) {
    if(type == 'edit') {
      this.index = i;
      this.PDBtn = true;
      this.educatorPackageName = data.educatorPackageName;
    }
    else {
      this.educatorPackageData.splice(i, 1);

      var json = {
        key: 'EducatorPackageDetail',
        value: JSON.stringify(this.educatorPackageData)
      }
      this.preferenceService.add(json).then(data => {
        if(data.flag == true) {
          this.educatorPackageName = "";
          this.toastr.successToastr(data.message, 'Educator Package Detail Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        }
        this.updatebtn = false;
        this.FormPD.reset();
        this.PDBtn = false;
        this.index = -1;
      });
    }
  }

  updateTU() {
    this.updatebtn = true;
    this.FormTU.value.hours = parseInt(this.FormTU.value.hours);
    this.FormTU.value.hourPrice = parseInt(this.FormTU.value.hourPrice);
    this.FormTU.value.driveSpace = parseInt(this.FormTU.value.driveSpace);
    this.FormTU.value.driveSpacePrice = parseInt(this.FormTU.value.driveSpacePrice);
    var json = {
      key: 'TopUp',
      value: JSON.stringify(this.FormTU.value)
    }
    this.preferenceService.add(json).then(data => {
      if(data.flag == true) {
        this.toastr.successToastr(data.message, 'Top Up Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.updatebtn = false;
    });
  }
}

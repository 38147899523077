import { Component, OnInit, Injectable } from '@angular/core';
import { EducatorFinanceService } from './educator-finance.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-educator-finance',
  templateUrl: './educator-finance.component.html',
  styleUrls: ['./educator-finance.component.css']
})
export class EducatorFinanceComponent implements OnInit {

  search;
  name;
  id;
  index;
  educatorFinanceList = [];
  statusData;
  status = 'Pending';

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private educatorFinanceService: EducatorFinanceService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  ngOnInit(): void {
    this.total = "0";
    this.search = "";
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
   
    const statusEducatorFinance = localStorage.getItem('statusEducatorFinance');
    if(statusEducatorFinance != null) {
      this.status = statusEducatorFinance;
    }
    this.pagination();

    this.statusData = [{
      id: 'Pending',
      text: 'Pending',
    },
    {
      id: 'In Progress',
      text: 'In Progress'
    },
    {
      id: 'Success',
      text: 'Success'
    }];
  }

  searchBtn() {
    this.pagination();
  }

  pagination() {
    var data = {
      status: this.status
    }

    if(this.search == "") {
      this.educatorFinanceService.total(data).then(data => {
          this.total = data.total;
          this.list();
      });
    }
    else {
      this.educatorFinanceService.totalSearch(data, this.search).then(data => {
        this.total = data.total;
        this.list();
      });
    }
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit),
      status: this.status
    }
    if(this.search == "") {
      this.educatorFinanceService.pagination(data).then(data => this.educatorFinanceList = data.data);
    }
    else {
      this.educatorFinanceService.paginationSearch(data, this.search).then(data => this.educatorFinanceList = data.data);
    }
  }

  onType(event) {
    this.status = event;
    localStorage.setItem('statusEducatorFinance', this.status);
    this.pagination();
  }
}
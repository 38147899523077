import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BitmojiAccessService } from './bitmoji-access.service';
import { BitmojiCategoryService } from '../bitmoji-category/bitmoji-category.service';
import { BadgeLevelService } from '../badge-level/badge-level.service';
import { PreferenceService } from '../preference/preference.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-bitmoji-access',
  templateUrl: './bitmoji-access.component.html',
  styleUrls: ['./bitmoji-access.component.css']
})
export class BitmojiAccessComponent implements OnInit {

  Form: FormGroup;
  FormM: FormGroup;

  badgeLevelId;
  bitmojiCategoryId;
  filesupload;
  fileId;
  aId;
  id;
  imgid;
  category;
  gender;
  meekoin;
  ageGroup;
  badgeName;
  default;
  index;
  bitmojiAccessList = [];
  badgeLevelList = [];
  bitmojiCategoryList = [];
  ageGroupList = [];
  // oldAgeGroupList = [];
  dropDownData = [];
  dropDownData1 = [];
  genderDropDownData = [];
  ageGroupData = [];
  uploadGLTF = [];
  uploadImage = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;
  imagepath;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private bitmojiAccessService: BitmojiAccessService,
    private bitmojiCategoryService: BitmojiCategoryService,
    private badgeLevelService: BadgeLevelService,
    private preferenceService: PreferenceService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();
    this.formM();
  }

  form() {
    this.Form = this.formBuilder.group({
      badgeLevelId: ['-', Validators.required],
      bitmojiCategoryId: ['-', Validators.required],
      gender: ['-', Validators.required],
      meekoin: ['-', Validators.required],
      ageGroup: ['-', Validators.required],
      avatarsGLTF: ['-', Validators.required],
      avatars: ['-', Validators.required],
      default: ['-', Validators.nullValidator]
    });
  }

  formM() {
    this.FormM = this.formBuilder.group({
      meekoin: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData.push({
      id: "-",
      text: "-- Select Bitmoji Category --",
      disabled: true,
      selected: true
    });
    this.bitmojiCategoryService.list().then(data => {
      this.bitmojiCategoryList = data;
      for (let index = 0; index < this.bitmojiCategoryList.length; index++) {
        const element = this.bitmojiCategoryList[index];
        this.dropDownData.push({
          id: element._id,
          text: element.name
        });
      }
    });
    this.dropDownData1.push({
      id: "-",
      text: "-- Select Badge Level --",
      disabled: true,
      selected: true
    });
    this.badgeLevelService.list({id: this.apiConstant.badgeLevelELearnerId}).then(data => {
      this.badgeLevelList = data;
      for (let index = 0; index < this.badgeLevelList.length; index++) {
        const element = this.badgeLevelList[index];
        this.dropDownData1.push({
          id: element._id,
          text: element.badgeName
        });
      }
    });
    this.genderDropDownData = [{
      id: "-",
      text: "-- Select Gender --",
      disabled: true,
      selected: true
    },
    {
      id: "Male",
      text: "Male"
    },
    {
      id: "Female",
      text: "Female"
    }];

    this.ageGroupData.push({
      id: "-",
      text: "-- Select Age Group --",
      disabled: true,
      selected: true
    })
    this.preferenceService.list().then(data => { 
      var index = data.findIndex(x => x.key == 'AgeGroup');
      if(index != -1) {
        var ageGroupData = JSON.parse(data[index].value);
        for (let i = 0; i <ageGroupData.length; i++) {
          const element = ageGroupData[i];
          this.ageGroupData.push({
            id: element.id,
            text: element.minimumAge + ' - ' + element.maximumAge
          });
        }
      }
    });
    this.meekoin = 0;
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.bitmojiAccessService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var bitmojiAccessList = [];
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.bitmojiAccessService.pagination(data).then(data => {
      new Promise(function(resolve, reject) {
        for (let i = 0, p = Promise.resolve(); i < data.data.length; i++) {
          p = p.then(_ => new Promise(resolve1 => {
            var element = data.data[i]; 
            var badgeLevelIndex = bitmojiAccessList.findIndex(x => x != undefined && x.badgeLevelId.toString() == element.badgeLevelId.toString());
            if(badgeLevelIndex != -1) {
              var bitmojiAccessIndex = bitmojiAccessList[badgeLevelIndex].bitmojiCategory.findIndex(x => x.bitmojiCategoryId.toString() == element.bitmojiCategoryId.toString());
              if(bitmojiAccessIndex != -1) {
                var avatarIndex = bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars.findIndex(x => x.gender == element.gender);
                if(avatarIndex != -1) {
                  element.avatars.forEach((ele, ind) => {
                    // bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars[avatarIndex].variation.push(ele.variation[0]);
                    bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars[avatarIndex].variation.push({
                      _id: element._id,
                      gender: element.gender,
                      folder: element.folder,
                      default: element.default,
                      bitmojiCategoryId: element.bitmojiCategoryId,
                      badgeLevelId: element.badgeLevelId,
                      meekoin: element.meekoin,
                      item: ele
                    });
                    if(element.avatars.length == (ind + 1)) {
                      if(data.data.length == (i + 1)) {
                        resolve(0);
                      }
                      resolve1();
                    }
                  });
                }
                else {
                  bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars.push({
                    gender: element.gender,
                    variation: []
                  });
  
                  if(element.avatars.length > 0) {
                    element.avatars.forEach((ele, ind) => {
                      // bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars[(bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars.length - 1)].variation.push(ele.variation[0]);
                      bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars[(bitmojiAccessList[badgeLevelIndex].bitmojiCategory[bitmojiAccessIndex].avatars.length - 1)].variation.push({
                        _id: element._id,
                        gender: element.gender,
                        folder: element.folder,
                        default: element.default,
                        bitmojiCategoryId: element.bitmojiCategoryId,
                        badgeLevelId: element.badgeLevelId,
                        meekoin: element.meekoin,
                        item: ele
                      });
                      if(element.avatars.length == (ind + 1)) {
                        if(data.data.length == (i + 1)) {
                          resolve(0);
                        }
                        resolve1();
                      }
                    });
                  }
                  else {
                    if(data.data.length == (i + 1)) {
                      resolve(0);
                    }
                    resolve1();
                  }
                }
              }
              else {
                bitmojiAccessList[badgeLevelIndex].bitmojiCategory.push({
                  bitmojiCategoryId: element.bitmojiCategoryId,
                  bitmojicategoryName: element.bitmojicategory[0].name,
                  avatars: [{
                    gender: element.gender,
                    variation: []
                  }]
                })
  
                if(element.avatars.length > 0) {
                  element.avatars.forEach((ele, ind) => {
                    // bitmojiAccessList[badgeLevelIndex].bitmojiCategory[(bitmojiAccessList[badgeLevelIndex].bitmojiCategory.length - 1)].avatars[0].variation.push(ele.variation[0]);
                    bitmojiAccessList[badgeLevelIndex].bitmojiCategory[(bitmojiAccessList[badgeLevelIndex].bitmojiCategory.length - 1)].avatars[0].variation.push({
                      _id: element._id,
                      gender: element.gender,
                      folder: element.folder,
                      default: element.default,
                      bitmojiCategoryId: element.bitmojiCategoryId,
                      badgeLevelId: element.badgeLevelId,
                      meekoin: element.meekoin,
                      item: ele
                    });
                    if(element.avatars.length == (ind + 1)) {
                      if(data.data.length == (i + 1)) {
                        resolve(0);
                      }
                      resolve1();
                    }
                  });
                }
                else {
                  if(data.data.length == (i + 1)) {
                    resolve(0);
                  }
                  resolve1();
                }
              }
            }
            else {
              bitmojiAccessList.push({
                // _id: element._id,
                // gender: element.gender,
                // folder: element.folder,
                // default: element.default,
                // bitmojiCategoryId: element.bitmojiCategoryId,
                badgeLevelId: element.badgeLevelId,
                badgeLevel: element.badgelevel[0].badgeName,
                bitmojiCategory: [{
                  bitmojiCategoryId: element.bitmojiCategoryId,
                  bitmojicategoryName: element.bitmojicategory[0].name,
                  avatars: [{
                    gender: element.gender,
                    variation: []
                  }]
                }]
              });
  
              if(element.avatars.length > 0) {
                element.avatars.forEach((ele, ind) => {
                  // bitmojiAccessList[0].bitmojiCategory[0].avatars[0].variation.push(ele.variation[0]);
                  bitmojiAccessList[0].bitmojiCategory[0].avatars[0].variation.push({ 
                    _id: element._id,
                    gender: element.gender,
                    folder: element.folder,
                    default: element.default,
                    bitmojiCategoryId: element.bitmojiCategoryId,
                    badgeLevelId: element.badgeLevelId,
                    meekoin: element.meekoin,
                    item: ele
                  });
                  if(element.avatars.length == (ind + 1)) {
                    if(data.data.length == (i + 1)) {
                      resolve(0);
                    }
                    resolve1();
                  }
                });
              }
              else {
                if(data.data.length == (i + 1)) {
                  resolve(0);
                }
                resolve1();
              }
            }
          }));
        }
      }).then(next => {
        this.bitmojiAccessList = bitmojiAccessList;
      });
    });

    // this.bitmojiAccessService.pagination(data).then(data => {
    //   // this.bitmojiAccessList = data.data;
    //   for (let i = 0, p = Promise.resolve(); i < data.data.length; i++) {
    //     p = p.then(_ => new Promise(resolve => {
    //       var element = data.data[i];
    //       if(element.avatars.length > 0) {
    //         for (let j = 0, p = Promise.resolve(); j < element.avatars.length; j++) {
    //           p = p.then(_ => new Promise(resolve1 => {
    //             var ele = element.avatars[j].variation;
    //             if(ele.length > 0) {
    //               ele.forEach((e, ind) => {
    //                 var IAG = this.ageGroupData.findIndex(x => x.id == e.ageGroupId);
    //                 if(IAG != -1) {
    //                   e.ageGroup = this.ageGroupData[IAG].text;
    //                 }
    //                 if(ele.length == (ind + 1)) {
    //                   if(element.avatars.length == (j + 1)) {
    //                     var index = this.bitmojiAccessList.findIndex(x => x.title == element.bitmojicategory[0].name);
    //                     if(index != -1) {
    //                       this.bitmojiAccessList[index].list.push(element);
    //                       resolve();
    //                     }
    //                     else {
    //                       this.bitmojiAccessList.push({title: element.bitmojicategory[0].name, list: [element]});
    //                       resolve();
    //                     }
    //                   }
    //                   resolve1();
    //                 }
    //               });
    //             }
    //             else {
    //               if(element.avatars.length == (j + 1)) {
    //                 var index = this.bitmojiAccessList.findIndex(x => x.title == element.bitmojicategory[0].name);
    //                 if(index != -1) {
    //                   this.bitmojiAccessList[index].list.push(element);
    //                   resolve();
    //                 }
    //                 else {
    //                   this.bitmojiAccessList.push({title: element.bitmojicategory[0].name, list: [element]});
    //                   resolve();
    //                 }
    //               }
    //               resolve1();
    //             }
    //           }));
    //         }
    //       }
    //       else {
    //         var index = this.bitmojiAccessList.findIndex(x => x.title == element.bitmojicategory[0].name);
    //         if(index != -1) {
    //           this.bitmojiAccessList[index].list.push(element);
    //           resolve();
    //         }
    //         else {
    //           this.bitmojiAccessList.push({title: element.bitmojicategory[0].name, list: [element]});
    //           resolve();
    //         }
    //       }
    //     }));
    //   }
    // });
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.filesupload = [];
    this.ageGroupList = [];
    this.uploadGLTF = [];
    this.uploadImage = [];
    this.fileId = 1;
    // this.oldAgeGroupList = [];
    // this.bitmojiCategoryId = '-';
    // this.badgeLevelId = '-';
    this.bitmojiCategoryId = null;
    this.badgeLevelId = null;
    this.gender = null;
    this.ageGroup = null;
    this.default = false;
    this.meekoin = 0;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id != '-') {
      this.dropDownData.unshift({
        id: "-",
        text: "-- Select Bitmoji Category --",
        disabled: true,
        selected: true
      });
    }
    if(this.dropDownData1[0] != undefined && this.dropDownData1[0].id != '-') {
      this.dropDownData1.unshift({
        id: "-",
        text: "-- Select Badge Level --",
        disabled: true,
        selected: true
      });
    }
    if(this.genderDropDownData[0] != undefined && this.genderDropDownData[0].id != '-') {
      this.genderDropDownData.unshift({
        id: "-",
        text: "-- Select Gender --",
        disabled: true,
        selected: true
      });
    }
    if(this.ageGroupData[0] != undefined && this.ageGroupData[0].id != '-') {
      this.ageGroupData.unshift({
        id: "-",
        text: "-- Select Age Group --",
        disabled: true,
        selected: true
      });
    }
  }

  fileGLTFChangeEvent(event, fileGLTF, fileAvatars) {
    // for (let index = 0; index < event.target.files.length; index++) {
    //   const element = event.target.files[index];
    //   var filename = element.name.split('.');
    //   this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    // }
    // // this.filesupload = event.target.files;
    if(this.gender != undefined && this.gender != null && this.gender != "" && this.gender != "-") {
      // var agindex = this.ageGroupList.findIndex(x => x.id == this.Form.value.ageGroup);
      // var oagindex = this.oldAgeGroupList.findIndex(x => x.id == this.Form.value.ageGroup);
      // if(agindex == -1 && oagindex == -1) {
      //   if(this.Form.value.ageGroup != undefined && this.Form.value.ageGroup != null && this.Form.value.ageGroup != "" && this.Form.value.ageGroup != "-") {
          if (event.target.files.length > 0) {
            var aindex = this.ageGroupList.findIndex(x => x.id == this.Form.value.ageGroup && x.nameImage != undefined && x.nameImage != "" && x.nameGLTF != undefined && x.nameGLTF != "");
            if(aindex == -1) {
              var agegroup = this.ageGroupData.find(x => x.id == this.Form.value.ageGroup).text;
              var age = agegroup.split(' - ');
              var filename = event.target.files[0].name.split('.');
              var name = this.gender + '_' + age[0] + '_' + age[1] + '.' + filename[filename.length - 1];
              this.filesupload.push({file: event.target.files[0], filename: name, id: this.Form.value.ageGroup});
              var reader = new FileReader();
              reader.onload = (event:any) => {
                var findex = this.ageGroupList.findIndex(x => x.fileId == this.fileId);
                if(findex != -1) {
                  this.fileId++;
                  this.ageGroupList[findex].gltf = event.target.result;
                  this.ageGroupList[findex].nameGLTF = name;
                  this.ageGroup = "-";
                  // this.avatars = "-"; 
                  fileGLTF.value = "";
                  fileAvatars.value = "";
                }
                else {
                  this.ageGroupList.push({nameImage: "", nameGLTF: name, ageGroup: agegroup, id: this.Form.value.ageGroup, gltf: event.target.result, image: "", fileId: this.fileId});
                }
              }
              reader.readAsDataURL(event.target.files[0]);
            }
            else {
              this.toastr.errorToastr('Already age group selected!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
            }
          }
      //   }
      //   else {
      //     this.toastr.errorToastr('Please select age group!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      //   }
      // }
      // else {
      //   this.toastr.errorToastr('This age group already use!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      // };
    }
    else {
      this.toastr.errorToastr('Please select gender!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  fileChangeEvent(event, fileGLTF, fileAvatars) {
    // for (let index = 0; index < event.target.files.length; index++) {
    //   const element = event.target.files[index];
    //   var filename = element.name.split('.');
    //   this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    // }
    // // this.filesupload = event.target.files;
    if(this.gender != undefined && this.gender != null && this.gender != "" && this.gender != "-") {
      // var agindex = this.ageGroupList.findIndex(x => x.id == this.Form.value.ageGroup);
      // var oagindex = this.oldAgeGroupList.findIndex(x => x.id == this.Form.value.ageGroup);
      // if(agindex == -1 && oagindex == -1) {
      //   if(this.Form.value.ageGroup != undefined && this.Form.value.ageGroup != null && this.Form.value.ageGroup != "" && this.Form.value.ageGroup != "-") {
        
          if (event.target.files.length > 0) {
            var aindex = this.ageGroupList.findIndex(x => x.id == this.Form.value.ageGroup && x.nameImage != undefined && x.nameImage != "" && x.nameGLTF != undefined && x.nameGLTF != "");
            if(aindex == -1) {
              var agegroup = this.ageGroupData.find(x => x.id == this.Form.value.ageGroup).text;
              var age = agegroup.split(' - ');
              var filename = event.target.files[0].name.split('.');
              var name = this.gender + '_' + age[0] + '_' + age[1] + '.' + filename[filename.length - 1];
              this.filesupload.push({file: event.target.files[0], filename: name, id: this.Form.value.ageGroup});
              var reader = new FileReader();
              reader.onload = (event:any) => {
                var findex = this.ageGroupList.findIndex(x => x.fileId == this.fileId);
                if(findex != -1) {
                  this.fileId++;
                  this.ageGroupList[findex].image = event.target.result;
                  this.ageGroupList[findex].nameImage = name;
                  this.ageGroup = "-";
                  // this.avatars = "-"; 
                  fileGLTF.value = "";
                  fileAvatars.value = "";
                }
                else {
                  this.ageGroupList.push({nameImage: name, nameGLTF: "", ageGroup: agegroup, id: this.Form.value.ageGroup, image: event.target.result, gltf: "", fileId: this.fileId});
                }
              }
              reader.readAsDataURL(event.target.files[0]);
            }
            else {
              this.toastr.errorToastr('Already age group selected!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
            }
          }
      //   }
      //   else {
      //     this.toastr.errorToastr('Please select age group!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      //   }
      // }
      // else {
      //   this.toastr.errorToastr('This age group already use!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      // };
    }
    else {
      this.toastr.errorToastr('Please select gender!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  defaultEvent(event) {
    this.default = event;
  }

  deleteImage(id) {
    var indexAG = this.ageGroupList.findIndex(x => x.id == id);
    if(indexAG != -1) {
      this.ageGroupList.splice(indexAG, 1);
    }
    var indexFU = this.filesupload.findIndex(x => x.id == id);
    if(indexFU != -1) {
      this.filesupload.splice(indexFU, 1);
    }
    this.ageGroup = "-";
  }

  add() {
    this.addbtn = true;
    // var category = this.bitmojiCategoryList.find(x => x._id == this.Form.value.bitmojiCategoryId).name;
    localStorage.setItem('category', this.Form.value.bitmojiCategoryId);
    // var badgename = this.badgeLevelList.find(x => x._id == this.Form.value.badgeLevelId).badgeName;
    localStorage.setItem('badgename', this.Form.value.badgeLevelId);
    this.ageGroupList.forEach(element => {
      delete element.image;
      delete element.gltf;
    });
    this.Form.value.avatars = this.ageGroupList;
    if(this.Form.value.default == null) {
      this.Form.value.default = false;
    }
    this.Form.value.avatarsGLTF = this.Form.value.avatarsGLTF.replace(/^.*\\/, "");
    this.bitmojiAccessService.add(this.Form.value, this.filesupload).then(data => {
      if(data.flag == true) {
        // this.bitmojiAccessList.unshift(data.data);
        // var index = this.bitmojiAccessList.findIndex(x => x.title == data.data.bitmojicategory[0].name);
        // if(index != -1) {
        //   this.bitmojiAccessList[index].list.unshift(data.data);
        // }
        // else {
        //   this.bitmojiAccessList.unshift({title: data.data.bitmojicategory[0].name, list: [data.data]});
        // }
        // this.total = (parseInt(this.total) + 1).toString();
        this.pagination();
        this.toastr.successToastr(data.message, 'Bitmoji Access Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false; 
        this.modalService.dismissAll();
      }
      localStorage.removeItem('category');
      localStorage.removeItem('badgename');
    });
  }

  show(data, aId, image, type, i) {
    this.filesupload = [];
    this.ageGroupList = [];
    this.uploadGLTF = [];
    this.uploadImage = [];
    this.fileId = 1;
    // this.oldAgeGroupList = [];
    this.updatebtn = false;
    this.deletebtn = false;
    this.ageGroup = "-";
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.aId = aId;
    this.bitmojiCategoryId = data.bitmojiCategoryId;
    this.badgeLevelId = data.badgeLevelId;
    this.gender = data.gender;
    this.meekoin = data.meekoin;
    this.imgid = image;
    var folder = data.folder.split('/');
    this.category = folder[0];
    this.badgeName = folder[1];
    this.default = data.default;
    // if(data.avatars.length > 0) {
    //   data.avatars.forEach(element => {
    //     this.oldAgeGroupList.push({ageGroup: element.ageGroup , id: element.ageGroupId});
    //   });
    // }
    // if(image != '') {
    //   this.imagepath = this.server + data.folder + '/' + image.image;
    // }
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id == '-') {
      this.dropDownData.splice(0, 1);
    }
    if(this.dropDownData1[0] != undefined && this.dropDownData1[0].id == '-') {
      this.dropDownData1.splice(0, 1);
    }
    if(this.genderDropDownData[0] != undefined && this.genderDropDownData[0].id == '-') {
      this.genderDropDownData.splice(0, 1);
    }
    // if(this.ageGroupData[0] != undefined && this.ageGroupData[0].id == '-') {
    //   this.ageGroupData.splice(0, 1);
    // }
  }

  addimg() {
    if(this.filesupload.length > 0) {
      this.updatebtn = true;
      localStorage.setItem('category', this.category);
      localStorage.setItem('badgename', this.badgeName);
      this.ageGroupList.forEach(element => {
        delete element.image;
        delete element.gltf;
      });
      this.Form.value.avatars = this.ageGroupList;
      this.Form.value.id = this.aId;
      if(this.Form.value.default == null) {
        this.Form.value.default = false;
      }
      this.bitmojiAccessService.addimage(this.Form.value, this.filesupload, this.id).then(data => {
        if(data.flag == true) {
          // this.bitmojiAccessList[this.index] = data.data;
          this.toastr.successToastr(data.message, 'Bitmoji Access Updated!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.pagination();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.updatebtn = false;
        }
        localStorage.removeItem('category');
        localStorage.removeItem('badgename');
      });
    }
    else {
      this.toastr.errorToastr('Image Upload!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  gltfChangeEvent(event) {
    this.uploadGLTF = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.uploadGLTF.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  imageChangeEvent(event) {
    this.uploadImage = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.uploadImage.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  updateimg() {
      if(this.uploadGLTF.length > 0 || this.uploadImage.length > 0) {
        var json = {
          avatarId: this.imgid._id,
          variationId: this.aId._id
        }
        localStorage.setItem('category', this.category);
        localStorage.setItem('badgename', this.badgeName);
        this.updatebtn = true;
        this.bitmojiAccessService.updateimage(json, this.id, this.uploadGLTF, this.uploadImage, this.aId.gltf, this.aId.image).then(data => {
          if(data.flag == true) {
            this.toastr.successToastr(data.message, 'Bitmoji Access Updated!', { animate: 'slideFromRight', showCloseButton: true });
            this.modalService.dismissAll();
            this.pagination();
            this.clear();
          }
          else {
            this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
            this.modalService.dismissAll();
            this.updatebtn = false;
          }
          localStorage.removeItem('category');
          localStorage.removeItem('badgename');
        });
      }
      else {
        this.toastr.errorToastr('Please upload a file!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
  }

  updateMeekoin() {
    this.updatebtn = true;
    this.bitmojiAccessService.update(this.FormM.value, this.id).then(data => {
      if(data.flag == true) {
        this.meekoin = this.FormM.value.meekoin;
        // this.bitmojiAccessList[this.index] = data.data;
        this.pagination();
        this.toastr.successToastr(data.message, 'Bitmoji Access Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  // update() {
  //   this.updatebtn = true;
  //   this.bitmojiAccessService.update(this.Form.value, this.id).then(data => {
  //     if(data.flag == true) {
  //       this.bitmojiAccessList[this.index] = data.data;
  //       this.toastr.successToastr(data.message, 'Bitmoji Access Updated!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.clear();
  //     }
  //     else {
  //       this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.updatebtn = false;
  //     }
  //   });
  // }

  delete() {
    if(this.imgid != '') {
      this.deletebtn = true;
      var json = {
        id: this.imgid._id,
        imageId: this.aId._id,
        // category: this.category + '/' + this.badgeName
      }
      this.bitmojiAccessService.deleteimage(json, this.id).then(data => {
        if(data.flag == true) {
          // this.bitmojiAccessList.splice(this.index, 1);
          // this.bitmojiAccessList[this.index] = data.data;
          this.toastr.successToastr(data.message, 'Bitmoji Access Deleted!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.pagination();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.deletebtn = false;
        }
      });
    }
    else {
      this.toastr.errorToastr('Something is wrong!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.bitmojiAccessService.active(json, id).then(data => {
      if(data.flag == true) {
        this.bitmojiAccessList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
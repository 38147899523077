import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ParentWithdrawalReferService } from './parent-withdrawal-refer.service';
import { ToastrManager } from 'ng6-toastr-notifications';
// import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { ApiConstant } from '../api.constant';


// @Injectable()
// export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
//   parse(value: string): NgbDateStruct | null {
//     if (value) {
//       const dateParts = value.trim().split('-');

//       let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
//       const dateLabels = Object.keys(dateObj);

//       dateParts.forEach((datePart, idx) => {
//         dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
//       });
//       return dateObj;
//     }
//     return null;
//   }

//   format(date: NgbDateStruct | null): string {
//     return date ?
//         `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
//         '';
//   }
// }

// function padNumber(value: number | null) {
//   if (!isNaN(value) && value !== null) {
//     return `0${value}`.slice(-2);
//   } else {
//     return '';
//   }
// }

@Component({
  selector: 'app-parent-withdrawal-refer',
  templateUrl: './parent-withdrawal-refer.component.html',
  styleUrls: ['./parent-withdrawal-refer.component.css'],
  // providers: [
  //   {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  //  ]
})
export class ParentWithdrawalReferComponent implements OnInit {

  Form: FormGroup;

  name;
  id;
  wid;
  amount;
  index;
  withdrawalReferList = [];
  statusData;
  statusData2;
  statusDDL = '-';
  status = '';
  message;
  search = '';
  // startDate: NgbDateStruct;
  // endDate: NgbDateStruct;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private parentWithdrawalReferService: ParentWithdrawalReferService,
    private toastr: ToastrManager,
    private router: Router,
    private titleService: Title,
    // private calendar: NgbCalendar,
    private apiConstant: ApiConstant
  ) {
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      status: ['', Validators.required],
      message: ['', Validators.nullValidator]
    });
  }

  ngOnInit(): void {
    this.search = "";
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;

    const status = localStorage.getItem('status');
    if(status != null) {
      this.statusDDL = status;
    }
    this.pagination();

    this.statusData = [{
      id: "-",
      text: "All Status"
    },
    {
      id: 'Pending',
      text: 'Pending',
    },
    {
      id: 'In Progress',
      text: 'In Progress',
    },
    {
      id: 'Success',
      text: 'Success',
    },
    {
      id: 'Failure',
      text: 'Failure'
    }];

    this.statusData2 = [{
      id: 'In Progress',
      text: 'In Progress',
    },
    {
      id: 'Success',
      text: 'Success',
    },
    {
      id: 'Failure',
      text: 'Failure'
    }];

    // var dateObj = new Date();
    // var month = dateObj.getUTCMonth() + 1; //months from 1-12
    // var day = dateObj.getUTCDate();
    // var year = dateObj.getUTCFullYear();

    // this.startDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
    // this.endDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  searchBtn() {
    this.pagination();
  }

  pagination() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit),
      status: this.statusDDL
    }

    if(this.search == "") {
      this.parentWithdrawalReferService.pagination(data).then(data => {
        this.withdrawalReferList = data.data.filterData;
        this.total = data.data.totalRecord.toString();
      });
    }
    else {
      this.parentWithdrawalReferService.search(data, this.search).then(data => {
        this.withdrawalReferList = data.data.filterData;
        this.total = data.data.totalRecord.toString();
      });
    }
  }

  loadPage(event) {
    this.pageIndex = event;
    this.pagination();
  }

  onType(event) {
    this.statusDDL = event;
    localStorage.setItem('status', this.statusDDL);
    this.pagination();
  }

  onStatus(event) {
    this.status = event;
  }

  show(data, type, i) {
    this.id = data._id;
    this.index = i;
    this.wid = data.id;
    this.status = data.status;
    this.amount = data.amount;
    this.message = data.message;
    this.modalService.open(type, { size: 'lg' });
  }

  Withdrawal() {
    this.Form.value.id = this.wid;
    this.Form.value.amount = this.amount;
    this.parentWithdrawalReferService.withdrawal(this.Form.value, this.id).then(data => {
      if(data.flag) {
        this.withdrawalReferList[this.index].status = data.data.status;
        this.withdrawalReferList[this.index].message = data.data.message;
        this.toastr.successToastr(data.message, 'Withdrawal!', { animate: 'slideFromRight', showCloseButton: true });
        this.Form.reset();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.modalService.dismissAll();
    });
  }
}

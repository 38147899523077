import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private auth: AuthService,
    private router: Router,
    private titleService: Title  
  ) { }

  canActivate() {
    if(this.auth.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['/']);
    this.titleService.setTitle('Login' + ' - Meeko');
    return false;
  }
  
}

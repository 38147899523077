import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ChildHelpDeskService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data) {
    return this.httpmanagerService.HttpPost('helpdesk/ticket', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  status(data) {
    return this.httpmanagerService.HttpPost('helpdesk/status', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
  
  total() {
    return this.httpmanagerService.HttpGet('helpdesk/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('helpdesk/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  refundApprove(id) {
    return this.httpmanagerService.HttpGet('helpdesk/refundapprove', id).then(data => {
      return data;
    });
  }

  refundReject(id) {
    return this.httpmanagerService.HttpGet('helpdesk/refundreject', id).then(data => {
      return data;
    });
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbdSortableHeader } from './sortable.directive';

import {SharedService} from './shared.service';

import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';

import { LightboxModule } from 'ngx-lightbox';

import { NestableModule } from 'ngx-nestable';

import { NgxSpinnerModule } from "ngx-spinner";

import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';

import { ToastrModule } from 'ng6-toastr-notifications';

/* Calendar Modules */

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])


import { MetismenuAngularModule } from "@metismenu/angular";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

/* Calendar Modules */

import { MatListModule } from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSliderModule} from '@angular/material/slider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRippleModule} from '@angular/material/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './elements/header/header.component';
import { NavHeaderComponent } from './elements/nav-header/nav-header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './elements/navigation/navigation.component';
/* import { ChatboxComponent } from './elements/chatbox/chatbox.component'; */
import { RecentEventListComponent } from './elements/dashboard/recent-event-list/recent-event-list.component';
import { FooterComponent } from './elements/footer/footer.component';

import { GraphBestSellingComponent } from './elements/dashboard/graph-best-selling/graph-best-selling.component';
import { GraphNewSalesComponent } from './elements/dashboard/graph-new-sales/graph-new-sales.component';
import { GraphEventHeldComponent } from './elements/dashboard/graph-event-held/graph-event-held.component';
import { GraphComparissonComponent } from './elements/dashboard/graph-comparisson/graph-comparisson.component';
import { GraphSalesRevenueComponent } from './elements/dashboard/graph-sales-revenue/graph-sales-revenue.component';
import { LatestSalesComponent } from './elements/dashboard/latest-sales/latest-sales.component';

import { SalesSummaryComponent } from './elements/graph/sales-summary/sales-summary.component';
import { AvailableTicketComponent } from './elements/graph/available-ticket/available-ticket.component';
import { IncomeComponent } from './elements/analytics/income/income.component';
import { CustomerStatisticsComponent } from './elements/analytics/customer-statistics/customer-statistics.component';
import { LastYearComponent } from './elements/analytics/last-year/last-year.component';
import { SalesComparisonComponent } from './elements/analytics/graph/sales-comparison/sales-comparison.component';
import { TicketSoldComponent } from './elements/analytics/graph/ticket-sold/ticket-sold.component';
import { AdultComponent } from './elements/analytics/graph/adult/adult.component';
import { YoungComponent } from './elements/analytics/graph/young/young.component';
import { RevenueComponent } from './elements/analytics/graph/revenue/revenue.component';
import { AnalyticsSalesSummaryComponent } from './elements/analytics/graph/analytics-sales-summary/analytics-sales-summary.component';
import { UserStatisticsComponent } from './elements/profile/user-statistics/user-statistics.component';
import { InterestComponent } from './elements/profile/interest/interest.component';
import { LatestNewsComponent } from './elements/profile/latest-news/latest-news.component';
import { HighlightsComponent } from './elements/profile/highlights/highlights.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Error400Component } from './pages/error400/error400.component';
import { Error403Component } from './pages/error403/error403.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error503Component } from './pages/error503/error503.component';
import { MonthlyComponent } from './elements/dashboard/graph-sales-revenue/monthly/monthly.component';
import { WeeklyComponent } from './elements/dashboard/graph-sales-revenue/weekly/weekly.component';
import { DailyComponent } from './elements/dashboard/graph-sales-revenue/daily/daily.component';
import { Timeline1Component } from './elements/widget/timeline1/timeline1.component';
import { Timeline2Component } from './elements/widget/timeline2/timeline2.component';
import { Notifications1Component } from './elements/widget/notifications1/notifications1.component';
import { Notifications2Component } from './elements/widget/notifications2/notifications2.component';
import { MessageComponent } from './elements/widget/message/message.component';
import { TodolistComponent } from './elements/widget/todolist/todolist.component';
import { PieChart1Component } from './elements/widget/charts/pie-chart1/pie-chart1.component';
import { BarChart1Component } from './elements/widget/charts/bar-chart1/bar-chart1.component';
import { BarChart2Component } from './elements/widget/charts/bar-chart2/bar-chart2.component';
import { AreaChart1Component } from './elements/widget/charts/area-chart1/area-chart1.component';
import { VisitorActivityComponent } from './elements/widget/visitor-activity/visitor-activity.component';
import { VisitorActivityDayComponent } from './elements/widget/visitor-activity/visitor-activity-day/visitor-activity-day.component';
import { VisitorActivityMonthComponent } from './elements/widget/visitor-activity/visitor-activity-month/visitor-activity-month.component';
import { VisitorActivityYearComponent } from './elements/widget/visitor-activity/visitor-activity-year/visitor-activity-year.component';
import { ChartjsActiveUsersComponent } from './elements/widget/charts/chartjs-active-users/chartjs-active-users.component';
import { BloodPressureComponent } from './elements/widget/charts/blood-pressure/blood-pressure.component';
import { HeartRateComponent } from './elements/widget/charts/heart-rate/heart-rate.component';
import { GlucoseRateComponent } from './elements/widget/charts/glucose-rate/glucose-rate.component';
import { ClolesterolComponent } from './elements/widget/charts/clolesterol/clolesterol.component';
import { BarChart3Component } from './elements/widget/charts/bar-chart3/bar-chart3.component';
import { AreaChart2Component } from './elements/widget/charts/area-chart2/area-chart2.component';
import { BarChart4Component } from './elements/widget/charts/bar-chart4/bar-chart4.component';
import { BarChart5Component } from './elements/widget/charts/bar-chart5/bar-chart5.component';
import { AreaChart3Component } from './elements/widget/charts/area-chart3/area-chart3.component';
import { BarChart6Component } from './elements/widget/charts/bar-chart6/bar-chart6.component';
import { MarketNowComponent } from './elements/widget/charts/market-now/market-now.component';
import { SalesAnalysisComponent } from './elements/widget/charts/sales-analysis/sales-analysis.component';
import { TopProducts1Component } from './elements/widget/charts/top-products1/top-products1.component';
import { TopProducts2Component } from './elements/widget/charts/top-products2/top-products2.component';
import { WeeklySalesComponent } from './elements/widget/charts/weekly-sales/weekly-sales.component';
import { SalesStatusComponent } from './elements/widget/charts/sales-status/sales-status.component';
import { AllSales1Component } from './elements/widget/charts/all-sales1/all-sales1.component';
import { AllSales2Component } from './elements/widget/charts/all-sales2/all-sales2.component';
import { LoadingComponent } from './elements/loading/loading.component';

import { AdminComponent } from './admin/admin.component';

import { AdminUserComponent } from './admin-user/admin-user.component';
import { DepartmentComponent } from './department/department.component';
import { SubscriptionPackageComponent } from './subscription-package/subscription-package.component';
import { EducatorPackageComponent } from './educator-package/educator-package.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { BadgeLevelComponent } from './badge-level/badge-level.component';
import { BitmojiCategoryComponent } from './bitmoji-category/bitmoji-category.component';
import { BitmojiAccessComponent } from './bitmoji-access/bitmoji-access.component';
import { ELearnerComponent } from './e-learner/e-learner.component';
import { EducatorComponent } from './educator/educator.component';
import { OfferComponent } from './offer/offer.component';
import { PreferenceComponent } from './preference/preference.component';
import { TaxRatesComponent } from './tax-rates/tax-rates.component';
import { ClassConvenienceFee } from './class-convenience-fee/class-convenience-fee.component';
import { LanguageComponent } from './language/language.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationComponent } from './notification/notification.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ChildUserComponent } from './child-user/child-user.component';
import { ParentUserComponent } from './parent-user/parent-user.component';
import { EducatorUserComponent } from './educator-user/educator-user.component';
import { ChatbotQuestionnaireComponent } from './chatbot-questionnaire/chatbot-questionnaire.component';
import { ELearnerSliderComponent } from './e-learner-slider/e-learner-slider.component';
import { GiftCoinComponent } from './giftcoin/giftcoin.component';
import { OfferCoinComponent } from './offer-coin/offer-coin.component';
import { CertificateComponent } from './certificate/certificate.component';
import { OfferBannerComponent } from './offer-banner/offer-banner.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { TestimonialEducatorComponent } from './testimonial-educator/testimonial-educator.component';
import { TestimonialParentComponent } from './testimonial-parent/testimonial-parent.component';
import { FAQComponent } from './faq/faq.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { BlogComponent } from './blog/blog.component';
import { EnquireComponent } from './enquire/enquire.component';
import { ChildHelpDeskComponent } from './child-help-desk/child-help-desk.component';
import { ReferralFriendComponent } from './referral-friend/referral-friend.component';
import { ClassesComponent } from './classes/classes.component';
import { ClassDetailViewComponent } from './class-detail-view/class-detail-view.component';
import { BatchDetailViewComponent } from './batch-detail-view/batch-detail-view.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { BeneficiaryListComponent } from './beneficiary-list/beneficiary-list.component';
import { EducatorFinanceComponent } from './educator-finance/educator-finance.component';
import { ELearnerRefundComponent } from './e-learner-refund/e-learner-refund.component';
import { WhatsAppComponent } from './whatsapp/whatsapp.component';
import { BookingComponent } from './booking/booking.component';
import { CategoryClassesComponent } from './category-classes/category-classes.component';
import { EducatorWithdrawalReferComponent } from './educator-withdrawal-refer/educator-withdrawal-refer.component';
import { ParentWithdrawalReferComponent } from './parent-withdrawal-refer/parent-withdrawal-refer.component';
import { SchoolWithdrawalReferComponent } from './school-withdrawal-refer/school-withdrawal-refer.component';
import { SchoolComponent } from './school/school.component';
import { ReferHistoryComponent } from './refer-history/refer-history.component';
import { ReferFriendHistoryComponent } from './refer-friend-history/refer-friend-history.component';
// import { BeneficiaryParentComponent } from './beneficiary-parent/beneficiary-parent.component';
// import { BeneficiaryListParentComponent } from './beneficiary-list-parent/beneficiary-list-parent.component';
import { ApiConstant } from './api.constant';
import { HttpManagerService } from './httpmanager.service';
import { NgSelect2Module } from 'ng-select2';
import { TagInputModule } from 'ngx-chips';
import { CKEditorModule } from 'ckeditor4-angular';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavigationComponent,
    /* ChatboxComponent, */
    HeaderComponent,
    RecentEventListComponent,
    FooterComponent,
    NavHeaderComponent,
	//NgbdSortableHeader,
	NgbdSortableHeader,

	/* ################################ */
	/* AutocompleteComponent, */

	/* DzmtAutocompleteComponent,

	DzmtAutocompleteFirstActiveComponent,

	DzmtAutocompleteDisplayComponent,

	DzmtAutocompleteFilterComponent,

	DzmtAutocompleteOptgroupComponent,

	DzmtAutocompleteOverviewComponent,

	DzmtAutocompletePlaneComponent,

	DzmtAutocompleteSimpleComponent,

	DzmtBadgeComponent,
 */
	GraphBestSellingComponent,

	GraphNewSalesComponent,

	GraphEventHeldComponent,

	GraphComparissonComponent,

	GraphSalesRevenueComponent,

	LatestSalesComponent,

	SalesSummaryComponent,

	AvailableTicketComponent,

	IncomeComponent,

	CustomerStatisticsComponent,

	LastYearComponent,

	SalesComparisonComponent,

	TicketSoldComponent,

	AdultComponent,

	YoungComponent,

	RevenueComponent,

	AnalyticsSalesSummaryComponent,

	UserStatisticsComponent,

	InterestComponent,

	LatestNewsComponent,

	HighlightsComponent,

	RegisterComponent,

	LoginComponent,

	LockScreenComponent,

	ForgotPasswordComponent,

	ResetPasswordComponent,

	Error400Component,

	Error403Component,

	Error404Component,

	Error500Component,

	Error503Component,

	/* AreaDatetimeXAxisComponent, */

	/* RadarPolygonFillComponent, */

	MonthlyComponent,

	WeeklyComponent,

	DailyComponent,

	Timeline1Component,

	Timeline2Component,

	Notifications1Component,

	Notifications2Component,

	MessageComponent,

	TodolistComponent,

	PieChart1Component,

	BarChart1Component,

	BarChart2Component,

	AreaChart1Component,

	VisitorActivityComponent,

	VisitorActivityDayComponent,

	VisitorActivityMonthComponent,

	VisitorActivityYearComponent,

	ChartjsActiveUsersComponent,

	BloodPressureComponent,

	HeartRateComponent,

	GlucoseRateComponent,

	ClolesterolComponent,

	BarChart3Component,

	AreaChart2Component,

	BarChart4Component,

	BarChart5Component,

	AreaChart3Component,

	BarChart6Component,

	MarketNowComponent,

	SalesAnalysisComponent,

	TopProducts1Component,

	TopProducts2Component,

	WeeklySalesComponent,

	SalesStatusComponent,

	AllSales1Component,

	AllSales2Component,

	LoadingComponent,

   AdminComponent,
   AdminUserComponent,
   DepartmentComponent,
   SubscriptionPackageComponent,
   EducatorPackageComponent,
   CategoryComponent,
   SubCategoryComponent,
   UserTypeComponent,
   BadgeLevelComponent,
   BitmojiCategoryComponent,
   BitmojiAccessComponent,
   ELearnerComponent,
   EducatorComponent,
   OfferComponent,
   PreferenceComponent,
   TaxRatesComponent,
   ClassConvenienceFee,
   LanguageComponent,
   ProfileComponent,
   NotificationComponent,
   ChildUserComponent,
   ParentUserComponent,
   EducatorUserComponent,
   ChatbotQuestionnaireComponent,
   ELearnerSliderComponent,
   GiftCoinComponent,
   OfferCoinComponent,
   CertificateComponent,
   OfferBannerComponent,
   EmailConfigurationComponent,
   EmailTemplateComponent,
   TestimonialEducatorComponent,
   TestimonialParentComponent,
   FAQComponent,
   TutorialComponent,
   BlogComponent,
   EnquireComponent,
   ChildHelpDeskComponent,
   ReferralFriendComponent,
   ClassesComponent,
   ClassDetailViewComponent,
   BatchDetailViewComponent,
   BeneficiaryComponent,
   BeneficiaryListComponent,
   EducatorFinanceComponent,
   ELearnerRefundComponent,
   WhatsAppComponent,
   BookingComponent,
   CategoryClassesComponent,
   EducatorWithdrawalReferComponent,
   ParentWithdrawalReferComponent,
   SchoolWithdrawalReferComponent,
   SchoolComponent,
   ReferHistoryComponent,
   ReferFriendHistoryComponent,
  //  BeneficiaryParentComponent,
  //  BeneficiaryListParentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    CarouselModule,
	FormsModule,
	ReactiveFormsModule,
	HttpClientModule,
    LightboxModule,
    FullCalendarModule,
    MetismenuAngularModule,
    PerfectScrollbarModule,
    NestableModule,
    NgxSpinnerModule,
    NgBootstrapFormValidationModule.forRoot(),
    ToastrModule.forRoot(),
	/* ############# Chart #################  */
	ChartsModule,
	NgApexchartsModule,

    /* ###########################  Material Modules   ######################## */
    MatListModule,
	MatAutocompleteModule,
	MatFormFieldModule,
	MatInputModule,
	MatSlideToggleModule,
	MatIconModule,
    MatTabsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    DragDropModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatGridListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,

   /* ###########################  Material Modules   ######################## */

	NgSelect2Module,
	TagInputModule,
	CKEditorModule
  ],
  exports: [
  ],
   providers: [
	SharedService, ApiConstant, HttpManagerService, Title
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Sub Category</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Main Category</th>
                            <th scope="col" class="text-left px-1 py-2">Sub Category</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col"  class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let subCategory of subCategoryList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ subCategory.category[0].name }}</td>
                            <td>{{ subCategory.name }}</td>
                            <!-- <td>{{ subCategory.createAt | date }}</td>
                            <td>{{ subCategory.admin[0].firstName }} {{ subCategory.admin[0].lastName }}</td>
                            <td>{{ subCategory.updateAt | date }}</td> -->
                            <td class="text-center">
                                <button [hidden]="subCategory.active" (click)="active(subCategory.active, subCategory._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!subCategory.active" (click)="active(subCategory.active, subCategory._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(subCategory, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <!-- <button type="button" (click)="show(subCategory, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button> -->
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Sub Category</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Main Category</label>
                    <ng-select2 
                        [(ngModel)]="categoryId"
                        formControlName="categoryId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a category...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Sub Category</label>
                    <input type="text" class="form-control" formControlName="name" placeholder="Sub Category">
                </div>
            </div>
            <form [formGroup]="FormIcon" (validSubmit)="addIcon()" novalidate>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Icon Name</label>
                        <input type="text" class="form-control" formControlName="iconName" [(ngModel)]="iconName" placeholder="Icon Name">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="formFileMultiple" class="form-label">Icon</label>
                        <input class="form-control" type="file" (change)="fileChangeEvent($event)" id="formFileMultiple"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">{{updateIconFlag == true ? 'Update' : 'Save'}} Icon</button>
                    <button type="button" class="btn btn-light" (click)="clearIcon()">Close</button>
                </div>
            </form>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Edit</th>
                                    <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let icon of iconData; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>{{icon.name}}</td>
                                    <td><img [src]="icon.image" height="100px" width="100px"></td>
                                    <td><button type="button" (click)="editIcon(icon.id)" class="btn btn-outline-info"><i class="fa fa-pencil"></i></button></td>
                                    <td><button type="button" (click)="deleteIcon(icon.id)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Sub Category</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Main Category</label>
                    <ng-select2 
                        [(ngModel)]="categoryId"
                        formControlName="categoryId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a category...'">		
                    </ng-select2>
                    <!-- <select class="form-control" formControlName="categoryId" [(ngModel)]="categoryId" id="inputCategory">
                        <option *ngFor="let category of categoryList" value={{category._id}}>{{category.name}}</option>
                    </select> -->
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Sub Category</label>
                    <input type="text" class="form-control" formControlName="name" [(ngModel)]="name" placeholder="Sub Category">
                </div>
            </div>
            <form [formGroup]="FormIcon" (validSubmit)="addIcon()" novalidate>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Icon Name</label>
                        <input type="text" class="form-control" formControlName="iconName" [(ngModel)]="iconName" placeholder="Icon Name">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="formFileMultiple" class="form-label">Icon</label>
                        <input class="form-control" type="file" (change)="fileChangeEvent($event)" id="formFileMultiple"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">{{updateIconFlag == true ? 'Update' : 'Save'}} Icon</button>
                    <button type="button" class="btn btn-light" (click)="clearIcon()">Close</button>
                </div>
            </form>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Image</th>
                                    <!-- <th scope="col">Delete</th> -->
                                    <!-- <th scope="col">Edit</th> -->
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let icon of iconData; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>{{icon.name}}</td>
                                    <td [hidden]="icon.type"><img [src]="icon.image" height="100px" width="100px"></td>
                                    <td [hidden]="!icon.type"><img [src]="server + icon.path" height="100px" width="100px"></td>
                                    <!-- <td><img [src]="icon.image" height="100px" width="100px"></td> -->
                                    <!-- <td><button type="button" (click)="deleteIcon(icon._id != undefined ? icon._id : icon.id)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button></td> -->
                                    <td><button type="button" (click)="editIcon(icon._id != undefined ? icon._id : icon.id)" class="btn btn-outline-info"><i class="fa fa-pencil"></i></button></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="">
                <label class="modal-title_lebel">Icon List</label>
                <div id="lightgallery" class="row_custome">
                    <a class="lightgallery_inner" href="javascript:void(0);" *ngFor="let icon of iconData; index as i">
                        <img [src]="icon.path" style="width:100px; height: 100px;">
                        <div class="btn_class"><button type="button" (click)="deleteIcon(icon._id)" class="btn btn-danger"><i class="fa fa-times-circle"></i></button></div>
                    </a>
                </div>
            </div> -->
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Sub Category</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
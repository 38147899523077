<div class="card-header border-0 pb-0">
	<h4 class="card-title">Revenue</h4>
	<div class="dropdown dropdown-no-icon ml-auto text-right" ngbDropdown>
		<div class="btn-link" data-toggle="dropdown" ngbDropdownToggle>
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="12" cy="5" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="19" r="2"></circle></g></svg>
		</div>
		<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
			<a class="dropdown-item" href="javascript:void(0);">View Detail</a>
			<a class="dropdown-item" href="javascript:void(0);">Edit</a>
			<a class="dropdown-item" href="javascript:void(0);">Delete</a>
		</div>
	</div>
</div>
<div class="card-body py-2">
	<apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [dataLabels]="chartOptions.dataLabels"
    [stroke]="chartOptions.stroke"	
    [colors]="chartOptions.colors"
  ></apx-chart>
</div>
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ELearnerService } from './e-learner.service';
import { BadgeLevelService } from '../badge-level/badge-level.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from "@avatsaev/three-orbitcontrols-ts";

@Component({
  selector: 'app-e-learner',
  templateUrl: './e-learner.component.html',
  styleUrls: ['./e-learner.component.css']
})
export class ELearnerComponent implements OnInit {

  Form: FormGroup;

  badgeLevelId;
  minimumSecond;
  maxmumSecond;
  lightCode;
  filesupload;
  videofilesupload = [];
  folder;
  SpaceshipSetup;
  updateItemId;
  deleteItemId;
  id;
  index;
  eLearnerList = [];
  badgeLevelList = [];
  dropDownData = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  // server;

  scene;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private eLearnerService: ELearnerService,
    private badgeLevelService: BadgeLevelService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      badgeLevelId: ['-', Validators.required],
      minimumSecond: ['-', Validators.required],
      maxmumSecond: ['-', Validators.required],
      lightCode: ['-', Validators.nullValidator]
    });
  }

  // mychange(e) {
  //   let file = e.target.files[0];
  //   const canvas = <HTMLCanvasElement> document.querySelector('#c');
  //   const renderer = new THREE.WebGLRenderer({canvas});

  //   const fov = 45;
  //   const aspect = 2;  // the canvas default
  //   const near = 0.1;
  //   const far = 100;
  //   const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  //   camera.position.set(0, 10, 20);

  //   const controls = new OrbitControls(camera, canvas);
  //   controls.target.set(0, 5, 0);
  //   controls.update();

  //   const scene = new THREE.Scene();
  //   scene.background = new THREE.Color('black');
 
  //   {
  //     const planeSize = 40;
  
  //     const loader = new THREE.TextureLoader();
  //     const texture = loader.load('https://threejsfundamentals.org/threejs/resources/images/checker.png');
  //     texture.wrapS = THREE.RepeatWrapping;
  //     texture.wrapT = THREE.RepeatWrapping;
  //     texture.magFilter = THREE.NearestFilter;
  //     const repeats = planeSize / 2;
  //     texture.repeat.set(repeats, repeats);
  
  //     const planeGeo = new THREE.PlaneBufferGeometry(planeSize, planeSize);
  //     const planeMat = new THREE.MeshPhongMaterial({
  //       map: texture,
  //       side: THREE.DoubleSide,
  //     });
  //     const mesh = new THREE.Mesh(planeGeo, planeMat);
  //     mesh.rotation.x = Math.PI * -.5;
  //     scene.add(mesh);
  //   }
 
  //   {
  //     const skyColor = 0xB1E1FF;  // light blue
  //     const groundColor = 0xB97A20;  // brownish orange
  //     const intensity = 1;
  //     const light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
  //     scene.add(light);
  //   }
 
  //   {
  //     const color = 0xFFFFFF;
  //     const intensity = 1;
  //     const light = new THREE.DirectionalLight(color, intensity);
  //     light.position.set(5, 10, 2);
  //     scene.add(light);
  //     scene.add(light.target);
  //   }
 
  //   function frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
  //     const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
  //     const halfFovY = THREE.MathUtils.degToRad(camera.fov * .5);
  //     const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
  //     const direction = (new THREE.Vector3())
  //         .subVectors(camera.position, boxCenter)
  //         .multiply(new THREE.Vector3(1, 0, 1))
  //         .normalize();
  
  //     camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));
  //     camera.near = boxSize / 100;
  //     camera.far = boxSize * 100;
  //     camera.updateProjectionMatrix();
  //     camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
  //   }
 
  //   {
  //       const reader = new FileReader();
  //       reader.addEventListener( 'load', function ( event ) {
  
  //         const contents = event.target.result;
      
  //         const loader = new GLTFLoader();
  //         loader.parse( contents, '', function ( gltf ) {
    
  //           const root = gltf.scene;
  //           scene.add(root);
  //           const box = new THREE.Box3().setFromObject(root);
  //           const boxSize = box.getSize(new THREE.Vector3()).length();
  //           const boxCenter = box.getCenter(new THREE.Vector3());
  //           frameArea(boxSize * 0.5, boxSize, boxCenter, camera);
  //           controls.maxDistance = boxSize * 10;
  //           controls.target.copy(boxCenter);
  //           controls.update();
    
  //         });
 
  //       }, false );
  //       reader.readAsArrayBuffer( file );
  //       //   const gltfLoader = new GLTFLoader();
  //       //  gltfLoader.parse( gltfText.target.result, '', function( gltf ){
  //       //     const root = gltf.scene;
  //       //     scene.add(root);
  //       //     const box = new THREE.Box3().setFromObject(root);
  //       //     const boxSize = box.getSize(new THREE.Vector3()).length();
  //       //     const boxCenter = box.getCenter(new THREE.Vector3());
  //       //     frameArea(boxSize * 0.5, boxSize, boxCenter, camera);
  //       //     controls.maxDistance = boxSize * 10;
  //       //     controls.target.copy(boxCenter);
  //       //     controls.update();
  //       //   });
  //   }
 
  //   function resizeRendererToDisplaySize(renderer) {
  //     const canvas = renderer.domElement;
  //     const width = canvas.clientWidth;
  //     const height = canvas.clientHeight;
  //     const needResize = canvas.width !== width || canvas.height !== height;
  //     if (needResize) {
  //       renderer.setSize(width, height, false);
  //     }
  //     return needResize;
  //   }
 
  //   function render() {
  //     if (resizeRendererToDisplaySize(renderer)) {
  //       const canvas = renderer.domElement;
  //       camera.aspect = canvas.clientWidth / canvas.clientHeight;
  //       camera.updateProjectionMatrix();
  //     }
  
  //     renderer.render(scene, camera);
  
  //     requestAnimationFrame(render);
  //   }
 
  //   requestAnimationFrame(render);
 
  // }
  
  ngOnInit(): void {
    // this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData.push({
      id: "-",
      text: "-- Select Badge Level --",
      disabled: true,
      selected: true
    })
    this.badgeLevelService.list({id: this.apiConstant.badgeLevelELearnerId}).then(data => { 
      this.badgeLevelList = data;
      for (let index = 0; index < this.badgeLevelList.length; index++) {
        const element = this.badgeLevelList[index];
        this.dropDownData.push({
          id: element._id,
          text: element.badgeName
        });
      }
    });

    // // Instantiate a loader
    // const loader = new GLTFLoader();
    // // Load a glTF resource
    //   loader.load(
    //     // resource URL
    //     'http://localhost:8080/Earthling/1630400390647-92-1630400389174.gltf',
    //     // called when the resource is loaded
    //     function ( gltf ) {
    //       const scene = new THREE.Scene();
    //       scene.add( gltf.scene );

    //       gltf.animations; // Array<THREE.AnimationClip>
    //       gltf.scene; // THREE.Group
    //       gltf.scenes; // Array<THREE.Group>
    //       gltf.cameras; // Array<THREE.Camera>
    //       gltf.asset; // Object

    //     },
    //     // called while loading is progressing
    //     function ( xhr ) {

    //       console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

    //     },
    //     // called when loading has errors
    //     function ( error ) {

    //       console.log( 'An error happened', error );

    //     }
    //   );


      // const reader = new FileReader();
      // reader.addEventListener( 'load', function ( event ) {

      //     const contents = event.target.result;

      //     const loader = new GLTFLoader();
      //     loader.parse( contents, '', function ( gltf ) {

      //         const scene = gltf.scene;
      //         console.log( scene );

      //     } );

      // }, false );
      // reader.readAsArrayBuffer( file );
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.eLearnerService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.eLearnerService.pagination(data).then(data => {
      for (let i = 0, p = Promise.resolve(); i < data.data.length; i++) {
        p = p.then(_ => new Promise(resolve => {
          var e = data.data[i];
          e.minimumSecond = e.minimumSecond / 60 / 60;
          e.maxmumSecond = e.maxmumSecond / 60 / 60;
          e.spaceshipSetup.forEach((element, index) => {
            element.index = index;
            element.tp = true;
            element.totalSecond = element.totalSecond / 60 / 60;
            if(e.spaceshipSetup.length == (index + 1)) {
              if(data.data.length == (i + 1)) {
                this.eLearnerList = data.data;
              }
              resolve();
            }
          });
        }));
      }
    });
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.filesupload = [];
    this.videofilesupload = [];
    this.SpaceshipSetup = [];
    this.badgeLevelId = null;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id != '-') {
      this.dropDownData.unshift({
        id: "-",
        text: "-- Select Badge Level --",
        disabled: true,
        selected: true
      });
    }
  }

  fileChangeEventVideo(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0, p = Promise.resolve(); i < event.target.files.length; i++) {
        p = p.then(_ => new Promise(resolve => {
            var filename = event.target.files[i].name.split('.');
            this.videofilesupload.push({file: event.target.files[i], filename: Date.now() + '.' + filename[filename.length - 1]});
            var reader = new FileReader();
            reader.onload = (event:any) => {
              resolve();
            }
            reader.readAsDataURL(event.target.files[i]);
        }));
      }
    }
  }

  fileChangeEvent(event) {
    if(this.Form.value.minimumSecond == null || isNaN(parseFloat(this.Form.value.minimumSecond))) {
      this.toastr.errorToastr('Please enter minimum second!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
    else if(this.Form.value.maxmumSecond == null || isNaN(parseFloat(this.Form.value.maxmumSecond)) || parseInt(this.Form.value.maxmumSecond) == 0) {
      this.toastr.errorToastr('Please enter maximum second!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
    else {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0, p = Promise.resolve(); i < event.target.files.length; i++) {
          p = p.then(_ => new Promise(resolve => {
            var index = this.SpaceshipSetup.length > 0 ? (this.SpaceshipSetup[this.SpaceshipSetup.length - 1].index + 1) : 0;
            var filename = event.target.files[i].name.split('.');
            this.filesupload.push({index: index, value: event.target.files[i], filename: Date.now() + '.' + filename[filename.length - 1]});
            var reader = new FileReader();
            reader.onload = (event:any) => {
              this.SpaceshipSetup.push({index: index, tp: false, number: (this.SpaceshipSetup.length + 1), totalSecond: 0, percentage: 0, image: event.target.result}); 
              resolve();
            }
            reader.readAsDataURL(event.target.files[i]);
          }));
        }
      }
    }
  }

  fileChangeEventEdit(event, item, i) {
    if(this.Form.value.minimumSecond == null || isNaN(parseFloat(this.Form.value.minimumSecond))) {
      this.toastr.errorToastr('Please enter minimum second!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
    else if(this.Form.value.maxmumSecond == null || isNaN(parseFloat(this.Form.value.maxmumSecond)) || parseInt(this.Form.value.maxmumSecond) == 0) {
      this.toastr.errorToastr('Please enter maximum second!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
    else {
      if (event.target.files && event.target.files[0]) {
        item.tp = false;
        var reader = new FileReader();
        reader.onload = (event:any) => {
          item.image = event.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);
        var filename = event.target.files[0].name.split('.');
        filename = Date.now() + '.' + filename[filename.length - 1];
        if(item._id != undefined) {
          var idx = this.updateItemId.findIndex(x => x._id == item._id);
          if(idx != -1) {
            this.updateItemId[idx].image = filename;
          }
          else {
            this.updateItemId.push({_id: item._id, image: filename});
          }
        }
        this.filesupload[item.index] = {index: item.index, value: event.target.files[0], filename: filename};
      }
    }
  }

  onKeypressEventMini(event: any) {
    if(this.SpaceshipSetup.length > 0 && this.Form.value.maxmumSecond != null && parseInt(this.Form.value.maxmumSecond) != 0) {
      var totalSecond = parseInt(this.Form.value.maxmumSecond) - parseInt(this.Form.value.minimumSecond);
      this.SpaceshipSetup.forEach(element => {
        element.totalSecond = (totalSecond * element.percentage) / 100;
      });
    }
  }

  onKeypressEventMaxi(event: any) {
    if(this.SpaceshipSetup.length > 0 && this.Form.value.minimumSecond != null) {
      var totalSecond = parseInt(this.Form.value.maxmumSecond) - parseInt(this.Form.value.minimumSecond);
      this.SpaceshipSetup.forEach(element => {
        element.totalSecond = (totalSecond * element.percentage) / 100;
      });
    }
  }
  
  onKeypressEvent(event: any, data) {
    this.SpaceshipSetup[data.index].percentage = parseFloat(event.target.value);
    var totalSecond = parseInt(this.Form.value.maxmumSecond) - parseInt(this.Form.value.minimumSecond);
    this.SpaceshipSetup[data.index].totalSecond = (totalSecond * parseFloat(event.target.value)) / 100;
    if(isNaN(this.SpaceshipSetup[data.index].percentage)) {
      this.SpaceshipSetup[data.index].percentage = 0;
    }
    if(isNaN(this.SpaceshipSetup[data.index].totalSecond)) {
      this.SpaceshipSetup[data.index].totalSecond = 0;
    }
  }

  onKeypressEventNum(event: any, data) {
    this.SpaceshipSetup[data.index].number = parseInt(event.target.value);
  }

  deleteImage(data) {
    if(data._id != undefined) {
      this.deleteItemId.push(data._id);
      var idx = this.updateItemId.findIndex(x => x._id == data._id);
      if(idx != -1) {
        this.updateItemId.splice(idx, 1);
      }
    }
    this.SpaceshipSetup.splice((data.index), 1);
    this.SpaceshipSetup.forEach((element, index) => {
      element.index = index;
    });
    this.filesupload.splice((data.index), 1);
    this.filesupload.forEach((element, index) => {
      element.index = index;
    });
  }

  add() {
    this.addbtn = true;
    if(this.filesupload.length > 0) {
      var files = [];
      this.filesupload.forEach((e, i) => {
        if(e.value != undefined) {
          files.push({file: e.value, filename: e.filename});
        }
        if(this.filesupload.length == (i + 1)) {
          this.Form.value.maxmumSecond = parseInt(this.Form.value.maxmumSecond) * 60 * 60;
          this.Form.value.minimumSecond = parseInt(this.Form.value.minimumSecond) * 60 * 60;
          var spaceshipSetup = [];
          this.SpaceshipSetup.forEach((element, index) => {
            spaceshipSetup.push({
              number: element.number,
              percentage: element.percentage,
              totalSecond: element.totalSecond * 60 * 60
            });
            if(this.SpaceshipSetup.length == (index + 1)) {
              this.Form.value.spaceshipSetup = spaceshipSetup;
              // var category = this.badgeLevelList.find(x => x._id == this.Form.value.badgeLevelId).badgeName;
              localStorage.setItem('category', this.Form.value.badgeLevelId);
              this.eLearnerService.add(this.Form.value, files, this.videofilesupload).then(data => {
                if(data.flag == true) {
                  data.data.minimumSecond = data.data.minimumSecond / 60 / 60;
                  data.data.maxmumSecond = data.data.maxmumSecond / 60 / 60;
                  data.data.spaceshipSetup.forEach((element, index) => {
                    element.index = index;
                    element.tp = true;
                    element.totalSecond = element.totalSecond / 60 / 60;
                    if(data.data.spaceshipSetup.length == (index + 1)) {
                      this.eLearnerList.unshift(data.data);
                    }
                  });
                  this.total = (parseInt(this.total) + 1).toString();
                  this.toastr.successToastr(data.message, 'E-Learner Added!', { animate: 'slideFromRight', showCloseButton: true });
                  this.modalService.dismissAll();
                  this.clear();
                }
                else {
                  this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
                  this.addbtn = false; 
                  this.modalService.dismissAll();
                }
                localStorage.removeItem('category');
              });
            }
          });
        }
      });
    }
    else {
      this.addbtn = false;
      this.toastr.errorToastr('Image Upload!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.filesupload = [];
    this.videofilesupload = [];
    this.updateItemId = [];
    this.deleteItemId = [];
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.badgeLevelId = data.badgeLevelId;
    this.minimumSecond = data.minimumSecond;
    this.maxmumSecond = data.maxmumSecond;
    this.lightCode = data.lightCode;
    this.folder = data.folder;
    this.SpaceshipSetup = [];
    data.spaceshipSetup.forEach((element, index) => {
      element.index = index;
      this.filesupload.push({index: index, value: undefined});
      this.SpaceshipSetup.push(element)
    });
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id == '-') {
      this.dropDownData.splice(0, 1);
    }
  }

  update() {
    var files = [];
    var filesupload = this.filesupload;
    new Promise(function(resolve, reject) {
      if(filesupload.length > 0) {
        filesupload.forEach((e, i) => {
          if(e.value != undefined) {
            files.push({file: e.value, filename: e.filename});
          }
          if(filesupload.length == (i + 1)) {
            resolve(0);
          }
        });
      }
      else {
        resolve(0);
      }
    }).then(next => {
      this.updatebtn = true;
      this.Form.value.maxmumSecond = parseInt(this.Form.value.maxmumSecond) * 60 * 60;
      this.Form.value.minimumSecond = parseInt(this.Form.value.minimumSecond) * 60 * 60;
      var spaceshipSetup = [];
      this.SpaceshipSetup.forEach((element, index) => {
          spaceshipSetup.push({
            _id: element._id,
            number: element.number,
            percentage: element.percentage,
            totalSecond: element.totalSecond * 60 * 60,
          });
        if(this.SpaceshipSetup.length == (index + 1)) {
          this.Form.value.spaceshipSetup = spaceshipSetup;
          this.Form.value.updateItemId = this.updateItemId;
          this.Form.value.deleteItemId = this.deleteItemId;
          // var category = this.badgeLevelList.find(x => x._id == this.Form.value.badgeLevelId).badgeName;
          localStorage.setItem('category', this.Form.value.badgeLevelId);
          this.eLearnerService.update(this.Form.value, files, this.videofilesupload, this.id).then(data => {
            if(data.flag == true) {
              // this.eLearnerList[this.index] = data.data;
              var e = data.data;
              e.minimumSecond = e.minimumSecond / 60 / 60;
              e.maxmumSecond = e.maxmumSecond / 60 / 60;
              e.spaceshipSetup.forEach((element, i) => {
                element.index = i;
                element.tp = true;
                element.totalSecond = element.totalSecond / 60 / 60;
                if(e.spaceshipSetup.length == (i + 1)) {
                    this.eLearnerList[this.index] = data.data;
                }
              });

              this.toastr.successToastr(data.message, 'E-Learner Updated!', { animate: 'slideFromRight', showCloseButton: true });
              this.modalService.dismissAll();
              this.clear();
            }
            else {
              this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
              this.modalService.dismissAll();
              this.updatebtn = false;
            }
            localStorage.removeItem('category');
          });
        }
      });
    });
  }

  delete() {
    this.deletebtn = true;
    this.eLearnerService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.eLearnerList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'E-Learner Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.eLearnerService.active(json, id).then(data => {
      if(data.flag == true) {
        this.eLearnerList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
import { Component, OnInit, Injectable } from '@angular/core';;
import { BeneficiaryListService } from './beneficiary-list.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.css']
})
export class BeneficiaryListComponent implements OnInit {

  beneficiaryList = [];

  constructor(
    private beneficiaryListService: BeneficiaryListService,
    private toastr: ToastrManager,
  ) { 
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  ngOnInit(): void {
    this.beneficiaryListService.list().then(data => this.beneficiaryList = data.data);
  }
}
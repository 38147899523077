import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  canActivate() {
    if(localStorage.getItem('role') == 'Super Admin' || localStorage.getItem('role') == 'Admin') {
      return true;
    }
    return false;
  }
  
}

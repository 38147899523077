import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { OfferBannerService } from './offer-banner.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-offer-banner',
  templateUrl: './offer-banner.component.html',
  styleUrls: ['./offer-banner.component.css']
})
export class OfferBannerComponent implements OnInit {

  Form: FormGroup;

  type;
  image;
  link;
  id;
  index;
  filesupload = [];
  offerBannerList = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private offerBannerService: OfferBannerService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      type: ['-', Validators.required],
      link: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.offerBannerService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.offerBannerService.pagination(data).then(data => this.offerBannerList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.filesupload = [];
  }

  fileChangeEvent(event) {
    this.filesupload = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  add() {
    if(this.filesupload.length > 0) {
      this.addbtn = true;
      localStorage.setItem('category', this.apiConstant.badgeLevelELearnerId);
      this.offerBannerService.add(this.Form.value, this.filesupload).then(data => {
        if(data.flag == true) {;
          this.offerBannerList.unshift(data.data);
          this.total = (parseInt(this.total) + 1).toString();
          this.toastr.successToastr(data.message, 'E-Learner Slider Added!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.addbtn = false; 
          this.modalService.dismissAll();
        }
        localStorage.removeItem('category');
      });
    }
    else {
      this.toastr.errorToastr('Please Upload Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.image = data.image;
    this.type = data.type;
    this.link = data.link;
  }

  update() {
    this.updatebtn = true;
    localStorage.setItem('category', this.apiConstant.badgeLevelELearnerId);
    this.offerBannerService.update(this.Form.value, this.id, this.filesupload, this.image).then(data => {
      if(data.flag == true) {
        this.offerBannerList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'E-Learner Slider Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
      localStorage.removeItem('category');
    });
  }

  delete() {
    this.deletebtn = true;
    this.offerBannerService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.offerBannerList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'E-Learner Slider Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.offerBannerService.active(json, id).then(data => {
      if(data.flag == true) {
        this.offerBannerList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
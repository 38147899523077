<!--**********************************
            Footer start
***********************************-->
<div class="footer">
	<div class="copyright">
		<p>Copyright © Designed &amp; Developed by <a href="#" target="_blank">Meeko</a> {{date | date: 'yyyy'}}</p>
	</div>
</div>
<!--**********************************
	Footer end
***********************************-->
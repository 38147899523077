<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <div class="page-titles">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="javascript:void(0)">Educator User</a>
        </li>
        <li class="breadcrumb-item active">
          <a href="javascript:void(0)">List</a>
        </li>
      </ol>
    </div>
    <div class="row">
      <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
      <div class="col-md-9"></div>
      <div class="col-md-3 search-addbtn">
        <div class="input-group search-area d-xl-inline-flex d-none">
          <input
            type="text"
            class="form-control"
            id="search"
            name="search" 
            [(ngModel)]="search"
            placeholder="Search here..."
          />
          <!-- <div class="input-group-append">
            <span class="input-group-text"
              ><a href="javascript:void(0)"
                ><i class="flaticon-381-search-2"></i></a
            ></span>
          </div> -->
          <div class="input-group-append">
            <span class="input-group-text search_btn"
              ><button type="button" (click)="searchBtn()"
                ><i class="flaticon-381-search-2"></i></button
            ></span>
          </div>
        </div>
        <!-- <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div> -->
      </div>
    </div>
    <div class="col-xl-12 col-xxl-12">&nbsp;</div>
    <div class="col-xl-12 col-xxl-12">
      <div class="card">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="text-left py-2 px-3">#</th>
                <th scope="col" class="text-left px-1 py-2">Date</th>
                <th scope="col" class="text-left px-1 py-2">Full Name</th>
                <th scope="col" class="text-left px-1 py-2">Legal Name</th>
                <th scope="col" class="text-left px-1 py-2">Email</th>
                <th scope="col" class="text-left px-1 py-2">Mobile</th>
                <th scope="col" class="text-left px-1 py-2">Address</th>
                <th scope="col" class="text-center py-2">Status</th>
                <th scope="col" class="text-center py-2">Profile</th>
                <th scope="col" class="text-center py-2">Sign In</th>
                <th scope="col" class="text-center py-2">Action</th>
              </tr>
            </thead>
            <!-- <tbody class="Tbody"> -->
            <tbody>
              <tr *ngFor="let educatorUser of educatorUserList; index as i">
                <td scope="row" class="px-3">{{ i + 1 }}</td>
                <td>{{ educatorUser.createAt | date: 'medium':'IST' }}</td>
                <td>{{ educatorUser.firstName }} {{ educatorUser.lastName }}</td>
                <td>{{ educatorUser.fullName }}</td>
                <td>{{ educatorUser.email }}</td>
                <td>{{ educatorUser.mobile }}</td>
                <td>{{ educatorUser.address }}</td>
                <td>{{ educatorUser.active }}</td>
                <!-- <td class="text-center">
                                <button [hidden]="educatorUser.active" (click)="status(educatorUser.active, educatorUser._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!educatorUser.active" (click)="status(educatorUser.active, educatorUser._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td> -->
                <td class="text-center">
                  <button
                    type="button"
                    (click)="show(educatorUser, profile, i)"
                    class="btn btn-outline-info"
                  >
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </button>
                </td>
                <td class="text-center">
                  <a [href]="url + 'admin/access?token=' + educatorUser.token" target="_blank"><i class="fa fa-sign-in btn btn-outline-primary"></i></a>
                </td>
                <td class="text-center">
                  <button
                    type="button"
                    (click)="show(educatorUser, act, i)"
                    class="btn btn-outline-info"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <!-- <button type="button" (click)="show(educatorUser, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button> -->
                    <button type="button" (click)="show(educatorUser, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-xl-12 col-xxl-12">
      <ngb-pagination
        [collectionSize]="total"
        [(page)]="page"
        [pageSize]="selectPageLimit"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<!-- <ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Educator User</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Educator User</label>
                    <input type="text" class="form-control" formControlName="name" placeholder="Educator User">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Educator User</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Educator User</label>
                    <input type="text" class="form-control" formControlName="name" [(ngModel)]="name" placeholder="Educator User">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template> -->

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Educator User</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<ng-template #act let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Suspended Educator</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="Form" (validSubmit)="status()" novalidate>
    <div class="modal-body">
        <div class="row">
          <div class="form-group col-md-12">
            <label>Status</label>
            <ng-select2
              [(ngModel)]="active"
              [data]="dropDownData"
              formControlName="active"
              [placeholder]="'Please select a status...'"
            >
            </ng-select2>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
              <label>Reason</label>
              <textarea class="form-control" rows="4" formControlName="reason" [(ngModel)]="reason" id="reason"></textarea>
          </div>
        </div>
    </div>
    <div class="modal-footer">
      <button
        [disabled]="deletebtn"
        type="submit"
        class="btn btn-primary"
      >
        Save
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click')"
      >
        Close
      </button>
    </div>
  </form>
</ng-template>

<!-- educator sign up steps -->
<ng-template #profile let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Educator Profile</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row profile">
      <div class="form-group col-md-2">
        <label>Profile Picture</label>

        <img
          [src]="server + data.profilePic.path"
          height="100px"
          width="100px"
        (click)="openFile(server + data.profilePic.path)"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <span class="about">Display Name</span>
        <span> {{ data.fullName != undefined && data.fullName != '' ? data.fullName : data.firstName + ' ' + data.lastName }}</span>
      </div>
      <div class="col-md-4" [hidden]="data.legalName == ''">
        <span class="about">Legal Name</span>
        <span>{{ data.legalName }}</span>
      </div>
      <div class="col-md-4" [hidden]="data.gender == ''">
        <span class="about">Gender</span>
        <span> {{ data.gender }}</span>
      </div>
    </div>

    <div class="row mt-3" [hidden]="data.aboutMe == ''">
      <div class="col-md-12">
        <span class="about about_section">About You</span>
        <span>{{ data.aboutMe }}</span>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6" [hidden]="data.address == ''">
        <span class="about">Address</span>
        <span> {{ data.address }}</span>
      </div>
      <div class="col-md-6" [hidden]="data.landmark == ''">
        <span class="about">Landmark</span>
        <span> {{ data.landmark }}</span>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-3" [hidden]="data.country == undefined">
        <span class="about">Country</span>
        <span>{{ data.countryDetail != undefined ? data.countryDetail.name : '' }} </span>
      </div>
      <div class="col-md-3" [hidden]="data.state == undefined">
        <span class="about">State</span>
        <span>{{ data.countryDetail != undefined ? data.countryDetail.states.name : '' }} </span>
      </div>
      <div class="col-md-3" [hidden]="data.city == undefined">
        <span class="about">City</span>
        <span> {{ data.countryDetail != undefined ? data.countryDetail.states.cities.name : '' }} </span>
      </div>
      <div class="col-md-3" [hidden]="data.pincode == ''">
        <span class="about">Zip/Pincode</span>
        <span> {{ data.pincode }}</span>
      </div>
    </div>

    <!-- start past experience -->
    <div class="Experience" [hidden]="data.pastExperiences.length == 0">
      <div class="Experience_title">
        <span> Past Experience </span>
      </div>
      <div class="main_div" *ngFor="let pastExperiences of data.pastExperiences">
        <div class="Experience_title">
          <!-- <span> Past Experience </span> -->
          <div class="about_section" style="margin: 15px 0px 28px 0px">
            <span class="about">Organization Name </span>
            <span> {{ pastExperiences.organizationName }}</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-3">
            <span class="about"> Joining Date</span>
            <span> </span>
            {{ pastExperiences.join }}
          </div>
          <div class="col-md-3">
            <span class="about"> End Date </span>
            <span>{{ pastExperiences.end }}</span>
          </div>
          <div class="col-md-3">
            <span class="about"> Experience In Years</span>
            <span> {{ pastExperiences.experienceInYears }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- end past experience -->

    <!-- start Education Details -->

    <div class="Experience education" [hidden]="data.educationDetails.length == 0">
      <div class="Experience_title">
        <span>Education Details </span>
      </div>
      <div class="main_div" *ngFor="let educationDetails of data.educationDetails">
        <div class="row mt-3">
          <div class="col-md-4">
            <span class="about">Institute Name </span>
            <span>{{ educationDetails.instituteName }}</span>
          </div>

          <div class="col-md-3">
            <div class="text-center">
              <span class="about about_section">Year Of Passing</span>
              <span>{{ educationDetails.yearOfPassing }}</span>
            </div>
          </div>

          <div class="col-md-2 center">
            <div class="text-center">
              <span class="about about_section">Degree</span>
              <span>{{ educationDetails.degreeName }}</span>
            </div>
          </div>

          <div class="form-group col-md-2">
            <label>Certificate</label>
            <img
              [src]="server + educationDetails.path"
              height="100px"
              width="100px"
              (click)="openFile(server + educationDetails.path)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- end Education Details -->

    <!-- start Certificates  -->

    <span class="about certi" [hidden]="data.certificates.length == 0">Certificates</span>
    <div class="row certirow" [hidden]="data.certificates.length == 0">
      <div class="form-group col-md-2" *ngFor="let certificates of data.certificates">
        <img
          [src]="server + certificates.path"
          height="100px"
          width="100px"
          (click)="openFile(server + certificates.path)"
        />
      </div>
    </div>
    <!-- end Certificates  -->

    <!-- start Digital Profile  -->

    <div class="row" [hidden]="data.digitalProfileLink.length == 0">
      <div class="col-md-4">
        <span class="about about_section">Digital Profile</span>
        <div class="digital" *ngFor="let digitalProfileLink of data.digitalProfileLink">
          <div>
            <span>{{digitalProfileLink.name}} : </span>
            <span>{{digitalProfileLink.link}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end Digital Profile  -->

    <!-- start Category -->

    <div class="Category" [hidden]="data.categories.length == 0">
      <div class="Experience_title">
        <span> Category</span>
      </div>

      <div class="main_div" *ngFor="let categories of data.categories" [hidden]="categories.mainCategories == undefined">
        <div class="row">
          <div class="col-md-6">
            <span class="about">Category</span>
            <span class="">{{categories.mainCategories}} </span>
          </div>
          <div class="col-md-6">
            <span class="about"> Sub Category</span>
            <span class=""> {{categories.subCategories}} </span>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-4">
            <span class="about"> Total Experience</span>
            <span>{{categories.experienceInYears}} Year</span>
          </div>
          <div class="col-md-4">
            <span class="about">Age Range</span>
            <span class="about">Min</span>
            <span>{{categories.studentsAgeRange != undefined ? categories.studentsAgeRange.minimum : ''}}</span>
          </div>
          <div class="col-md-4">
            <span class="about">Max</span>
            <span>{{categories.studentsAgeRange != undefined ? categories.studentsAgeRange.maximum : ''}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end Category -->

    <!-- start Description -->

    <div class="row" [hidden]="data.aboutClass == ''">
      <div class="col-md-12">
        <span class="about about_section">Class Description</span>
        <span>{{ data.aboutClass }} </span>
      </div>
    </div>
    <!-- end Description -->

    <!-- start Languages -->

    <div class="row" [hidden]="data.demoClass == undefined">
      <div class="col-md-6">
        <span class="about about_section">Languages you speak</span>
        <div class="Languages" *ngFor="let languages of data.languages">
          <span class="about_section"> {{languages.name}}</span>
        </div>
      </div>
      <div class="col-md-6">
        <span class="about about_section">Upload Demo Class</span>
        <div class="form-group col-md-4 certirow">
          <video width="150px" height="150px" controls>
              <source [src]="data.demoClass != undefined ? server + data.demoClass.path : ''" >
          </video>
        </div>
      </div>
    </div>
    <!-- end Languages -->

    <!-- start proof -->
    <div class="row proof" [hidden]="data.idProof.proofName == ''">
      <!-- <div class="col-md-2"></div> -->
      <!-- <div class="col-md-4">
        <span class="about"> Proof Name</span>
        <span>{{ data.idProof.proofName }}</span>
      </div> -->

      <!-- <div class="col-md-2">
        <span>{{ data.idProof.proofName }}</span>
      </div> -->
      <!-- <div class="col-md-3">
        <span class="about"> Address Proof Name</span>
      </div> -->
      <!-- <div class="col-md-2">
        <span>{{ data.addressProof.proofName }}</span>
      </div> -->
      <div class="form-group col-md-2 upload">
        <label> Selfie</label>
        <img [src]="data.selfie != undefined ? server + data.selfie.path : ''" height="100px" width="100px" (click)="openFile(server + data.selfie.path)" />
      </div>
      <div class="form-group col-md-3 upload">
        <span style="color:#60adce;">{{ data.idProof.proofName }}</span>
        <label> ID Proof (Front)</label>
        <img
          [src]="data.idProof != undefined ? server + data.idProof.frontPath : ''"
          height="100px"
          width="100px"
          (click)="openFile(server + data.idProof.frontPath)"
        />
      </div>
      <div class="form-group col-md-3 upload">
        <span style="color:#60adce;">{{ data.idProof.proofName }}</span>
        <label> ID Proof (Back)</label>
        <img
          [src]="server + data.idProof.backPath"
          height="100px"
          width="100px"
          (click)="openFile(server + data.idProof.backPath)"
        />
      </div>
      <div class="form-group col-md-3 upload">
        <span style="color:#60adce;">{{ data.addressProof.proofName }}</span>
        <label> Address Proof</label>
        <img
          [src]="data.addressProof != undefined ? server + data.addressProof.frontPath : ''"
          height="100px"
          width="100px"
          (click)="openFile(server + data.addressProof.frontPath)"
        />
      </div>
    </div>
    <!-- start proof -->
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

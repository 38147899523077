import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { LoginService } from '../login/login.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  AuthForm: FormGroup;
  btn = false;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: LoginService,
    private router: Router,
    private toastr: ToastrManager,
    private titleService: Title
  ) { 
    this.formAuth();
  }

  formAuth() {
    this.AuthForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Forgot Password' + ' - Meeko');
  }

  forgotPassword() {
    this.btn = true;
    this.authenticationService.forgotPassword(this.AuthForm.value).then(data => {
      if(data.flag) {
        this.toastr.successToastr(data.message, 'Forgot Password', { animate: 'slideFromRight', showCloseButton: true });
        this.router.navigate(['/']);
        this.titleService.setTitle('Login' + ' - Meeko');
      }
      else {
        this.btn = false;
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.AuthForm.reset();
      }
    });
  }

}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class EducatorPackageService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data) {
    return this.httpmanagerService.HttpPost('educatorpackage', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('educatorpackage', "").then(data => {
      return data.data;
    });
  }
  alllist() {
    return this.httpmanagerService.HttpGet('educatorpackage/list', "").then(data => {
      return data.data;
    });
  }

  packageDetail() {
    return this.httpmanagerService.HttpGet('educatorpackage/packagedetail', "").then(data => {
      return data.data;
    });
  }

  update(data, id) {
    return this.httpmanagerService.HttpPut('educatorpackage', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('educatorpackage', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('educatorpackage/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('educatorpackage/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('educatorpackage/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

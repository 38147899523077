import { Component, OnInit } from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	providers: [NgbDropdownConfig]
})
export class HeaderComponent implements OnInit {
	
	toggleChat: boolean = true;
	toggleSingle: boolean = true;
	
	constructor(
		private router: Router,
		private titleService: Title
	) { }
	
	ngOnInit(): void {
	}
	
	
	togglechatbar() {
		this.toggleChat = !this.toggleChat;
	}
	singleChatWindow() {
		this.toggleSingle = !this.toggleSingle;
	}
	
	logout() {
		this.router.navigate(['/']);
		localStorage.removeItem('tokenData');
		localStorage.removeItem('role'); 
		this.titleService.setTitle('Login' + ' - Meeko');
	}
}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class EmailConfigurationService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data) {
    return this.httpmanagerService.HttpPost('emailconfiguration', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('emailconfiguration', "").then(data => {
      return data.data;
    });
  }

  update(data, id) {
    return this.httpmanagerService.HttpPut('emailconfiguration', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('emailconfiguration', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('emailconfiguration/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('emailconfiguration/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('emailconfiguration/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-young',
  templateUrl: './young.component.html',
  styleUrls: ['./young.component.css']
})
export class YoungComponent implements OnInit {

  constructor() { }
  
  
    chartOptions = {
      series: [50],
      chart: {
        height: 110,
        width: 110,
        type: "radialBar",
		zoom: {
			enabled: false
		},
		sparkline: {
			enabled: true
		}
      },
      legend: {
		show:false,
      },
	  colors:['#FE634E'],
	  plotOptions: {
        radialBar: {
          
          hollow: {
            margin: 0,
            size: "50%",
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
			 offsetY: 7,
              show: true
            }
          }
        }
      },
      labels: ["Events"]
    };
	
	

  ngOnInit(): void {
  }

}

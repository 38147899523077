import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data) {
    return this.httpmanagerService.HttpPost('keyword', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('keyword', "").then(data => {
      return data.data;
    });
  }
}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class BitmojiAccessService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data ,images) {
    return this.httpmanagerService.HttpPostImage('bitmojiaccess', images, JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  addimage(data, images, id) {
    return this.httpmanagerService.HttpPostImage('bitmojiaccess/addimage', images, JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('bitmojiaccess', "").then(data => {
      return data.data;
    });
  }

  update(data, id) {
    return this.httpmanagerService.HttpPut('bitmojiaccess', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  updateimage(data, id, image, bgimage, oldgltf, oldimage) {
    data.oldgltf = oldgltf; 
    data.oldimage = oldimage; 
    return this.httpmanagerService.HttpPostMultiImage('bitmojiaccess/updateimage', image, bgimage, JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  deleteimage(data, id) {
    return this.httpmanagerService.HttpPut('bitmojiaccess/deleteimage', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('bitmojiaccess', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('bitmojiaccess/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('bitmojiaccess/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('bitmojiaccess/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

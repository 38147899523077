<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Classes</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <form [formGroup]="Form" (validSubmit)="filter()" novalidate> -->
                <!-- <div class="col-md-1">
                    <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                    <option selected value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <ng-select2 
                        [(ngModel)]="status"
                        [data]="statusData"
                        (valueChanged)="onType($event)"
                        [placeholder]="'Please select a status...'">		
                    </ng-select2>
                </div>
                <!-- <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" formControlName="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" formControlName="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-4 search-addbtn">
                    <div class="input-group search-area d-xl-inline-flex d-none">
                        <input type="text" class="form-control" id="search" placeholder="Search here...">
                        <div class="input-group-append">
                            <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                        </div>
                    </div>
                    <div class="ml-3">
                    </div>
                </div>
            <!-- </form> -->
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Class Name</th>
                            <th scope="col" class="text-left px-1 py-2">Full Name</th>
                            <th scope="col" class="text-left px-1 py-2">Email</th>
                            <th scope="col" class="text-left px-1 py-2">Mobile</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Status</th> -->
                            <th scope="col" class="text-left px-1 py-2">View</th>
                            <th scope="col" class="text-left px-1 py-2">Batch</th>
                            <th scope="col" class="text-left px-1 py-2">Review</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let classes of classesList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ classes.className }}</td>
                            <td>{{ classes.educatorName }}</td>
                            <td>{{ classes.educatorEmail }}</td>
                            <td>{{ classes.educatorMobile }}</td>
                            <!-- <td>{{ classes.status }}</td> -->
                            <td class="text-center">
                                <button [disabled]="status == 'Draft'"
                                  type="button"
                                  (click)="show(classes._id, 'cv', i)"
                                  class="btn btn-outline-info"
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td class="text-center">
                                <button [disabled]="status == 'Draft'"
                                    type="button"
                                    (click)="show(classes._id, 'bcv', i)"
                                    class="btn btn-outline-primary"
                                >
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td class="text-center">
                                <button [hidden]="classes.inReview == true" [disabled]="classes.inReview != true"
                                    type="button"
                                    (click)="show(classes._id, review, i)"
                                    class="btn btn-outline-success"
                                >
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </button>
                                <button [hidden]="status != 'InReview'" [disabled]="classes.inReview != true"
                                    type="button"
                                    (click)="show(classes._id, review, i)"
                                    class="btn btn-outline-secondary"
                                >
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </button>
                                <button [hidden]="status != 'InReview'" [disabled]="classes.inReview != true"
                                    type="button"
                                    (click)="show(classes._id, reject, i)"
                                    class="btn btn-outline-secondary"
                                >
                                    <i class="fa fa-close" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<!-- <ng-template #act let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Class View</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template> -->

<ng-template #review let-modal>
    <div class="modal-header">
      <h4 class="modal-title">In Review Class</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="inReview()">Review</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<ng-template #reject let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Reject Class</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="rejectFnc()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Message</label>
                    <textarea class="form-control" rows="4" formControlName="message" id="message"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Reject</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>
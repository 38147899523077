import { Component, OnInit, ViewChild, Injectable, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { DashboardService } from './dashboard.service';
import { SubscriptionPackageService } from '../subscription-package/subscription-package.service';
import { CategoryService } from '../category/category.service';
import { SubCategoryService } from '../sub-category/sub-category.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexStroke,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexTooltip
} from "ng-apexcharts";

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
};

export type ChartOptionsPie = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  labels: any;
  legend: ApexLegend;
  colors: string[];
};

export type ChartOptionsBar = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
  colors: string[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
};

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
        `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
        '';
  }
}

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [
    NgbDropdownConfig,
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class DashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsPie: Partial<ChartOptionsPie>;
  public chartOptionsPie2: Partial<ChartOptionsPie>;
  public chartOptionsPie3: Partial<ChartOptionsPie>;
  public chartOptions2: Partial<ChartOptions>;
  public chartOptionsBar: Partial<ChartOptionsBar>;
  public chartOptions3: Partial<ChartOptions>;

  @ViewChild('search') inputName;
  @ViewChild('searchEud') nputNameEud;

  Form: FormGroup;
  FormParent: FormGroup;
  FormEud: FormGroup;

  active = 1;
  timePeriod;
  selected;
  selectedeLearner;
  selectedcomplaint;
  selectedeRevenue;
  selectedeEducator;
  selectedePopularCategory;
  eLearner;
  complaint;
  revenue;
  colors;
  educator;
  classes;
  cActive;
  cDeactive;
  cDraft;
  cInReview;
  cTotal;
  popularCategory;
  tPending;
  tActive;
  tDeactive;
  tReject;
  pPending;
  pActive;
  pDeactive;
  pReject;
  compPending;
  compInProgress;
  compRejected;
  compApproved;
  compClose;
  compPrvPending;
  compPrvInProgress;
  compPrvRejected;
  compPrvApproved;
  compPrvClose;
  compTotal;
  total;
  state;
  city;
  category;
  subscriptionPackage;
  RRDays;
  status;
  statedata;
  stateData;
  cityData;
  categoryData;
  subCategoryData1;
  subCategoryData;
  subscriptionPackageData;
  statusData;
  statusDataEdu;
  fromAge;
  toAge;
  subCategory;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;

  totalClassesBooking = 0;
  totalPurchaseMekkoin = 0;
  totalPurchaseTopUp = 0;
  totalPurchaseDetail = 0;
  totalEducatorPurchaseDetail = 0;
  totalAdminConvenienceFeesDetail = 0;
  totalTax = 0;
  totalIncomeStatement = 0;
  totalGrossRevenue = 0;
  totalELearner = 0;
  totalParent = 0;
  totalEducator = 0;
  totalClasses = 0;
  totalGrossRevenue2 = 0;

  parentReportList = [];
  eLearnerReportList = [];
  educatorReportList = [];
  childList = [];
  filesupload = [];

  authorization = false;
  btn = true;

  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  calendarVisible = true;
  liveStreamingVisible = true;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // select: this.handleDateSelect.bind(this),
    // eventClick: this.handleEventClick.bind(this),
    // eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  liveStreamingOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // select: this.handleDateSelect.bind(this),
    // eventClick: this.handleEventClick.bind(this),
    // eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };

  constructor(
    private router: Router,
    private titleService: Title,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private subscriptionPackageService: SubscriptionPackageService,
    private categoryService: CategoryService,
    private subCategoryService: SubCategoryService,
    private calendar: NgbCalendar,
    private toastr: ToastrManager,
    private cdref: ChangeDetectorRef
  ) {
    this.form();
    this.formParent();
    this.formEud();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  currentEvents: EventApi[] = [];


  formParent() {
    this.FormParent = this.formBuilder.group({
      category: ['-', Validators.required],
      state: ['-', Validators.required],
      city: ['-', Validators.required],
      subscriptionPackage: ['-', Validators.required],
      fromAge: ['', Validators.nullValidator],
      toAge: ['', Validators.nullValidator],
      RRDays: ['', Validators.nullValidator],
      status: ['-', Validators.required],
      startDate: ['-', Validators.nullValidator],
      endDate: ['-', Validators.nullValidator]
    });
  }

  form() {
    this.Form = this.formBuilder.group({
      category: ['-', Validators.required],
      // state: ['-', Validators.required],
      // city: ['-', Validators.required],
      subscriptionPackage: ['-', Validators.required],
      fromAge: ['', Validators.nullValidator],
      toAge: ['', Validators.nullValidator],
      RRDays: ['', Validators.nullValidator],
      status: ['-', Validators.required],
      startDate: ['-', Validators.nullValidator],
      endDate: ['-', Validators.nullValidator]
    });
  }

  formEud() {
    this.FormEud = this.formBuilder.group({
      category: ['-', Validators.required],
      state: ['-', Validators.required],
      city: ['-', Validators.required],
      subCategory: ['-', Validators.required],
      status: ['-', Validators.required],
      startDate: ['-', Validators.nullValidator],
      endDate: ['-', Validators.nullValidator]
    });
  }

  ngOnInit(): void {
    if(localStorage.getItem('department') != null && localStorage.getItem('department') != undefined) {
      if(localStorage.getItem('department') == 'Accounting') {
        this.authorization = true;
      }
    }

    this.timePeriod  = [
      {value: 'year', viewValue: 'This Year'},
      {value: 'month', viewValue: 'This Month'},
      {value: 'today', viewValue: 'Today'}
    ];

    this.selected = "month";
    this.selectedeLearner = "month";
    this.selectedcomplaint = "month";
    this.selectedeRevenue = "month";
    this.selectedeEducator = "month";
    this.selectedePopularCategory = "month";

    this.eLearnerList(this.selectedeLearner);
    this.complaintList(this.selectedcomplaint)
    this.revenueList(this.selectedeRevenue);
    this.educatorList(this.selectedeEducator);
    this.class();
    this.popularCategoryList(this.selectedePopularCategory);
    this.allData();
    this.DDL();
    this.parentReport();
    this.eLearnerReport();
    this.educatorReport();
    this.userCounter();
  }

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleLiveStreamingToggle() {
    this.liveStreamingVisible = !this.liveStreamingVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  // handleDateSelect(selectInfo: DateSelectArg) {
  //   const title = prompt('Please enter a new title for your event');
  //   const calendarApi = selectInfo.view.calendar;
  //   calendarApi.unselect(); // clear date selection

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay
  //     });
  //   }
  // }

  // handleEventClick(clickInfo: EventClickArg) {
  //   if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
  //     clickInfo.event.remove();
  //   }
  // }

  // handleEvents(events: EventApi[]) {
  //   this.currentEvents = events;
  // }

  userCounter() {
    this.dashboardService.userCounter().then(data => {
      this.totalELearner = data.childCount;
      this.totalEducator = data.educatorCount;
      this.totalParent = data.parentCount;
      this.totalClasses = data.classesCount;
    });
  }

  calenderTab() {
    if(this.calendarVisible) {
      this.handleCalendarToggle();
    }
    setTimeout(() => {
      this.handleCalendarToggle();
    }, 250);

    // setTimeout(() => {
      // var initialEvents: any = this.calendarOptions.initialEvents;
      // initialEvents.push({
      //   id: createEventId(),
      //   title: 'Timed event',
      //   start: this.TODAY_STR + 'T02:00:00'
      // });

      this.dashboardService.calender().then(data => this.calendarOptions.initialEvents = data);

      // this.calendarOptions.initialEvents = [{
      //   id: createEventId(),
      //   title: 'All-day event',
      //   start: this.TODAY_STR
      // },
      // {
      //   id: createEventId(),
      //   title: 'Timed event',
      //   start: this.TODAY_STR + 'T12:00:00'
      // }];

      // this.calendarOptions = {
      //   headerToolbar: {
      //     left: 'prev,next today',
      //     center: 'title',
      //     right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      //   },
      //   initialView: 'dayGridMonth',
      //   initialEvents: initialEvents, // alternatively, use the `events` setting to fetch from a feed
      //   weekends: true,
      //   editable: true,
      //   selectable: true,
      //   selectMirror: true,
      //   dayMaxEvents: true,
      //   // select: this.handleDateSelect.bind(this),
      //   // eventClick: this.handleEventClick.bind(this),
      //   // eventsSet: this.handleEvents.bind(this)
      //   /* you can update a remote database when these fire:
      //   eventAdd:
      //   eventChange:
      //   eventRemove:
      //   */
      // };
    // }, 1000);
  }

  liveStreamingTab() {
    if(this.liveStreamingVisible) {
      this.handleLiveStreamingToggle();
    }
    setTimeout(() => {
      this.handleLiveStreamingToggle();
    }, 250);

    this.dashboardService.liveStreaming().then(data => this.liveStreamingOptions.initialEvents = data);
  }

  DDL() {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day,
      month: month,
      year: year
    }

    this.stateData = [];
    this.stateData.push({
      id: "-",
      text: "Select State",
      // disabled: true,
      // selected: true
    });
    this.dashboardService.state().subscribe(data => {
      this.statedata = data;
      this.statedata.forEach(element => {
        this.stateData.push({
          id: element.state,
          text: element.state,
        });
      });
    });
    this.categoryData = [];
    this.categoryData.push({
      id: "-",
      text: "Select Category",
      // disabled: true,
      // selected: true
    });
    this.categoryService.alllist().then(data => {
      data.forEach(element => {
        this.categoryData.push({
          id: element._id,
          text: element.name,
        });
      });
    });
    this.statusData = [{
      id: "-",
      text: "Select Status"
    },
    {
      id: true,
      text: 'Active',
    },
    {
      id: false,
      text: 'Deactive',
    }];
    this.statusDataEdu = [{
      id: "-",
      text: "Select Status"
    },
    {
      id: 'Active',
      text: 'Active',
    },
    {
      id: 'Deactive',
      text: 'Deactive',
    },
    {
      id: 'Pending',
      text: 'Pending',
    },
    {
      id: 'Reject',
      text: 'Reject',
    }];
  }

  eLearnerList(select) {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if(select == 'year') {
        var tstartdate = new Date(year + "/01/01");
        var tenddate = new Date((year + 1) + "/01/01");
        var pstartdate = new Date((year - 1) + "/01/01");
        var penddate = new Date(year + "/01/01");
    }
    else if(select == 'month') {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");

        if(month == 12) {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : month) + "/31");
        } else {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : (month + 1)) + "/01");
        }
        var pstartdate = new Date(year + "/" +  ((month - 1) < 10 ? "0" + (month - 1) : (month - 1)) + "/01");
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
    }
    else {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
        var tenddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day + 1) < 10 ? "0" + (day + 1) : (day + 1) > 31 ? 31 : (day + 1)));
        var pstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day - 1) < 10 ? (day - 1) > 0 ?  "0" + (day - 1) : "0" + day : (day - 1)));
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
    }
    var json = {
      tstartdate: new Date(tstartdate).toISOString(),
      tenddate: new Date(tenddate).toISOString(),
      pstartdate: new Date(pstartdate).toISOString(),
      penddate: new Date(penddate).toISOString()
    }

    this.dashboardService.eLearner(json).then(eLearner => {
      this.eLearner = eLearner;
      this.chartOptions = {
        series: [
          {
            name: "E-Learner",
            data: [(eLearner.totaPaidCount + eLearner.totaFreeCount), eLearner.totaPaidCount, eLearner.totaFreeCount]
          }
        ],
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
            }
          }
        },
        colors: [
          "#5BAACC",
          "#20574F",
          "#A3E7DF"
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: true,
          borderColor: '#f0f1f5',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: false
              }
          },
          yaxis: {
              lines: {
                  show: true
              }
          },
          row: {
              colors: undefined,
              opacity: 0.5
          },
          column: {
              colors: undefined,
              opacity: 0.5
          },
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
          }
        },
        xaxis: {
          categories: [
            ["Total"],
            ["Paid"],
            ["Trial"]
          ],
          labels: {
            style: {
              colors: [
                "#5BAACC",
                "#20574F",
                "#A3E7DF"
              ],
              fontSize: "12px"
            }
          }
        }
      };

    });
  }

  complaintList(select) {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if(select == 'year') {
        var tstartdate = new Date(year + "/01/01");
        var tenddate = new Date((year + 1) + "/01/01");
        var pstartdate = new Date((year - 1) + "/01/01");
        var penddate = new Date(year + "/01/01");
    }
    else if(select == 'month') {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
        if(month == 12) {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : month) + "/31");
        } else {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : (month + 1)) + "/01");
        }
        var pstartdate = new Date(year + "/" +  ((month - 1) < 10 ? "0" + (month - 1) : (month - 1)) + "/01");
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
    }
    else {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
        var tenddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day + 1) < 10 ? "0" + (day + 1) : (day + 1) > 31 ? 31 : (day + 1)));
        var pstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day - 1) < 10 ? (day - 1) > 0 ?  "0" + (day - 1) : "0" + day : (day - 1)));
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
    }

    var json = {
      tstartdate: new Date(tstartdate).toISOString(),
      tenddate: new Date(tenddate).toISOString(),
      pstartdate: new Date(pstartdate).toISOString(),
      penddate: new Date(penddate).toISOString()
    }

    this.compPending = 0;
    this.compInProgress = 0;
    this.compRejected = 0;
    this.compApproved = 0;
    this.compClose = 0;
    this.compPrvPending = 0;
    this.compPrvInProgress = 0;
    this.compPrvRejected = 0;
    this.compPrvApproved = 0;
    this.compPrvClose = 0;
    this.compTotal = 0;

    this.dashboardService.complaint(json).then(complaint => {
      this.complaint = complaint.data;
      if(this.complaint.complaint.length > 0) {
        for (let index = 0; index < this.complaint.complaint.length; index++) {
          const element = this.complaint.complaint[index];
          this.compTotal += element.count;

          if(element._id == "Pending") {
            this.compPending += element.count;
          }
          else if(element._id == "In Progress") {
            this.compInProgress += element.count;
          }
          else if(element._id == "Rejected") {
            this.compRejected += element.count;
          }
          else if(element._id == "Approved") {
            this.compApproved += element.count;
          }
          else if(element._id == "Close") {
            this.compClose += element.count;
          }
        }
      }

      if(this.complaint.complaintPrevious.length > 0) {
        for (let index = 0; index < this.complaint.complaintPrevious.length; index++) {
          const element = this.complaint.complaintPrevious[index];

          if(element._id == "Pending") {
            this.compPrvPending += element.count;
          }
          else if(element._id == "In Progress") {
            this.compPrvInProgress += element.count;
          }
          else if(element._id == "Rejected") {
            this.compPrvRejected += element.count;
          }
          else if(element._id == "Approved") {
            this.compPrvApproved += element.count;
          }
          else if(element._id == "Close") {
            this.compPrvClose += element.count;
          }
        }
      }

      this.chartOptions2 = {
        series: [
          {
            name: "Complaint",
            data: [this.compPending, this.compInProgress, this.compApproved, this.compClose, this.compRejected]
          }
        ],
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
            }
          }
        },
        colors: [
          "#5BAACC",
          "#20574F",
          "#4C93B1",
          "#A3E7DF",
          "#112f2b"
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: true,
          borderColor: '#f0f1f5',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: false
              }
          },
          yaxis: {
              lines: {
                  show: true
              }
          },
          row: {
              colors: undefined,
              opacity: 0.5
          },
          column: {
              colors: undefined,
              opacity: 0.5
          },
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
          }
        },
        xaxis: {
          categories: [
            ["Pending"],
            ["In Progress"],
            ["Approved"],
            ["Close"],
            ["Rejected"]
          ],
          labels: {
            style: {
              colors: [
                "#5BAACC",
                "#20574F",
                "#4C93B1",
                "#A3E7DF",
                "#112f2b"
              ],
              fontSize: "12px"
            }
          }
        }
      };
    });
  }

  revenueList(select) {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if(select == 'year') {
        var tstartdate = new Date(year + "/01/01");
        var tenddate = new Date((year + 1) + "/01/01");
        // var pstartdate = new Date((year - 1) + "/01/01");
        // var penddate = new Date(year + "/01/01");
    }
    else if(select == 'month') {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
        if(month == 12) {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : month) + "/31");
        } else {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : (month + 1)) + "/01");
        }
        // var pstartdate = new Date(year + "/" +  ((month - 1) < 10 ? "0" + (month - 1) : (month - 1)) + "/01");
        // var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
    }
    else {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
        var tenddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day + 1) < 10 ? "0" + (day + 1) : (day + 1) > 31 ? 31 : (day + 1)));
        // var pstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day - 1) < 10 ? (day - 1) > 0 ?  "0" + (day - 1) : "0" + day : (day - 1)));
        // var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
    }

    var json = {
      tstartdate: new Date(tstartdate).toISOString(),
      tenddate: new Date(tenddate).toISOString(),
      // pstartdate: new Date(pstartdate).toISOString(),
      // penddate: new Date(penddate).toISOString()
    }

    this.colors = [
      // "#5BAACC",
      // "#20574F",
      // "#4C93B1",
      // "#A3E7DF"
      "#112f2b",
      "#20574f",
      "#008081",
      "#156e94",
      "#50a3c7",
      "#b8e0f1",
      "#41c4b5",
      "#a8f2e9",
      "#b6d0ce"
    ]

    this.totalGrossRevenue2 = 0;

    this.dashboardService.grosstransaction(json).then(revenue => {
      this.revenue = revenue;
      const labels = [];
      const series = [];
      let index = 0;
      this.revenue.purchaseDetail.map(x => {
        x.color = this.colors[index];
        index++;
      });
      const childSubscription = this.revenue.purchaseDetail.map(x => x._id);
      if(childSubscription.length > 0) {
        childSubscription.map(x => labels.push('E-Learner -' + x));
      }
      const childSubscriptionValue = this.revenue.purchaseDetail.map(x => x.taxableAmount);
      if(childSubscriptionValue.length > 0) {
        childSubscriptionValue.map(x => series.push(x));
        if(this.revenue.purchaseDetail.length > 1) {
          this.totalGrossRevenue2 += this.revenue.purchaseDetail.reduce((c, p, n) => c.taxableAmount + p.taxableAmount);
        }
        else {
          this.totalGrossRevenue2 += this.revenue.purchaseDetail[0].taxableAmount;
        }
      }
      this.revenue.educatorPurchaseDetail.map(x => {
        x.color = this.colors[index];
        index++;
      });
      const educatorSubscription = this.revenue.educatorPurchaseDetail.map(x => x._id);
      if(educatorSubscription.length > 0) {
        educatorSubscription.map(x => labels.push('Educator -' + x));
      }
      const educatorSubscriptionValue = this.revenue.educatorPurchaseDetail.map(x => x.taxableAmount);
      if(educatorSubscriptionValue.length > 0) {
        educatorSubscriptionValue.map(x => series.push(x));
        if(this.revenue.educatorPurchaseDetail.length > 1) {
          this.totalGrossRevenue2 += this.revenue.educatorPurchaseDetail.reduce((c, p, n) => c.taxableAmount + p.taxableAmount);
        }
        else {
          this.totalGrossRevenue2 += this.revenue.educatorPurchaseDetail[0].taxableAmount;
        }
      }
      this.revenue.purchaseMekkoin.color = this.colors[index];
      index++;
      this.revenue.purchaseTopUp.color = this.colors[index];
      index++;
      this.revenue.adminConvenienceFeesDetail.color = this.colors[index];

      labels.push('Mekkoin');
      series.push(this.revenue.purchaseMekkoin.taxableAmount);
      labels.push('Top Up');
      series.push(this.revenue.purchaseTopUp.taxableAmount);
      labels.push('Convenience Fees');
      series.push(this.revenue.adminConvenienceFeesDetail.taxableAmount);

      this.totalGrossRevenue2 += this.revenue.purchaseMekkoin.taxableAmount;
      this.totalGrossRevenue2 += this.revenue.purchaseTopUp.taxableAmount;
      this.totalGrossRevenue2 += this.revenue.adminConvenienceFeesDetail.taxableAmount;

      this.chartOptionsPie = {
        // series: [15, 10, 25, 50],
        series: series,
        chart: {
          type: "donut"
        },
        // labels: ["Team A", "Team B", "Team C", "Team D"],
        labels: labels,
        colors: this.colors,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                //  name: {
                //   show: true,
                //   fontSize: '22px',
                //   fontFamily: 'Rubik',
                //   color: '#dfsda',
                //   offsetY: -10
                // },
                // value: {
                //   show: true,
                //   fontSize: '16px',
                //   fontFamily: 'Helvetica, Arial, sans-serif',
                //   color: undefined,
                //   offsetY: 16,
                //   formatter: function (val) {
                //     return val
                //   }
                // },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#373d3f',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                width: 300
              },
              // legend: {
              //   position: "bottom",
              //   show: true,
              // },
              dataLabels: {
                  enabled: false,
              }
            }
          }
        ],
        legend: {
          show: false
        },
      };
    });
  }

  educatorList(select) {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if(select == 'year') {
        var tstartdate = new Date(year + "/01/01");
        var tenddate = new Date((year + 1) + "/01/01");
        var pstartdate = new Date((year - 1) + "/01/01");
        var penddate = new Date(year + "/01/01");
    }
    else if(select == 'month') {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
        if(month == 12) {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : month) + "/31");
        } else {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : (month + 1)) + "/01");
        }
        var pstartdate = new Date(year + "/" +  ((month - 1) < 10 ? "0" + (month - 1) : (month - 1)) + "/01");
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
    }
    else {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
        var tenddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day + 1) < 10 ? "0" + (day + 1) : (day + 1) > 31 ? 31 : (day + 1)));
        var pstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day - 1) < 10 ? (day - 1) > 0 ?  "0" + (day - 1) : "0" + day : (day - 1)));
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
    }

    var json = {
      tstartdate: new Date(tstartdate).toISOString(),
      tenddate: new Date(tenddate).toISOString(),
      pstartdate: new Date(pstartdate).toISOString(),
      penddate: new Date(penddate).toISOString()
    }

    this.dashboardService.educator(json).then(educator => {
      this.educator = educator;
      var tPending = this.educator.totalToday.find(x => x._id == 'Pending')
      var tActive = this.educator.totalToday.find(x => x._id == 'Active');
      var tDeactive = this.educator.totalToday.find(x => x._id == 'Deactive');
      var tReject = this.educator.totalToday.find(x => x._id == 'Reject');
      var pPending = this.educator.totalPrevious.find(x => x._id == 'Pending');
      var pActive = this.educator.totalPrevious.find(x => x._id == 'Active');
      var pDeactive = this.educator.totalPrevious.find(x => x._id == 'Deactive');
      var pReject = this.educator.totalPrevious.find(x => x._id == 'Reject');
      this.tPending = tPending != undefined ? tPending.count : 0;
      this.tActive = tActive != undefined ? tActive.count : 0;
      this.tDeactive = tDeactive != undefined ? tDeactive.count : 0;
      this.tReject = tReject != undefined ? tReject.count : 0;
      this.pPending = pPending != undefined ? pPending.count : 0;
      this.pActive = pActive != undefined ? pActive.count : 0;
      this.pDeactive = pDeactive != undefined ? pDeactive.count : 0;
      this.pReject = pReject != undefined ? pReject.count : 0;
      this.total = this.tPending + this.tActive + this.tDeactive + this.tReject;
      this.chartOptionsPie2 = {
        series: [this.tPending, this.tActive, this.tReject, this.tDeactive],
        chart: {
          type: "pie"
        },
        labels: ["Pending", "Approved", "Rejected", "Deactive"],
        colors: [
          "#5BAACC",
          "#20574F",
          "#4C93B1",
          "#A3E7DF"
        ],
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                width: 300
              },
              // legend: {
              //   position: "bottom",
              //   show: true,
              // },
              dataLabels: {
                  enabled: false,
              },
            }
          }
        ],
        legend: {
          show: false
        }
      };
    });
  }

  class() {
    this.dashboardService.classes().then(classes => {
      this.classes = classes;
      // var cActive = this.classes.find(x => x._id == 'Active')
      // var cDeactive = this.classes.find(x => x._id == 'Deactive');
      // var cDraft = this.classes.find(x => x._id == 'Draft');
      // var cInReview = this.classes.find(x => x._id == 'InReview');
      var cActive = this.classes[1];
      var cDeactive = this.classes[0];
      var cDraft = this.classes[2];
      var cInReview = this.classes[3];
      this.cActive = cActive != undefined ? cActive.count : 0;
      this.cDeactive = cDeactive != undefined ? cDeactive.count : 0;
      this.cDraft = cDraft != undefined ? cDraft.count : 0;
      this.cInReview = cInReview != undefined ? cInReview.count : 0;
      this.cTotal = this.cActive + this.cDeactive + this.cDraft + this.cInReview;
      this.chartOptionsPie3 = {
        series: [this.cActive, this.cDeactive, this.cDraft, this.cInReview],
        chart: {
          type: "pie"
        },
        labels: ["Active", "Deactive", "Draft", "InReview"],
        colors: [
          "#5BAACC",
          "#20574F",
          "#4C93B1",
          "#A3E7DF"
        ],
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                width: 300
              },
              // legend: {
              //   position: "bottom",
              //   show: true,
              // },
              dataLabels: {
                  enabled: false,
              },
            }
          }
        ],
        legend: {
          show: false
        }
      };
    });
  }

  popularCategoryList(select) {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if(select == 'year') {
        var tstartdate = new Date(year + "/01/01");
        var tenddate = new Date((year + 1) + "/01/01");
        var pstartdate = new Date((year - 1) + "/01/01");
        var penddate = new Date(year + "/01/01");
    }
    else if(select == 'month') {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
        if(month == 12) {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : month) + "/31");
        } else {
          var tenddate = new Date(year + "/" +  ((month + 1) < 10 ? "0" + (month + 1) : (month + 1)) + "/01");
        }
        var pstartdate = new Date(year + "/" +  ((month - 1) < 10 ? "0" + (month - 1) : (month - 1)) + "/01");
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/01");
    }
    else {
        var tstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
        var tenddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day + 1) < 10 ? "0" + (day + 1) : (day + 1) > 31 ? 31 : (day + 1)));
        var pstartdate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  ((day - 1) < 10 ? (day - 1) > 0 ?  "0" + (day - 1) : "0" + day : (day - 1)));
        var penddate = new Date(year + "/" +  (month < 10 ? "0" + month : month) + "/" +  (day < 10 ? "0" + day : day));
    }

    var json = {
      tstartdate: new Date(tstartdate).toISOString(),
      tenddate: new Date(tenddate).toISOString(),
      pstartdate: new Date(pstartdate).toISOString(),
      penddate: new Date(penddate).toISOString()
    }

    this.dashboardService.popularCategory(json).then(popularCategory => {
      this.popularCategory = popularCategory;
      this.chartOptions3 = {
        series: [
          {
            name: "Category",
            data: popularCategory.tdata
          }
        ],
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
            }
          }
        },
        colors: popularCategory.colors,
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: true,
          borderColor: '#f0f1f5',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: false
              }
          },
          yaxis: {
              lines: {
                  show: true
              }
          },
          row: {
              colors: undefined,
              opacity: 0.5
          },
          column: {
              colors: undefined,
              opacity: 0.5
          },
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
          }
        },
        xaxis: {
          categories: popularCategory.categories,
          labels: {
            style: {
              colors: popularCategory.colors,
              fontSize: "12px"
            }
          }
        }
      };
    });
  }

  parentTab() {
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day,
      month: month,
      year: year
    }
    this.state = "-",
    this.city = "-",
    this.subscriptionPackage = "-";
    this.status = "-";
    this.category = "-";
    this.fromAge = "";
    this.toAge = "";
    this.RRDays = "";
    this.FormParent.value.state = "-";
    this.FormParent.value.city = "-";
    this.FormParent.value.subscriptionPackage = "-";
    this.FormParent.value.status = "-";
    this.FormParent.value.category = "-";
    this.FormParent.value.fromAge = "";
    this.FormParent.value.toAge = "";
    this.FormParent.value.RRDays = "";
    this.FormParent.value.startDate = "-";
    this.FormParent.value.endDate = "-";
    this.inputName.nativeElement.value = '';
    this.dashboardService.parentReport(this.FormParent.value).then(data => this.parentReportList = data);
  }

  eLearnerTab() {
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day,
      month: month,
      year: year
    }
    // this.state = "-",
    // this.city = "-",
    this.subscriptionPackage = "-";
    this.status = "-";
    this.category = "-";
    this.fromAge = "";
    this.toAge = "";
    this.RRDays = "";
    // this.Form.value.state = "-";
    // this.Form.value.city = "-";
    this.Form.value.subscriptionPackage = "-";
    this.Form.value.status = "-";
    this.Form.value.category = "-";
    this.Form.value.fromAge = "";
    this.Form.value.toAge = "";
    this.Form.value.RRDays = "";
    this.Form.value.startDate = "-";
    this.Form.value.endDate = "-";
    this.inputName.nativeElement.value = '';
    this.dashboardService.eLearnerReport(this.Form.value).then(data => this.eLearnerReportList = data);
  }

  educatorTab() {
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day,
      month: month,
      year: year
    }
    this.state = "-",
    this.city = "-",
    this.subCategory = "-";
    this.status = "-";
    this.category = "-";
    this.FormEud.value.state = "-";
    this.FormEud.value.city = "-";
    this.FormEud.value.subCategory = "-";
    this.FormEud.value.status = "-";
    this.FormEud.value.category = "-";
    this.FormEud.value.startDate = "-";
    this.FormEud.value.endDate = "-";
    this.nputNameEud.nativeElement.value = '';
    this.dashboardService.educatorReport(this.FormEud.value).then(data => this.educatorReportList = data);
  }

  parentReport() {
    this.FormParent.value.fromAge = "";
    this.FormParent.value.toAge = "";
    this.FormParent.value.RRDays = "";

    this.dashboardService.parentReport(this.FormParent.value).then(data => this.parentReportList = data);

    this.subscriptionPackageData = [];
    this.subscriptionPackageData.push({
      id: "-",
      text: "Select Package",
      // disabled: true,
      // selected: true
    });
    // this.subscriptionPackageData.push({
    //   id: "Free",
    //   text: "Free"
    // });
    this.subCategoryData1 = [];
    this.subCategoryService.alllist().then(data => {
      this.subCategoryData1 = data;
    });
    this.subscriptionPackageService.alllist().then(data => {
        data.forEach(element => {
          this.subscriptionPackageData.push({
            id: element.packageName,
            text: element.packageName,
          });
        });
    });
  }

  eLearnerReport() {
    this.Form.value.fromAge = "";
    this.Form.value.toAge = "";
    this.Form.value.RRDays = "";

    this.dashboardService.eLearnerReport(this.Form.value).then(data => this.eLearnerReportList = data);

    this.subscriptionPackageData = [];
    this.subscriptionPackageData.push({
      id: "-",
      text: "Select Package",
      // disabled: true,
      // selected: true
    });
    // this.subscriptionPackageData.push({
    //   id: "Free",
    //   text: "Free"
    // });
    this.subCategoryData1 = [];
    this.subCategoryService.alllist().then(data => {
      this.subCategoryData1 = data;
    });
    this.subscriptionPackageService.alllist().then(data => {
        data.forEach(element => {
          this.subscriptionPackageData.push({
            id: element.packageName,
            text: element.packageName,
          });
        });
    });
  }

  exportexcelParent() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Parent_List.xlsx');
  }

  exportexcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'ELearner_List.xlsx');
  }

  eduexportexcel(){
    /* table id is passed over here */
    let element = document.getElementById('excel-table-edu');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Educator_List.xlsx');
  }

  educatorReport() {
    this.subCategoryData1 = [];
    this.subCategoryService.alllist().then(data => {
      this.subCategoryData1 = data;
    });
    this.dashboardService.educatorReport(this.FormEud.value).then(data => this.educatorReportList = data);
  }

  onCategory(value) {
    this.subCategoryData = [];
    this.subCategoryData.push({
      id: "-",
      text: "Select Sub Category",
      // disabled: true,
      // selected: true
    });
    var data = this.subCategoryData1.filter(x => x.categoryId == value);
    if(data.length > 0) {
      data.forEach(element => {
        this.subCategoryData.push({
          id: element._id,
          text: element.name,
        });
      });
    }
  }

  onState(value) {
    this.cityData = [];
    this.cityData.push({
      id: "-",
      text: "Select City",
      // disabled: true,
      // selected: true
    });
    var index = this.statedata.findIndex(x => x.state == value);
    if(index != -1) {
      this.statedata[index].districts.forEach(element => {
        this.cityData.push({
          id: element,
          text: element,
        });
      });
    }
  }

  fileChangeEvent(event) {
    this.filesupload = [];
    this.btn = false;
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  uploadexcel() {
    if(this.filesupload.length > 0) {
      this.btn = true;
      this.dashboardService.excelFileUpload(this.filesupload).then(data => {
        if(data.flag == true) {;
          this.toastr.successToastr(data.message, 'Parent are SingUp!', { animate: 'slideFromRight', showCloseButton: true });
        }
        else {
          this.btn = false;
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        }
        this.filesupload = [];
      });
    }
    else {
      this.toastr.errorToastr('Please Upload Excel File!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  filter() {
    if((this.Form.value.fromAge != "" && this.Form.value.toAge != "") || (this.Form.value.fromAge == "" && this.Form.value.toAge == "")) {
      if(this.Form.value.startDate == null) {
        this.Form.value.startDate = '-';
      }
      if(this.Form.value.endDate == null) {
        this.Form.value.endDate = '-';
      }
      if(this.Form.value.startDate != '-') {
        this.Form.value.startDate = this.Form.value.startDate.year + '-' + this.Form.value.startDate.month + '-' + this.Form.value.startDate.day;
        this.Form.value.startDate = new Date(this.Form.value.startDate).toISOString();
      }
      if(this.Form.value.endDate != '-') {
        this.Form.value.endDate = this.Form.value.endDate.year + '-' + this.Form.value.endDate.month + '-' + this.Form.value.endDate.day;
        this.Form.value.endDate = new Date(this.Form.value.endDate).toISOString();
      }

      this.dashboardService.eLearnerReport(this.Form.value).then(data => this.eLearnerReportList = data);
    }
    else {
      this.toastr.errorToastr('Pleace vaild input for age group!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  filterParent() {
    if((this.FormParent.value.fromAge != "" && this.FormParent.value.toAge != "") || (this.FormParent.value.fromAge == "" && this.FormParent.value.toAge == "")) {
      if(this.FormParent.value.startDate == null) {
        this.FormParent.value.startDate = '-';
      }
      if(this.FormParent.value.endDate == null) {
        this.FormParent.value.endDate = '-';
      }
      if(this.FormParent.value.startDate != '-') {
        this.FormParent.value.startDate = this.FormParent.value.startDate.year + '-' + this.FormParent.value.startDate.month + '-' + this.FormParent.value.startDate.day;
        this.FormParent.value.startDate = new Date(this.FormParent.value.startDate).toISOString();
      }
      if(this.FormParent.value.endDate != '-') {
        this.FormParent.value.endDate = this.FormParent.value.endDate.year + '-' + this.FormParent.value.endDate.month + '-' + this.FormParent.value.endDate.day;
        this.FormParent.value.endDate = new Date(this.FormParent.value.endDate).toISOString();
      }
      this.dashboardService.parentReport(this.FormParent.value).then(data => this.parentReportList = data);
    }
    else {
      this.toastr.errorToastr('Pleace vaild input for age group!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  filterEud() {
    if(this.FormEud.value.startDate == null) {
      this.FormEud.value.startDate = '-';
    }
    if(this.FormEud.value.endDate == null) {
      this.FormEud.value.endDate = '-';
    }
    if(this.FormEud.value.startDate != '-') {
      this.FormEud.value.startDate = this.FormEud.value.startDate.year + '-' + this.FormEud.value.startDate.month + '-' + this.FormEud.value.startDate.day;
      this.FormEud.value.startDate = new Date(this.FormEud.value.startDate).toISOString();
    }
    if(this.FormEud.value.endDate != '-') {
      // this.FormEud.value.endDate = this.FormEud.value.endDate.year + '-' + this.FormEud.value.endDate.month + '-' + (this.FormEud.value.endDate.day + 1);
      this.FormEud.value.endDate = this.FormEud.value.endDate.year + '-' + this.FormEud.value.endDate.month + '-' + this.FormEud.value.endDate.day;
      this.FormEud.value.endDate = new Date(this.FormEud.value.endDate).toISOString();
    }
    this.dashboardService.educatorReport(this.FormEud.value).then(data => this.educatorReportList = data);
  }

  clearParent() {
    this.parentTab();
  }

  clear() {
    this.eLearnerTab();
  }

  clearEud() {
    this.educatorTab();
  }

  // reloadComponent() {
  //   let currentUrl = this.router.url;
  //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //   this.router.onSameUrlNavigation = 'reload';
  //   this.router.navigate([currentUrl]);
  // }

  onELearnerChange(event) {
    this.eLearnerList(event);
  }

  onComplaintChange(event) {
    this.complaintList(event);
  }

  onRevenueChange(event) {
    this.revenueList(event);
  }

  onEducatorChange(event) {
    this.educatorList(event);
  }

  onPopularCategoryChange(event) {
    this.popularCategoryList(event);
  }

  allData() {
    // this.chartOptions2 = {
    //   series: [
    //     {
    //       name: "Revenue",
    //       data: [20, 15, 5]
    //     }
    //   ],
    //   chart: {
    //     height: 350,
    //     type: "bar",
    //     events: {
    //       click: function(chart, w, e) {
    //       }
    //     }
    //   },
    //   colors: [
    //     "#20574F",
    //     "#5BAACC",
    //     "#A3E7DF"
    //   ],
    //   plotOptions: {
    //     bar: {
    //       columnWidth: "45%",
    //       distributed: true
    //     }
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   legend: {
    //     show: false
    //   },
    //   grid: {
    //     show: true,
    //     borderColor: '#f0f1f5',
    //     strokeDashArray: 0,
    //     position: 'back',
    //     xaxis: {
    //         lines: {
    //             show: false
    //         }
    //     },
    //     yaxis: {
    //         lines: {
    //             show: true
    //         }
    //     },
    //     row: {
    //         colors: undefined,
    //         opacity: 0.5
    //     },
    //     column: {
    //         colors: undefined,
    //         opacity: 0.5
    //     },
    //     padding: {
    //         top: 0,
    //         right: 0,
    //         bottom: 0,
    //         left: 0
    //     }
    //   },
    //   xaxis: {
    //     categories: [
    //       ["Total"],
    //       ["Pending"],
    //       ["Solved"]
    //     ],
    //     labels: {
    //       style: {
    //         colors: [
    //           "#20574F",
    //           "#5BAACC",
    //           "#A3E7DF"
    //         ],
    //         fontSize: "12px"
    //       }
    //     }
    //   }
    // };

    this.chartOptionsBar = {
      series: [
        {
          data: [250, 20, 20]
        }
      ],
      chart: {
        type: "bar",
        height: 275
      },
      plotOptions: {
        bar: {
          // barHeight: "100%",
          distributed: true,
          horizontal: true,
          borderRadius: 4,
          // dataLabels: {
          //   position: "bottom"
          // }
        }
      },
      colors: [
        "#20574F",
        "#5BAACC",
        "#A3E7DF"
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"]
        },
        // formatter: function(val, opt) {
        //   return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        // },
        offsetX: 0,
        // dropShadow: {
        //   enabled: true
        // }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: [
          "Total",
          "Canceled",
          "Reschedule"
        ]
      },
      // yaxis: {
      //   labels: {
      //     show: false
      //   }
      // },
      // title: {
      //   text: "Live Streaming",
      //   align: "center",
      //   floating: true
      // },
      subtitle: {
        text: "Number of Hours",
        align: "center"
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function() {
              return "";
            }
          }
        }
      }
    };

    this.dashboardService.grossTransactionAndrevenue().then(data => {
      this.totalClassesBooking = data.classesBooking.taxableAmount;
      this.totalPurchaseMekkoin = data.purchaseMekkoin.taxableAmount;
      this.totalPurchaseTopUp = data.purchaseTopUp.taxableAmount;
      this.totalPurchaseDetail = data.purchaseDetail;
      this.totalEducatorPurchaseDetail = data.educatorPurchaseDetail;
      this.totalTax = data.classesBooking.taxAmount + data.purchaseMekkoin.taxAmount + data.purchaseTopUp.taxAmount;
      this.totalIncomeStatement = data.classesBooking.taxableAmount + data.purchaseMekkoin.taxableAmount + data.purchaseTopUp.taxableAmount;
      this.totalGrossRevenue = data.purchaseMekkoin.taxableAmount + data.purchaseTopUp.taxableAmount;
      data.purchaseDetail.forEach(element => {
        this.totalTax += element.taxAmount;
        this.totalIncomeStatement += element.taxableAmount;
        this.totalGrossRevenue += element.taxableAmount;
      });
      data.educatorPurchaseDetail.forEach(element => {
        this.totalTax += element.taxAmount;
        this.totalIncomeStatement += element.taxableAmount;
        this.totalGrossRevenue += element.taxableAmount;
      });

      this.totalAdminConvenienceFeesDetail = data.adminConvenienceFeesDetail.taxableAmount;
      this.totalGrossRevenue += data.adminConvenienceFeesDetail.taxableAmount;
    });
  }

  parentReportShow(type, data) {
    this.modalService.open(type, { size: 'xl' });
    this.childList = data;
  }

  classesInfo() {
    this.router.navigate(['/admin/classes']);
    this.titleService.setTitle('Classes' + ' - Meeko');
  }

  // events = [
  //       {
  //         image: "assets/images/events/1.png",
  //         title: "International Live Choice Festivals (2020)",
  //         url: "event-detail",
  //         location: "Manchester, London",
  //         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini",
  //         price: "124,00",
  //         date: "June 20, 2020",
  //         no_of_tickets: "561 pcs",
  //         time: "08:35 AM",
  //       },
  //       {
  //         image: "assets/images/events/3.png",
  //         title: "Envato Atuhor Community Fun Hiking at Sibayak Mt.",
  //         url: "event-detail",
  //         location: "London, United Kingdom",
  //         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini",
  //         price: "124,00",
  //         date: "June 20, 2020",
  //         no_of_tickets: "561 pcs",
  //         time: "08:35 AM",
  //       },
  //       {
  //         image: "assets/images/events/1.png",
  //         title: "International Live Choice Festivals (2020)",
  //         url: "event-detail",
  //         location: "Manchester, London",
  //         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini",
  //         price: "124,00",
  //         date: "June 20, 2020",
  //         no_of_tickets: "561 pcs",
  //         time: "08:35 AM",
  //       },
  //       {
  //         image: "assets/images/events/2.png",
  //         title: "Envato Indonesian Authors Meetup 2020",
  //         url: "event-detail",
  //         location: "Medan, Indonesia",
  //         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini",
  //         price: "124,00",
  //         date: "June 20, 2020",
  //         no_of_tickets: "561 pcs",
  //         time: "08:35 AM",
  //       },
  //       {
  //         image: "assets/images/events/3.png",
  //         title: "Envato Atuhor Community Fun Hiking at Sibayak Mt.",
  //         url: "event-detail",
  //         location: "London, United Kingdom",
  //         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini",
  //         price: "124,00",
  //         date: "June 20, 2020",
  //         no_of_tickets: "561 pcs",
  //         time: "08:35 AM",
  //       },




  // ];

}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class BatchDetailViewService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }
  
  detail(id) {
    return this.httpmanagerService.HttpGet('classesinfo/batchdetail', id).then(data => {
      return data;
    });
  }
}
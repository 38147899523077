import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AdminUserService } from './admin-user.service';
import { DepartmentService } from '../department/department.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css']
})
export class AdminUserComponent implements OnInit {

  Form: FormGroup;
  UpdateForm: FormGroup;
  
  firstName;
  lastName;
  departmentId;
  email;
  address;
  userName;
  password;
  id;
  index;
  adminList = [];
  departmentList = [];
  dropDownData = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private adminUserService: AdminUserService,
    private departmentService: DepartmentService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();
    this.updateForm();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      firstName: ['-', Validators.required],
      lastName: ['-', Validators.required],
      departmentId: ['-', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: ['-', Validators.required],
      userName: ['-', Validators.required],
      password: ['-', Validators.required]
    });
  }

  updateForm() {
    this.UpdateForm = this.formBuilder.group({
      firstName: ['-', Validators.required],
      lastName: ['-', Validators.required],
      departmentId: ['-', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: ['-', Validators.required],
      userName: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination()
    this.dropDownData.push({
      id: "-",
      text: "-- Select Department --",
      disabled: true,
      selected: true
    })
    this.departmentService.list().then(data => { 
      this.departmentList = data;
      for (let index = 0; index < this.departmentList.length; index++) {
        const element = this.departmentList[index];
        this.dropDownData.push({
          id: element._id,
          text: element.name
        });
      }
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.adminUserService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.adminUserService.pagination(data).then(data => this.adminList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.departmentId = null;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id != '-') {
      this.dropDownData.unshift({
        id: "-",
        text: "-- Select Department --",
        disabled: true,
        selected: true
      });
    }
  }

  add() {
    this.addbtn = true;
    this.adminUserService.add(this.Form.value).then(data => {
      if(data.flag == true) {
        this.adminList.unshift(data.data);
        this.total = (parseInt(this.total) + 1).toString();
        this.toastr.successToastr(data.message, 'Admin Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false; 
        this.modalService.dismissAll();
      }
    });
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.address = data.address;
    this.userName = data.userName;
    this.password = data.password;
    this.departmentId = data.departmentId;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id == '-') {
      this.dropDownData.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    this.adminUserService.update(this.UpdateForm.value, this.id).then(data => {
      if(data.flag == true) {
        this.adminList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Admin Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.adminUserService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.adminList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Admin Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.adminUserService.active(json, id).then(data => {
      if(data.flag == true) {
        this.adminList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/retry'; // don't forget the imports

import { ApiConstant } from './api.constant';

import * as CryptoJS from 'crypto-js';
import { Title } from '@angular/platform-browser';


@Injectable()
export class HttpManagerService {
    user: any;
    private crypto_password : String;
    headers;
    private baseurl : String;
    private baseurl2 : String;
    constructor(
        private http: HttpClient,
        private apiservice: ApiConstant,
        private router: Router,
        private titleService: Title
    ) {
        this.baseurl = apiservice.ServerWithApiUrl;
        this.baseurl2 = apiservice.ServerWithApiUrl;
        this.crypto_password = apiservice.crypto_password;
    }

    requestAuthentication() {
        const token = localStorage.getItem('tokenData');
        this.headers = new HttpHeaders()
        this.headers = this.headers.set('Content-Type', 'application/json');
        if(token != null) {
            this.headers = this.headers.set('X-Authorization', token);
        }
        else {
            return
        }
    }

    HttpPost(api,data,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
        var finalAPI = this.baseurl + api +_params;
        var ciphertext = CryptoJS.AES.encrypt(data.toString(), this.crypto_password);
        var JsonRequest = {
            data : ciphertext.toString()
        }
        return this.http.post<any>(finalAPI, JsonRequest, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpPost2(api,data,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
        var finalAPI = this.baseurl2 + api +_params;
        var ciphertext = CryptoJS.AES.encrypt(data.toString(), this.crypto_password);
        var JsonRequest = {
            data : ciphertext.toString()
        }
        return this.http.post<any>(finalAPI, JsonRequest, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpPut(api,data,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
        var finalAPI = this.baseurl + api +_params;
        var ciphertext = CryptoJS.AES.encrypt(data.toString(), this.crypto_password);
        var JsonRequest = {
            data : ciphertext.toString()
        }
        return this.http.put<any>(finalAPI, JsonRequest, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpPut2(api,data,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
        var finalAPI = this.baseurl2 + api +_params;
        var ciphertext = CryptoJS.AES.encrypt(data.toString(), this.crypto_password);
        var JsonRequest = {
            data : ciphertext.toString()
        }
        return this.http.put<any>(finalAPI, JsonRequest, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpGet(api,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
       
        var finalAPI = this.baseurl + api +_params;
        return this.http.get<any>(finalAPI, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                JSON.parse(plaintext).data = [];
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpGet2(api,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
        var finalAPI = this.baseurl2 + api +_params;
        return this.http.get<any>(finalAPI, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                JSON.parse(plaintext).data = [];
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpDelete(api,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
        var finalAPI = this.baseurl + api +_params;
        return this.http.delete<any>(finalAPI, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpDelete2(api,param) : Promise<any> {
        this.requestAuthentication();
        var _params = "";
        if(param != "") {
            _params = "/"+btoa(param);
        }
        var finalAPI = this.baseurl2 + api +_params;
        return this.http.delete<any>(finalAPI, { 'headers': this.headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpPostImage(api, image, objs, param): Promise<any> {
        const token = localStorage.getItem('tokenData');
        const category = localStorage.getItem('category');
        const badgename = localStorage.getItem('badgename');
        var headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'multipart/form-data');
        if(token != null) {
            headers = headers.set('X-Authorization', token);
            if(category != null) {
                headers = headers.set('category', category);
            }
            if(badgename != null) {
                headers = headers.set('badgename', badgename);
            }
        }
        else {
            return
        }
       
        var _params = "";
        if (param != "") {
            _params = "/" + btoa(param);
        }
        var finalAPI = this.baseurl + api + _params;
   
        var ciphertext = CryptoJS.AES.encrypt(objs.toString(), this.crypto_password);
        const fd = new FormData();
        if(image.length > 0) {
            for (let index = 0; index < image.length; index++) {
                fd.append('image', image[index].file, image[index].filename);
            }
        }
        fd.append('data', ciphertext.toString())

        // this.httpClient.get(url).subscribe(response => {
        //     //do something with response
        //   }, err => {
        //     console.log(err.message);
        //   }, () => {
        //     console.log('completed');
        //   }
    
        return this.http.post<any>(finalAPI, fd, { 'headers': headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }, err => {
        }).catch(this.handleError);
    }

    HttpPostMultiFile(api, file, file2, objs, param): Promise<any> {
        const token = localStorage.getItem('tokenData');
        const category = localStorage.getItem('category');
        const badgename = localStorage.getItem('badgename');
        var headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'multipart/form-data');
        if(token != null) {
            headers = headers.set('X-Authorization', token);
            if(category != null) {
                headers = headers.set('category', category);
            }
            if(badgename != null) {
                headers = headers.set('badgename', badgename);
            }
        }
        else {
            return
        }

        var _params = "";
        if (param != "") {
            _params = "/" + btoa(param);
        }
        var finalAPI = this.baseurl + api + _params;
   
        var ciphertext = CryptoJS.AES.encrypt(objs.toString(), this.crypto_password);
        const fd = new FormData();
        if(file.length > 0) {
            for (let index = 0; index < file.length; index++) {
                fd.append('image', file[index].file, file[index].filename);
            }
        }
        if(file2.length > 0) {
            for (let index = 0; index < file2.length; index++) {
                fd.append('video', file2[index].file, file2[index].filename);
            }
        }
        fd.append('data', ciphertext.toString())
        return this.http.post<any>(finalAPI, fd, { 'headers': headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpPostMultiImage(api, file, file2, objs, param): Promise<any> {
        const token = localStorage.getItem('tokenData');
        const category = localStorage.getItem('category');
        const badgename = localStorage.getItem('badgename');
        var headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'multipart/form-data');
        if(token != null) {
            headers = headers.set('X-Authorization', token);
            if(category != null) {
                headers = headers.set('category', category);
            }
            if(badgename != null) {
                headers = headers.set('badgename', badgename);
            }
        }
        else {
            return
        }

        var _params = "";
        if (param != "") {
            _params = "/" + btoa(param);
        }
        var finalAPI = this.baseurl + api + _params;
   
        var ciphertext = CryptoJS.AES.encrypt(objs.toString(), this.crypto_password);
        const fd = new FormData();
        if(file.length > 0) {
            for (let index = 0; index < file.length; index++) {
                fd.append('image', file[index].file, file[index].filename);
            }
        }
        if(file2.length > 0) {
            for (let index = 0; index < file2.length; index++) {
                fd.append('bgimage', file2[index].file, file2[index].filename);
            }
        }
        fd.append('data', ciphertext.toString())
        return this.http.post<any>(finalAPI, fd, { 'headers': headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    HttpPostImage2(api, image, objs, param): Promise<any> {
        const token = localStorage.getItem('tokenData');
        const category = localStorage.getItem('category');
        const badgename = localStorage.getItem('badgename');
        var headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'multipart/form-data');
        if(token != null) {
            headers = headers.set('X-Authorization', token);
            headers = headers.set('category', category);
            if(badgename != null) {
                headers = headers.set('badgename', badgename);
            }
        }
        else {
            return
        }

        var _params = "";
        if (param != "") {
            _params = "/" + btoa(param);
        }
        var finalAPI = this.baseurl2 + api + _params;
   
        var ciphertext = CryptoJS.AES.encrypt(objs.toString(), this.crypto_password);
        const fd = new FormData();
        for (let index = 0; index < image.length; index++) {
            fd.append('image', image[index].file, image[index].filename);
        }
        fd.append('data', ciphertext.toString())
        return this.http.post<any>(finalAPI, fd, { 'headers': headers }).toPromise().then(res => {
            var bytes = CryptoJS.AES.decrypt(res.data.toString(), this.crypto_password);
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            if(plaintext != undefined && JSON.parse(plaintext).code != undefined && (JSON.parse(plaintext).code == 403 || JSON.parse(plaintext).code == 401)) {
                this.router.navigate(['/']);
                this.titleService.setTitle('Login' + ' - Meeko');
            }
            return JSON.parse(plaintext) as any
        }).catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ChildUserService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  // list() {
  //   return this.httpmanagerService.HttpGet('child', "").then(data => {
  //     return data.data;
  //   });
  // }

  // update(data, id) {
  //   return this.httpmanagerService.HttpPut('child', JSON.stringify(data), id).then(data => {
  //     return data;
  //   });
  // }

  // delete(id) {
  //   return this.httpmanagerService.HttpDelete('child', id).then(data => {
  //     return data;
  //   });
  // }

  // active(data, id) {
  //   return this.httpmanagerService.HttpPut('child/active', JSON.stringify(data), id).then(data => {
  //     return data;
  //   });
  // }

  total() {
    return this.httpmanagerService.HttpGet2('child/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost2('child/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

<div class="nav-header">
	<a [routerLink]="['/admin/index']" class="brand-logo">
		<!-- <img class="logo-abbr" src="assets/images/meeko.png" alt=""> -->
		<!-- <img class="logo-compact" src="assets/images/meeko2.png" alt=""> -->
		<img class="brand-title" src="assets/images/meeko2.png" alt="">
	</a>

	<div class="nav-control">
		<div [ngClass]="{'is-active': hamburgerClass, '': !hamburgerClass}" class="hamburger" (click)="toggleHamburgerClass()">
			<span class="line"></span><span class="line"></span><span class="line"></span>
		</div>
	</div>
</div>
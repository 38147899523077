import { Component, OnInit, ViewChild } from '@angular/core';
/* import { ChartComponent } from "ng-apexcharts";
import {  ApexNonAxisChartSeries,  ApexPlotOptions,  ApexChart} from "ng-apexcharts"; */


/* export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
}; */



@Component({
  selector: 'app-graph-event-held',
  templateUrl: './graph-event-held.component.html',
  styleUrls: ['./graph-event-held.component.css']
})
export class GraphEventHeldComponent implements OnInit {

/*   @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>; */

  constructor() {}
    chartOptions = {
      series: [90],
      chart: {
        height: 110,
        width: 110,
        type: "radialBar",
		zoom: {
			enabled: false
		},
		sparkline: {
			enabled: true
		}
      },
      legend: {
		show:false,
      },
	  colors:['#FE634E'],
	  plotOptions: {
        radialBar: {
          
          hollow: {
            margin: 0,
            size: "50%",
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
			 offsetY: 7,
              show: true
            }
          }
        }
      },
      labels: ["Events"]
    };

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data, image, bgimage) {
    return this.httpmanagerService.HttpPostMultiImage('category', image, bgimage, JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('category', "").then(data => {
      return data.data;
    });
  }

  alllist() {
    return this.httpmanagerService.HttpGet('category/list', "").then(data => {
      return data.data;
    });
  }

  update(data, id, image, bgimage, oldimage, oldbgimage) {
    if(image.length > 0 || bgimage.length > 0) {
      data.oldimage = oldimage;
      data.oldbgimage = oldbgimage;
      return this.httpmanagerService.HttpPostMultiImage('category/update', image, bgimage, JSON.stringify(data), id).then(data => {
        return data;
      });
    }
    else {
      return this.httpmanagerService.HttpPut('category', JSON.stringify(data), id).then(data => {
        return data;
      });
    }
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('category', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('category/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('category/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('category/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  categorylist() {
    return this.httpmanagerService.HttpGet('classes/categorylist', "").then(data => {
      return data.data;
    });
  }

}

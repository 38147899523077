<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Educator Package</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Package Name</th>
                            <th scope="col" class="text-left px-1 py-2">Platform Use (Commision)</th>
                            <th scope="col" class="text-left px-1 py-2">Price</th>
                            <th scope="col" class="text-left px-1 py-2">Validity</th>
                            <th scope="col" class="text-left px-1 py-2">Validity Day Expire</th>
                            <th scope="col" class="text-left px-1 py-2">Total Hours</th>
                            <th scope="col" class="text-left px-1 py-2">Total Minutes</th>
                            <th scope="col" class="text-left px-1 py-2">Total Seconds</th>
                            <th scope="col" class="text-left px-1 py-2">Total Hours Expire</th>
                            <th scope="col" class="text-left px-1 py-2">Coin</th>
                            <th scope="col" class="text-left px-1 py-2">Storage</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Referral</th> -->
                            <!-- <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col" class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let educatorPackage of educatorPackageList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ educatorPackage.packageName }}</td>
                            <td>{{ educatorPackage.platformUse }} %</td>
                            <td>{{ educatorPackage.price | number:'1.2' }}</td>
                            <td>{{ educatorPackage.validity }} {{ educatorPackage.validityType }}</td>
                            <td>{{ educatorPackage.validityDayExpire }}</td>
                            <td>{{ educatorPackage.totalSecond / 60 / 60 }}</td>
                            <td>{{ educatorPackage.totalSecond / 60 }}</td>
                            <td>{{ educatorPackage.totalSecond }}</td>
                            <td>{{ educatorPackage.totalSecondExpire / 60 / 60 }}</td>
                            <td>{{ educatorPackage.coin }}</td>
                            <td>{{ educatorPackage.storage / 1024 / 1024 }} GB</td>
                            <!-- <td>{{ educatorPackage.referral }} {{ educatorPackage.referralType }}</td> -->
                            <!-- <td>{{ educatorPackage.createAt | date }}</td>
                            <td>{{ educatorPackage.admin[0].firstName }} {{ educatorPackage.admin[0].lastName }}</td>
                            <td>{{ educatorPackage.updateAt | date }}</td> -->
                            <td class="text-center">
                                <button [hidden]="educatorPackage.active" (click)="active(educatorPackage.active, educatorPackage._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!educatorPackage.active" (click)="active(educatorPackage.active, educatorPackage._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(educatorPackage, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(educatorPackage, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Educator Package</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Package Name</label>
                    <input type="text" class="form-control" formControlName="packageName" placeholder="Package Name">
                </div>
                <div class="form-group col-md-3">
                    <label>Platform Use (Commision)</label>
                    <input type="text" class="form-control" formControlName="platformUse" placeholder="Platform Use" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Coin</label>
                    <input type="text" class="form-control" formControlName="coin" placeholder="Coin" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Price</label>
                    <input type="text" class="form-control" formControlName="price" placeholder="Price" pattern="\d*">
                </div>
                <!-- <div class="form-group col-md-4">
                    <label>Referral Type</label>
                    <ng-select2 
                        [(ngModel)]="referralType"
                        formControlName="referralType"
                        [data]="referralData"
                        [placeholder]="'Please select a referral type...'">		
                    </ng-select2>
                </div>
                <div class="form-group col-md-4">
                    <label>Referral</label>
                    <input type="text" class="form-control" formControlName="referral" placeholder="referral" pattern="\d*">
                </div> -->
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Validity Type</label>
                    <ng-select2 
                        [(ngModel)]="validityType"
                        formControlName="validityType"
                        [data]="dropDownData"
                        [placeholder]="'Please select a validity type...'">		
                    </ng-select2>
                    <!-- <select class="form-control" formControlName="validityType" [(ngModel)]="validityType" id="inputValidityType">
                        <option value="Days">Days</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                    </select> -->
                </div>
                <div class="form-group col-md-3">
                    <label>Validity {{validityType}}</label>
                    <input type="text" class="form-control" formControlName="validity" placeholder="Validity {{validityType}}" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Total Hours</label>
                    <input type="text" class="form-control" (keyup)="onKeypressEvent($event)" formControlName="totalSecond" placeholder="Total Hours" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Total Minutes</label>
                    <input disabled type="text" class="form-control" [value]="tMinutes">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Total Seconds</label>
                    <input disabled type="text" class="form-control" [value]="tSecond">
                </div>
                <div class="form-group col-md-3">
                    <label>Total Hours Expire</label>
                    <input type="text" class="form-control" formControlName="totalSecondExpire" placeholder="Total Hours Expire" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Validity Days Expire</label>
                    <input type="text" class="form-control" formControlName="validityDayExpire" placeholder="Validity Days Expire" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Storage (KB)</label>
                    <input type="text" class="form-control" formControlName="storage" placeholder="Storage" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Rescheduled Limit</label>
                    <input type="text" class="form-control" formControlName="rescheduledLimit" placeholder="Rescheduled Limit " pattern="\d*">
                </div>
            </div>
            <form [formGroup]="FormPackage" (validSubmit)="addFD()" novalidate>
                <div class="row">
                    <div class="form-group col-md-5">
                        <label class="form-label">Package</label>
                        <ng-select2 
                            [(ngModel)]="name"
                            formControlName="name"
                            [data]="packageData"
                            [placeholder]="'Please select a Package...'">		
                        </ng-select2>
                    </div>
                    <div class="form-group col-md-5">
                        <label  class="form-label">Package Detail</label>
                        <input class="form-control" type="text" formControlName="value" [(ngModel)]="value" placeholder="Package Detail" />
                    </div>
                    <div class="form-group col-md-2 btn_fix">
                        <div class="btn_bottom_class">
                            <button [hidden]="FDBtn" type="submit" class="btn btn-primary">Add</button>
                            <button [hidden]="!FDBtn" type="submit" class="btn btn-primary">Update</button>
                            <button type="button" class="btn btn-light" (click)="FDCancel()">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Package</th>
                                    <th scope="col">Package Detail</th>
                                    <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let package of packageDetailData; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>{{package.name}}</td>
                                    <td>{{package.value}}</td>
                                    <td class="text-center">
                                        <button type="button" (click)="actionFD(package, 'edit', i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                        <button type="button" (click)="actionFD(package, 'del', i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Educator Package</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Package Name</label>
                    <input type="text" class="form-control" formControlName="packageName" [(ngModel)]="packageName" placeholder="Package Name">
                </div>
                <div class="form-group col-md-3">
                    <label>Platform Use (Commision)</label>
                    <input type="text" class="form-control" formControlName="platformUse" [(ngModel)]="platformUse" placeholder="Platform Use (Commision)" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Coin</label>
                    <input type="text" class="form-control" formControlName="coin" [(ngModel)]="coin" placeholder="Coin" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Price</label>
                    <input type="text" class="form-control" formControlName="price" [(ngModel)]="price" placeholder="Price" pattern="\d*">
                </div>
                <!-- <div class="form-group col-md-4">
                    <label>Referral Type</label>
                    <ng-select2 
                        [(ngModel)]="referralType"
                        formControlName="referralType"
                        [data]="referralData"
                        [placeholder]="'Please select a referral type...'">		
                    </ng-select2>
                </div>
                <div class="form-group col-md-4">
                    <label>Referral</label>
                    <input type="text" class="form-control" formControlName="referral" [(ngModel)]="referral" placeholder="referral" pattern="\d*">
                </div> -->
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Validity Type</label>
                    <ng-select2 
                        [(ngModel)]="validityType"
                        formControlName="validityType"
                        [data]="dropDownData"
                        [placeholder]="'Please select a validity type...'">		
                    </ng-select2>
                    <!-- <select class="form-control" formControlName="validityType" [(ngModel)]="validityType" id="inputValidityType">
                        <option value="Days">Days</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                    </select> -->
                </div>
                <div class="form-group col-md-3">
                    <label>Validity {{validityType}}</label>
                    <input type="text" class="form-control" formControlName="validity" [(ngModel)]="validity" placeholder="Validity {{validityType}}" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Total Hours</label>
                    <input type="text" class="form-control" (keyup)="onKeypressEvent($event)" formControlName="totalSecond" [(ngModel)]="totalSecond" placeholder="Total Hours" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Total Minutes</label>
                    <input disabled type="text" class="form-control" [value]="tMinutes">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Total Seconds</label>
                    <input disabled type="text" class="form-control" [value]="tSecond">
                </div>
                <div class="form-group col-md-3">
                    <label>Total Hours Expire</label>
                    <input type="text" class="form-control" formControlName="totalSecondExpire" [(ngModel)]="totalSecondExpire" placeholder="Total Hours Expire" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Validity Days Expire</label>
                    <input type="text" class="form-control" formControlName="validityDayExpire" [(ngModel)]="validityDayExpire" placeholder="Validity Days Expire" pattern="\d*">
                </div>
                <div class="form-group col-md-3">
                    <label>Storage (KB)</label>
                    <input type="text" class="form-control" formControlName="storage"  [(ngModel)]="storage" placeholder="Storage" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Rescheduled Limit</label>
                    <input type="text" class="form-control" formControlName="rescheduledLimit" [(ngModel)]="rescheduledLimit" placeholder="Rescheduled Limit " pattern="\d*">
                </div>
            </div>
            <form [formGroup]="FormPackage" (validSubmit)="addFD()" novalidate>
                <div class="row">
                    <div class="form-group col-md-5">
                        <label class="form-label">Package</label>
                        <ng-select2 
                            [(ngModel)]="name"
                            formControlName="name"
                            [data]="packageData"
                            [placeholder]="'Please select a Package...'">		
                        </ng-select2>
                    </div>
                    <div class="form-group col-md-5">
                        <label  class="form-label">Package Detail</label>
                        <input class="form-control" type="text" formControlName="value" [(ngModel)]="value" placeholder="Package Detail" />
                    </div>
                    <div class="form-group col-md-2 btn_fix">
                        <div class="btn_bottom_class">
                            <button [hidden]="FDBtn" type="submit" class="btn btn-primary">Add</button>
                            <button [hidden]="!FDBtn" type="submit" class="btn btn-primary">Update</button>
                            <button type="button" class="btn btn-light" (click)="FDCancel()">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Package</th>
                                    <th scope="col">Package Detail</th>
                                    <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let package of packageDetailData; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>{{package.name}}</td>
                                    <td>{{package.value}}</td>
                                    <td class="text-center">
                                        <button type="button" (click)="actionFD(package, 'edit', i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                        <button type="button" (click)="actionFD(package, 'del', i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Educator Package</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
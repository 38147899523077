import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BlogService } from './blog.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  Form: FormGroup;

  title;
  seoTitle;
  description;
  metaKeyword;
  metaDescription;
  image;
  type;
  id;
  index;
  filesupload = [];
  typeData = [];
  blogList = [];
  ckeConfig: any;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private blogService: BlogService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      title: ['-', Validators.required],
      seoTitle: ['-', Validators.required],
      type: ['-', Validators.required],
      description: ['-', Validators.required],
      metaKeyword: ['-', Validators.required],
      metaDescription: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();

    this.ckeConfig = {
      extraPlugins:
        "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
        "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
        "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
        "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
        "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
        "indentblock,indentlist,justify,link,list,liststyle,magicline," +
        "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
        "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
        "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
        "tabletools,templates,toolbar,undo,wsc,wysiwygarea"
    };

    this.typeData.push({
      id: "-",
      text: "-- Select Type --",
      disabled: true,
      selected: true
    },
    {
      id: "E-Learner",
      text: "E-Learner",
    },
    {
      id: "Parent",
      text: "Parent",
    },
    {
      id: "Educator",
      text: "Educator",
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.blogService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.blogService.pagination(data).then(data => this.blogList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.filesupload = [];
    if(this.typeData[0] != undefined && this.typeData[0].id != '-') {
      this.typeData.unshift({
        id: "-",
        text: "-- Select Type --",
        disabled: true,
        selected: true
      });
    }
  }

  fileChangeEvent(event) {
    this.filesupload = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  add() {
    if(this.filesupload.length > 0) {
      this.addbtn = true;
      this.blogService.add(this.Form.value, this.filesupload).then(data => {
        if(data.flag == true) {
          this.blogList.unshift(data.data);
          this.total = (parseInt(this.total) + 1).toString();
          this.toastr.successToastr(data.message, 'Blog Added!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.addbtn = false; 
          this.modalService.dismissAll();
        }
      });
    }
    else {
      this.toastr.errorToastr('Please Upload Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.title = data.title;
    this.seoTitle = data.seoTitle;
    this.type = data.type;
    this.description = data.description;
    this.metaKeyword = data.metaKeyword;
    this.metaDescription = data.metaDescription;
    this.image = data.image.name;
    if(this.typeData[0] != undefined && this.typeData[0].id == '-') {
      this.typeData.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    this.blogService.update(this.Form.value, this.id, this.filesupload, this.image).then(data => {
      if(data.flag == true) {
        this.blogList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Blog Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.blogService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.blogList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Blog Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.blogService.active(json, id).then(data => {
      if(data.flag == true) {
        this.blogList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
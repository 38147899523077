<div class="d-flex align-items-center">
		<apx-chart class="event-chart"
		[series]="chartOptions.series"
		[chart]="chartOptions.chart"
		[plotOptions]="chartOptions.plotOptions"
		[labels]="chartOptions.labels"
		[legend]="chartOptions.legend"
		[colors]="chartOptions.colors"
	  ></apx-chart>
	<div>
		<h6 class="fs-18 text-black font-w600">Young</h6>
		<span class="fs-14">17 - 24 Years</span>
	</div>
</div>
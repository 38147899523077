<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Refer History</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <form [formGroup]="Form" (validSubmit)="filter()" novalidate> -->
                <!-- <div class="col-md-1">
                    <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                    <option selected value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <ng-select2
                        [(ngModel)]="status"
                        [data]="statusData"
                        (valueChanged)="onType($event)"
                        [placeholder]="'Please select a status...'">
                    </ng-select2>
                </div>
                <!-- <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" formControlName="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" formControlName="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-4 search-addbtn">
                    <div class="input-group search-area d-xl-inline-flex d-none">
                        <input type="text" class="form-control" id="search" name="search" [(ngModel)]="search" placeholder="Search here...">
                        <div class="input-group-append">
                          <span class="input-group-text search_btn">
                            <button type="button" (click)="searchBtn()"><i class="flaticon-381-search-2"></i></button>
                          </span>
                        </div>
                    </div>
                    <div class="ml-3">
                    </div>
                </div>
            <!-- </form> -->
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Date</th>
                            <th scope="col" class="text-left px-1 py-2">Full Name</th>
                            <th scope="col" class="text-left px-1 py-2">Email</th>
                            <th scope="col" class="text-left px-1 py-2">Mobile</th>
                            <th scope="col" class="text-left px-1 py-2">Remaining Amount</th>
                            <th scope="col" class="text-left px-1 py-2">Total Amount</th>
                            <th scope="col" class="text-left px-1 py-2">History</th>
                        </tr>
                      </thead>
                      <!-- <tbody class="Tbody"> -->
                      <tbody>
                        <tr *ngFor="let referHistory of referHistoryList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ referHistory.date | date: 'medium':'IST' }}</td>
                            <td>{{ referHistory.fullName }}</td>
                            <td>{{ referHistory.email }}</td>
                            <td>{{ referHistory.mobile }}</td>
                            <td>{{ referHistory.referralEarningAmount | number : '1.2-2' }}</td>
                            <td>{{ referHistory.totalReferralEarningAmount | number : '1.2-2' }}</td>
                            <td class="text-center">
                                <button type="button" (click)="show(referHistory, history)" class="btn btn-outline-secondary">
                                  <i class="fa fa-history" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #history let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Refer History List</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <div class=" default-calendar">
                <div class="form-group">
                    <div class="input-group">
                        <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                name="dp" [(ngModel)]="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class=" default-calendar">
                <div class="form-group">
                    <div class="input-group">
                        <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                name="dp" [(ngModel)]="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-md-2">
            <button type="submit" class="btn btn-primary" (click)="filter()">Filter</button> &nbsp;&nbsp;
            <button type="button" class="btn btn-light" (click)="clear()">Clear</button>
          </div>
          <div class="col-md-4 search-addbtn">
              <div class="input-group search-area d-xl-inline-flex d-none">
                  <input type="text" class="form-control" id="search2" name="search2" [(ngModel)]="search2" placeholder="Search here...">
                  <div class="input-group-append">
                    <span class="input-group-text search_btn">
                      <button type="button" (click)="historyPagination()"><i class="flaticon-381-search-2"></i></button>
                    </span>
                  </div>
              </div>
              <div class="ml-3">
              </div>
          </div>
      </div>
      <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                  <th scope="col">#</th>
                  <th scope="col">Withdrawal</th>
                  <th scope="col">Date</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Package Price</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Message</th>
                  <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let referralEarning of referralEarningList; index as i">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{referralEarning.withdrawal == true ? 'Debit' : 'Credit'}}</td>
                  <td>{{referralEarning.date | date: 'medium':'IST'}}</td>
                  <td>{{referralEarning.fullName}}</td>
                  <td>{{referralEarning.type}}</td>
                  <td>{{referralEarning.packagePrice | number : '1.2-2'}}</td>
                  <td>{{referralEarning.amount | number : '1.2-2'}}</td>
                  <td>{{referralEarning.message}}</td>
                  <td>{{referralEarning.status}}</td>
              </tr>
            </tbody>
          </table>
      </div>
      <div class="col-xl-12 col-xxl-12">
        <ngb-pagination [collectionSize]="total2" [(page)]="page2" [pageSize]="selectPageLimit2" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage2($event)"></ngb-pagination>
      </div>
    </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from '../login/login.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  AuthForm: FormGroup;
  btn = false;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrManager,
    private titleService: Title
  ) { 
    this.formAuth();
  }

  formAuth() {
    this.AuthForm = this.formBuilder.group({
      password: ['', Validators.required],
      repassword: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Reset Password' + ' - Meeko');
  }

  resetPassword() {
    this.btn = true;
    this.activatedRoute.params.subscribe(params => { 
      this.authenticationService.resetPassword(this.AuthForm.value, params.id).then(data => {
        if(data.flag) {
          this.toastr.successToastr(data.message, 'Reset Password', { animate: 'slideFromRight', showCloseButton: true });
          this.router.navigate(['/']);
          this.titleService.setTitle('Login' + ' - Meeko');
        }
        else {
          this.btn = false;
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.AuthForm.reset();
        }
      });
    });
  }

}

<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Profile</a></li>
            </ol>
        </div>
        <div class="row">
            <div class="col-xl-12 col-xxl-12">
                <div class="card">
                    <div class="card-header">
						<h4 class="card-title">Profile Update</h4>
					</div>
                    <div class="card-body pb-1">
                        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>First Name</label>
                                    <input type="text" class="form-control" formControlName="firstName" [(ngModel)]="firstName" placeholder="First Name">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" formControlName="lastName" [(ngModel)]="lastName" placeholder="Last Name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Email</label>
                                    <input type="email" class="form-control" formControlName="email" [(ngModel)]="email" placeholder="Email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>User Name</label>
                                    <input type="text" class="form-control" formControlName="userName" [(ngModel)]="userName" placeholder="User Name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label>Address</label>
                                    <textarea class="form-control" rows="4" formControlName="address" [(ngModel)]="address" id="comment"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->
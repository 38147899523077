import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SchoolService } from './school.service';
import { CategoryService } from '../category/category.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.css']
})
export class SchoolComponent implements OnInit {

  Form: FormGroup;

  firstName;
  lastName;
  schoolName;
  contactNumber;
  email;
  address;
  city;
  pincode;
  mainCategory;
  subCategory;
  holderName;
  bankname;
  accountNo;
  IFSCCode;
  branchName;
  accountType;
  status;
  id;
  index;
  schoolList = [];
  mainCategoryData = [];
  subCategoryData = [];
  accountTypeData = [];
  statusData = [];
  search = '';
  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  statusbtn;
  url;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private schoolService: SchoolService,
    private categoryService: CategoryService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) {
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      firstName: ['-', Validators.required],
      lastName: ['-', Validators.required],
      schoolName: ['-', Validators.required],
      contactNumber: ['-', Validators.required],
      email: ['-', Validators.required],
      address: ['-', Validators.required],
      city: ['-', Validators.required],
      pincode: ['-', Validators.required],
      mainCategory: ['-', Validators.required],
      subCategory: ['-', Validators.required],
      holderName: ['-', Validators.nullValidator],
      bankname: ['-', Validators.nullValidator],
      accountNo: ['-', Validators.nullValidator],
      IFSCCode: ['-', Validators.nullValidator],
      branchName: ['-', Validators.nullValidator],
      accountType: ['-', Validators.nullValidator]
    });
  }

  ngOnInit(): void {
    this.search = "";
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.url = this.apiConstant.url;
    this.pagination();

    this.mainCategoryData.push({
      id: "-",
      text: "-- Select Main Category --",
      disabled: true,
      selected: true
    });
    this.categoryService.categorylist().then(data => {
    //   this.mainCategoryData = data;
      if(data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.mainCategoryData.push({
            id: element._id,
            text: element.name,
            subCategory: element.subCategory
          });
        }
      }
    });

    this.subCategoryData.push({
      id: "-",
      text: "-- Select Sub Category --",
      disabled: true,
      selected: true
    });

    this.accountTypeData.push({
      id: "-",
      text: "-- Select Account Type --",
      disabled: true,
      selected: true
    },
    {
      id: 'Saving',
      text: 'Saving'
    },
    {
      id: 'Current',
      text: 'Current'
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  searchBtn() {
    this.pagination();
  }

  pagination() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }

    if(this.search == "") {
      this.schoolService.pagination(data).then(data => {
        this.schoolList = data.data;
        this.total = data.totalRecord.toString();
      });
    }
    else {
      this.schoolService.search(data, this.search).then(data => {
        this.schoolList = data.data;
        this.total = data.totalRecord.toString();
      });
    }
  }

  loadPage(event) {
    this.pageIndex = event;
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
    this.subCategoryData = [{
      id: "-",
      text: "-- Select Sub Category --",
      disabled: true,
      selected: true
    }];
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.statusbtn = false;
  }

  onMainCategory(id) {
    this.subCategoryData = [{
      id: "-",
      text: "-- Select Sub Category --",
      disabled: true,
      selected: true
    }];
    var index = this.mainCategoryData.findIndex(x => x.id.toString() == id.toString());
    if(index != -1 && this.mainCategoryData[index].subCategory.length > 0) {
      for (let i = 0; i < this.mainCategoryData[index].subCategory.length; i++) {
        const element = this.mainCategoryData[index].subCategory[i];
        this.subCategoryData.push({
          id: element._id,
          text: element.name
        });
      }
    }
  }

  add() {
    this.addbtn = true;
    this.schoolService.add(this.Form.value).then(data => {
      if(data.flag == true) {;
        this.schoolList.unshift(data.data);
        this.total = (parseInt(this.total) + 1).toString();
        this.toastr.successToastr(data.message, 'School Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false;
        this.modalService.dismissAll();
      }
    });
  }

  show(data, type, model, i) {
    this.index = i;
    this.id = data._id;
    if(type == 'edit') {
      this.onMainCategory(data.mainCategory);
      this.updatebtn = false;
      this.modalService.open(model, { size: 'xl' });
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.schoolName = data.schoolName;
      this.contactNumber = data.contactNumber;
      this.email = data.email;
      this.address = data.address;
      this.city = data.city;
      this.pincode = data.pincode;
      this.mainCategory = data.mainCategory;
      this.subCategory = data.subCategory;
      this.holderName = data.holderName;
      this.bankname = data.bankname;
      this.accountNo = data.accountNo;
      this.IFSCCode = data.IFSCCode;
      this.branchName = data.branchName;
      this.accountType = data.accountType;
    }
    else if(type == 'status') {
      this.statusbtn = false;
      this.modalService.open(model, { size: 'md' });
      this.status = data.status;
      this.statusData = [{
        id: 'Active',
        text: 'Active',
      },
      {
        id: 'Deactive',
        text: 'Deactive'
      }];
    }
    else {
      this.deletebtn = false;
      this.modalService.open(model, { size: 'md' });
    }
  }

  update() {
    this.updatebtn = true;
    this.schoolService.update(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.schoolList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'School Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.schoolService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.schoolList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'School Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  }

  onStatus(data) {
    this.status = data;
  }

  statusFnc() {
    this.statusbtn = true;
    var json = {
      status: this.status
    }

    this.schoolService.status(json, this.id).then(data => {
      if(data.flag == true) {
        this.schoolList[this.index].status = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.statusbtn = false;
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.modalService.dismissAll();
    });
  }
}

import { Component, OnInit, Injectable } from '@angular/core';
import { BatchDetailViewService } from './batch-detail-view.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-batch-detail-view',
  templateUrl: './batch-detail-view.component.html',
  styleUrls: ['./batch-detail-view.component.css'],
  // providers: [
  //   {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  //  ]
})
export class BatchDetailViewComponent implements OnInit {

  detail;
  Server;

  constructor(
    private batchDetailViewService: BatchDetailViewService,
    private toastr: ToastrManager,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private apiConstant: ApiConstant
  ) {
  }

  ngOnInit(): void {
    this.Server = this.apiConstant.Server2;
    this.activatedRoute.params.subscribe(params => { 
      this.batchDetailViewService.detail(params.id).then(data => {
        if(data.flag) {
          this.detail = data.data;
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });;
        }
      });
    });
  }

  back() {
    this.router.navigate(['/admin/classes']);
    this.titleService.setTitle('Classes' + ' - Meeko');
  }
}
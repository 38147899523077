import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AdminUserService } from '../admin-user/admin-user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  Form: FormGroup;

  firstName;
  lastName;
  departmentId;
  email;
  address;
  userName;
  id;
  profileList = [];

  updatebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private adminUserService: AdminUserService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      firstName: ['-', Validators.required],
      lastName: ['-', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: ['-', Validators.required],
      userName: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.updatebtn = false;
    this.adminUserService.findOne().then(data => {
      this.id = data._id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.email = data.email;
      this.userName = data.userName;
      this.address = data.address;
    });
  }

  update() {
    this.updatebtn = true;
    this.adminUserService.update(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.toastr.successToastr(data.message, 'Profile Updated!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        
      }
      this.updatebtn = false;
    });
  }
}
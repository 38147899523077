<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">School</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" name="search" [(ngModel)]="search" placeholder="Search here...">
                      <div class="input-group-append">
                        <span class="input-group-text search_btn">
                          <button type="button" (click)="searchBtn()"><i class="flaticon-381-search-2"></i></button>
                        </span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Full Name</th>
                            <th scope="col" class="text-left px-1 py-2">School Name</th>
                            <th scope="col" class="text-left px-1 py-2">Contact Number</th>
                            <th scope="col" class="text-left px-1 py-2">Email</th>
                            <th scope="col" class="text-left px-1 py-2">Address</th>
                            <th scope="col" class="text-left px-1 py-2">Main Category</th>
                            <th scope="col" class="text-left px-1 py-2">Sub Category</th>
                            <th scope="col" class="text-left px-1 py-2">Remaining Amount</th>
                            <th scope="col" class="text-left px-1 py-2">Total Amount</th>
                            <th scope="col" class="text-left px-1 py-2">Status</th>
                            <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col" class="text-left px-1 py-2">Refer Card</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <!-- <tbody class="Tbody"> -->
                      <tbody>
                        <tr *ngFor="let school of schoolList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ school.firstName }} {{ school.lastName }}</td>
                            <td>{{ school.schoolName }}</td>
                            <td>{{ school.contactNumber }}</td>
                            <td>{{ school.email }}</td>
                            <td>{{ school.address }}</td>
                            <td>{{ school.mainCategoryData.name }}</td>
                            <td>{{ school.subCategoryData.name }}</td>
                            <td>{{ school.referralEarningAmount | number : '1.2-2' }}</td>
                            <td>{{ school.totalReferralEarningAmount | number : '1.2-2' }}</td>
                            <td>{{ school.status }}</td>
                            <td>{{ school.createAt | date: "medium":"IST" | date: "dd/MM/yyyy hh:mm:ss aa" }}</td>
                            <!-- <td>{{ school.adminData.firstName }} {{ school.adminData.lastName }}</td>
                            <td>{{ school.updateAt | date }}</td> -->
                            <td class="text-center">
                              <a [href]="url + 'school/refer-and-earn/' + school._id" target="_blank"><i class="fa fa-id-card btn btn-outline-secondary"></i></a>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(school, 'status', statusmodel, i)" class="btn btn-outline-primary"><i class="fa fa-power-off" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(school, 'edit', edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <!-- <button type="button" (click)="show(school, 'delete', del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button> -->
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add School</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-4">
                  <label>First Name</label>
                  <input type="text" class="form-control" formControlName="firstName" placeholder="First Name">
                </div>
                <div class="form-group col-md-4">
                  <label>Last Name</label>
                  <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
                </div>
                <div class="form-group col-md-4">
                  <label>School Name</label>
                  <input type="text" class="form-control" formControlName="schoolName" placeholder="School Name">
                </div>
            </div>
            <div class="row">
              <div class="form-group col-md-4">
                <label>Contact Number</label>
                <input type="text" class="form-control" formControlName="contactNumber" placeholder="Contact Number" pattern="\d*">
              </div>
              <div class="form-group col-md-4">
                <label>Email</label>
                <input type="email" class="form-control" formControlName="email" placeholder="Email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              </div>
              <div class="form-group col-md-4">
                <label>Address</label>
                <textarea class="form-control" rows="4" formControlName="address" id="address"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-4">
                <label>City</label>
                <input type="text" class="form-control" formControlName="city" placeholder="City">
              </div>
              <div class="form-group col-md-4">
                <label>Pincode</label>
                <input type="text" class="form-control" formControlName="pincode" placeholder="Pincode">
              </div>
              <div class="form-group col-md-4">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-4">
                <label>Main Category</label>
                <ng-select2
                    formControlName="mainCategory"
                    [data]="mainCategoryData"
                    (valueChanged)="onMainCategory($event)"
                    [placeholder]="'Please select a mian category...'">
                </ng-select2>
              </div>
              <div class="form-group col-md-4">
                <label>Sub Category</label>
                <ng-select2
                    formControlName="subCategory"
                    [data]="subCategoryData"
                    [placeholder]="'Please select a sub category...'">
                </ng-select2>
              </div>
              <div class="form-group col-md-4">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-4">
                <label>Holder Name</label>
                <input type="text" class="form-control" formControlName="holderName" placeholder="Holder Name">
              </div>
              <div class="form-group col-md-4">
                <label>Bank Name</label>
                <input type="text" class="form-control" formControlName="bankname" placeholder="Bank Name">
              </div>
              <div class="form-group col-md-4">
                <label>Account No.</label>
                <input type="number" class="form-control" formControlName="accountNo" placeholder="Account No.">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-4">
                <label>IFSC Code</label>
                <input type="text" class="form-control" formControlName="IFSCCode" placeholder="IFSC Code">
              </div>
              <div class="form-group col-md-4">
                <label>Branch Name</label>
                <input type="text" class="form-control" formControlName="branchName" placeholder="Branch Name">
              </div>
              <div class="form-group col-md-4">
                <label>Account Type</label>
                <ng-select2
                    [(ngModel)]="accountType"
                    formControlName="accountType"
                    [data]="accountTypeData"
                    [placeholder]="'Please select a account type...'">
                </ng-select2>
              </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update School</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
              <div class="form-group col-md-4">
                <label>First Name</label>
                <input type="text" class="form-control" formControlName="firstName" [(ngModel)]="firstName" placeholder="First Name">
              </div>
              <div class="form-group col-md-4">
                <label>Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" [(ngModel)]="lastName" placeholder="Last Name">
              </div>
              <div class="form-group col-md-4">
                <label>School Name</label>
                <input type="text" class="form-control" formControlName="schoolName" [(ngModel)]="schoolName" placeholder="School Name">
              </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label>Contact Number</label>
              <input type="text" class="form-control" formControlName="contactNumber" [(ngModel)]="contactNumber" placeholder="Contact Number" pattern="\d*">
            </div>
            <div class="form-group col-md-4">
              <label>Email</label>
              <input type="email" class="form-control" formControlName="email" [(ngModel)]="email" placeholder="Email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            </div>
            <div class="form-group col-md-4">
              <label>Address</label>
              <textarea class="form-control" rows="4" formControlName="address" [(ngModel)]="address" id="address"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label>City</label>
              <input type="text" class="form-control" formControlName="city" [(ngModel)]="city"  placeholder="City">
            </div>
            <div class="form-group col-md-4">
              <label>Pincode</label>
              <input type="text" class="form-control" formControlName="pincode" [(ngModel)]="pincode" placeholder="Pincode">
            </div>
            <div class="form-group col-md-4">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label>Main Category</label>
              <ng-select2
                  formControlName="mainCategory"
                  [(ngModel)]="mainCategory"
                  [data]="mainCategoryData"
                  (valueChanged)="onMainCategory($event)"
                  [placeholder]="'Please select a mian category...'">
              </ng-select2>
            </div>
            <div class="form-group col-md-4">
              <label>Sub Category</label>
              <ng-select2
                  formControlName="subCategory"
                  [(ngModel)]="subCategory"
                  [data]="subCategoryData"
                  [placeholder]="'Please select a sub category...'">
              </ng-select2>
            </div>
            <div class="form-group col-md-4">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label>Holder Name</label>
              <input type="text" class="form-control" formControlName="holderName" [(ngModel)]="holderName" placeholder="Holder Name">
            </div>
            <div class="form-group col-md-4">
              <label>Bank Name</label>
              <input type="text" class="form-control" formControlName="bankname" [(ngModel)]="bankname" placeholder="Bank Name">
            </div>
            <div class="form-group col-md-4">
              <label>Account No.</label>
              <input type="number" class="form-control" formControlName="accountNo" [(ngModel)]="accountNo" placeholder="Account No.">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label>IFSC Code</label>
              <input type="text" class="form-control" formControlName="IFSCCode" [(ngModel)]="IFSCCode" placeholder="IFSC Code">
            </div>
            <div class="form-group col-md-4">
              <label>Branch Name</label>
              <input type="text" class="form-control" formControlName="branchName" [(ngModel)]="branchName" placeholder="Branch Name">
            </div>
            <div class="form-group col-md-4">
              <label>Account Type</label>
              <ng-select2
                  [(ngModel)]="accountType"
                  formControlName="accountType"
                  [data]="accountTypeData"
                  [placeholder]="'Please select a account type...'">
              </ng-select2>
            </div>
          </div>
          <div class="modal-footer">
              <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
              <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
          </div>
        </form>
    </div>
</ng-template>

<!-- <ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete School</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template> -->

<ng-template #statusmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Status School</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label>Status</label>
          <ng-select2
              [(ngModel)]="status"
              [data]="statusData"
              (valueChanged)="onStatus($event)"
              [placeholder]="'Please select a status...'">
          </ng-select2>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button [disabled]="statusbtn" type="submit" (click)="statusFnc()" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

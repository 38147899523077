import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ChildUserService } from './child-user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-child-user',
  templateUrl: './child-user.component.html',
  styleUrls: ['./child-user.component.css']
})
export class ChildUserComponent implements OnInit {

  Form: FormGroup;

  name;
  id;
  index;
  childUserList = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private childUserService: ChildUserService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      name: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination()
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.childUserService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.childUserService.pagination(data).then(data => this.childUserList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
  }

  // add() {
  //   this.addbtn = true;
  //   this.childUserService.add(this.Form.value).then(data => {
  //     if(data.flag == true) {;
  //       this.childUserList.unshift(data.data);
  //       this.total = (parseInt(this.total) + 1).toString();
  //       this.toastr.successToastr(data.message, 'Child User Added!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.clear();
  //     }
  //     else {
  //       this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.addbtn = false; 
  //       this.modalService.dismissAll();
  //     }
  //   });
  // }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.name = data.name;
  }

  // update() {
  //   this.updatebtn = true;
  //   this.childUserService.update(this.Form.value, this.id).then(data => {
  //     if(data.flag == true) {
  //       this.childUserList[this.index] = data.data;
  //       this.toastr.successToastr(data.message, 'Child User Updated!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.clear();
  //     }
  //     else {
  //       this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.updatebtn = false;
  //     }
  //   });
  // }

  // delete() {
  //   this.deletebtn = true;
  //   this.childUserService.delete(this.id).then(data => {
  //     if(data.flag == true) {
  //       this.childUserList.splice(this.index, 1);
  //       this.total = (parseInt(this.total) - 1).toString();
  //       this.toastr.successToastr(data.message, 'Child User Deleted!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.clear();
  //     }
  //     else {
  //       this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
  //       this.modalService.dismissAll();
  //       this.deletebtn = false;
  //     }
  //   });
  // } 

  // active(act, id, i) {
  //   var active = false;
  //   if(act == false) {
  //     active = true;
  //   }

  //   var json = {
  //     active: active
  //   }
  //   this.childUserService.active(json, id).then(data => {
  //     if(data.flag == true) {
  //       this.childUserList[i].active = data.data;
  //       this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
  //     }
  //     else {
  //       this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
  //     }
  //   });
  // }
}
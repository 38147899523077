<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Booking</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <form [formGroup]="Form" (validSubmit)="filter()" novalidate> -->
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-3">
                <div class=" default-calendar">
                    <div class="form-group">
                        <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class=" default-calendar">
                    <div class="form-group">
                        <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-2">
                <button type="submit" class="btn btn-primary" (click)="filter()">Filter</button> &nbsp;&nbsp;
				        <button type="button" class="btn btn-light" (click)="clear()">Clear</button>
              </div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <!-- <input type="text" class="form-control" id="search" placeholder="Search here..."> -->
                      <input
                        type="text"
                        class="form-control"
                        id="search"
                        name="search"
                        [(ngModel)]="search"
                        placeholder="Search here..."
                      />
                      <div class="input-group-append">
                        <span class="input-group-text search_btn"
                          ><button type="button" (click)="searchBtn()"
                            ><i class="flaticon-381-search-2"></i></button
                        ></span>
                      </div>
                      <!-- <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div> -->
                  </div>
                  <div class="ml-3">
                  </div>
              </div>
            <!-- </form> -->
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Date</th>
                            <th scope="col" class="text-left px-1 py-2">Class Name</th>
                            <th scope="col" class="text-left px-1 py-2">Batch Name</th>
                            <th scope="col" class="text-left px-1 py-2">Child Name</th>
                            <th scope="col" class="text-left px-1 py-2">Parent Name</th>
                            <th scope="col" class="text-left px-1 py-2">Parent Email</th>
                            <th scope="col" class="text-left px-1 py-2">Educator Name</th>
                        </tr>
                      </thead>
                      <!-- <tbody class="Tbody"> -->
                    <tbody>
                        <tr *ngFor="let booking of bookingList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ booking.date | date: "medium":"IST" | date: "dd/MM/yyyy hh:mm:ss aa" }}</td>
                            <td>{{ booking.className }}</td>
                            <td>{{ booking.batchName }}</td>
                            <td>{{ booking.fullName }}</td>
                            <td>{{ booking.parentName }}</td>
                            <td>{{ booking.parentEmail }}</td>
                            <td>{{ booking.educatorName }}</td>
                            <!-- <td class="text-center">
                                <button [hidden]="booking.active" (click)="active(booking.active, booking._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!booking.active" (click)="active(booking.active, booking._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(booking, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(booking, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td> -->
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

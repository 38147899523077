import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data) {
    return this.httpmanagerService.HttpPost('school', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('school', "").then(data => {
      return data.data;
    });
  }

  update(data, id) {
    return this.httpmanagerService.HttpPut('school', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('school', id).then(data => {
      return data;
    });
  }

  status(data, id) {
    return this.httpmanagerService.HttpPut('school/status', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('school/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  search(data, id) {
    return this.httpmanagerService.HttpPost('school/search', JSON.stringify(data), id).then(data => {
      return data;
    });
  }
}

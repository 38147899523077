<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Staff</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
            <div class="col-md-8"></div>
            <div class="col-md-4 search-addbtn">
                <div class="input-group search-area d-xl-inline-flex d-none">
                    <input type="text" class="form-control" id="search" placeholder="Search here...">
                    <div class="input-group-append">
                        <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                    </div>
                </div>
                <div class="ml-3">
                    <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Fullname</th>
                            <th scope="col" class="text-left px-1 py-2">Email</th>
                            <th scope="col" class="text-left px-1 py-2">Department</th> 
                            <th scope="col" class="text-left px-1 py-2">Username</th>
                            <th scope="col" class="text-left px-1 py-2">Address</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col" class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let admin of adminList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ admin.firstName }} {{ admin.lastName }}</td>
                            <td>{{ admin.email }}</td>
                            <td>{{ admin.department[0].name }}</td>
                            <td>{{ admin.userName }}</td>
                            <td>{{ admin.address }}</td>
                            <!-- <td>{{ admin.createAt | date }}</td>
                            <td >{{ admin.admin[0].firstName }} {{ admin.admin[0].lastName }}</td>
                            <td>{{ admin.updateAt | date }}</td> -->
                            <td class="text-center">
                                <button [hidden]="admin.active" (click)="active(admin.active, admin._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!admin.active" (click)="active(admin.active, admin._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(admin, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(admin, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Staff</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>First Name</label>
                    <input type="text" class="form-control" formControlName="firstName" placeholder="First Name">
                </div>
                <div class="form-group col-md-6">
                    <label>Last Name</label>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Email</label>
                    <input type="email" class="form-control" formControlName="email" placeholder="Email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                </div>
                <div class="form-group col-md-6">
                    <label>Department</label>
                    <ng-select2 
                        [(ngModel)]="departmentId"
                        formControlName="departmentId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a department...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>User Name</label>
                    <input type="text" class="form-control" formControlName="userName" placeholder="User Name">
                </div>
                <div class="form-group col-md-6">
                    <label>Password</label>
                    <input type="password" class="form-control" formControlName="password" placeholder="Password">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Address</label>
                    <textarea class="form-control" rows="4" formControlName="address" id="comment"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Staff</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="UpdateForm" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>First Name</label>
                    <input type="text" class="form-control" formControlName="firstName" [(ngModel)]="firstName" placeholder="First Name">
                </div>
                <div class="form-group col-md-6">
                    <label>Last Name</label>
                    <input type="text" class="form-control" formControlName="lastName" [(ngModel)]="lastName" placeholder="Last Name">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Email</label>
                    <input type="email" class="form-control" formControlName="email" [(ngModel)]="email" placeholder="Email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                </div>
                <div class="form-group col-md-6">
                    <label>Department</label>
                    <ng-select2 
                        [(ngModel)]="departmentId"
                        formControlName="departmentId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a department...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>User Name</label>
                    <input type="text" class="form-control" formControlName="userName" [(ngModel)]="userName" placeholder="User Name">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Address</label>
                    <textarea class="form-control" rows="4" formControlName="address" [(ngModel)]="address" id="comment"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Staff</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ELearnerSliderService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data, image) {
    return this.httpmanagerService.HttpPostImage('slider', image, JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  update(data, id, image, oldimage) {
    if(image.length > 0) {
      data.oldimage = oldimage; 
      return this.httpmanagerService.HttpPostImage('slider/update', image, JSON.stringify(data), id).then(data => {
        return data;
      });
    }
    else {
      return this.httpmanagerService.HttpPut('slider', JSON.stringify(data), id).then(data => {
        return data;
      });
    }
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('slider', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('slider/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total(data) {
    return this.httpmanagerService.HttpPost('slider/total', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('slider/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

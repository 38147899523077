import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { EnquireService } from './enquire.service';
import { ToastrManager } from 'ng6-toastr-notifications';
// import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ApiConstant } from '../api.constant';


// @Injectable()
// export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
//   parse(value: string): NgbDateStruct | null {
//     if (value) {
//       const dateParts = value.trim().split('-');

//       let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
//       const dateLabels = Object.keys(dateObj);

//       dateParts.forEach((datePart, idx) => {
//         dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
//       });
//       return dateObj;
//     }
//     return null;
//   }

//   format(date: NgbDateStruct | null): string {
//     return date ?
//         `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
//         '';
//   }
// }

// function padNumber(value: number | null) {
//   if (!isNaN(value) && value !== null) {
//     return `0${value}`.slice(-2);
//   } else {
//     return '';
//   }
// }

@Component({
  selector: 'app-enquire',
  templateUrl: './enquire.component.html',
  styleUrls: ['./enquire.component.css'],
  // providers: [
  //   {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  //  ]
})
export class EnquireComponent implements OnInit {

  Form: FormGroup;

  name;
  id;
  index;
  enquireList = [];
  statusData;
  status = '-';
  // startDate: NgbDateStruct;
  // endDate: NgbDateStruct;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private enquireService: EnquireService,
    private toastr: ToastrManager,
    // private calendar: NgbCalendar,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      name: ['-', Validators.required]
      // startDate: ['-', Validators.required], 
      // endDate: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();

    this.statusData = [{
      id: "-",
      text: "All Status"
    },
    {
      id: true,
      text: 'Active',
    },
    {
      id: false,
      text: 'Deactive'
    }];

    // var dateObj = new Date();
    // var month = dateObj.getUTCMonth() + 1; //months from 1-12
    // var day = dateObj.getUTCDate();
    // var year = dateObj.getUTCFullYear();

    // this.startDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
    // this.endDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    var data = {
      status: this.status
    }

    // if(this.FormEud.value.startDate != '-') {
    //   this.FormEud.value.startDate = this.FormEud.value.startDate.year + '-' + this.FormEud.value.startDate.month + '-' + this.FormEud.value.startDate.day;
    //   this.FormEud.value.startDate = new Date(this.FormEud.value.startDate).toISOString();
    // }
    // if(this.FormEud.value.endDate != '-') {
    //   this.FormEud.value.endDate = this.FormEud.value.endDate.year + '-' + this.FormEud.value.endDate.month + '-' + (this.FormEud.value.endDate.day + 1);
    //   this.FormEud.value.endDate = new Date(this.FormEud.value.endDate).toISOString();
    // }


    this.enquireService.total(data).then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit),
      status: this.status
    }
    this.enquireService.pagination(data).then(data => this.enquireList = data.data);
  }

  onType(event) {
    this.status = event;
    this.pagination();
  }

  filter() {

  }
}
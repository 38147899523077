<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Category Classes</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <div class="col-md-3">
                <ng-select2 
                    [(ngModel)]="status"
                    [data]="statusData"
                    (valueChanged)="onType($event)"
                    [placeholder]="'Please select a status...'">		
                </ng-select2>
            </div>
            <div class="col-md-3">
                <div class=" default-calendar">
                    <div class="form-group">
                        <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class=" default-calendar">
                    <div class="form-group">
                        <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <button type="submit" class="btn btn-primary" (click)="filter()">Filter</button> &nbsp;&nbsp;
                <button type="button" class="btn btn-light" (click)="clear()">Clear</button>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Category Name</th>
                            <th scope="col" class="text-left px-1 py-2">Class Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let categoryClasses of categoryClassesList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ categoryClasses.name }}</td>
                            <td><button [disabled]="categoryClasses.classDetail.length == 0" (click)="show(categoryClasses.classDetail, classes)" type="button" class="btn btn-outline-info"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #classes let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Class List</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                    <th scope="col" class="text-left py-2 px-3">#</th>
                    <th scope="col" class="text-left px-1 py-2">Class Name</th>
                    <th scope="col" class="text-left px-1 py-2">Status</th>
                    <th scope="col" class="text-left px-1 py-2">Mobile</th>
                    <th scope="col" class="text-left px-1 py-2">Link</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let classes of classesList; index as i">
                    <td scope="row" class="px-3">{{ i + 1 }}</td>
                    <td>{{ classes.title }}</td>
                    <td>{{ classes.status }}</td>
                    <td>{{ classes.mobile }}</td>
                    <td>
                        <a [href]="classes.link" target="_blank"><i class="fa fa-link btn btn-outline-primary"></i></a>
                    </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
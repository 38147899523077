<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Tax Rates</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Tax Name</th>
                            <th scope="col" class="text-left px-1 py-2">Tax Type</th>
                            <th scope="col" class="text-left px-1 py-2">Tax Rate</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col" class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let taxRates of taxRatesList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ taxRates.taxName }}</td>
                            <td>{{ taxRates.taxType }}</td>
                            <td>{{ taxRates.taxRate | number : '1.2-2' }}</td>
                            <!-- <td>{{ taxRates.createAt | date }}</td>
                            <td>{{ taxRates.admin[0].firstName }} {{ taxRates.admin[0].lastName }}</td>
                            <td>{{ taxRates.updateAt | date }}</td> -->
                            <td class="text-center">
                                <button [hidden]="taxRates.active" (click)="active(taxRates.active, taxRates._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!taxRates.active" (click)="active(taxRates.active, taxRates._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(taxRates, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(taxRates, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Tax Rates</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Tax Name</label>
                    <input type="text" class="form-control" formControlName="taxName" placeholder="Tax Name">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Tax Type</label>
                    <!-- <select class="form-control" formControlName="taxType" [(ngModel)]="taxType" id="inputType">
                        <option value="Fix Amount">Fix Amount</option>
                        <option value="Percentage">Percentage </option>
                    </select> -->
                    <ng-select2 
                        formControlName="taxType"
                        [data]="dropDownData"
                        [placeholder]="'Please select a tax type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Tax Rate</label>
                    <input type="text" class="form-control" formControlName="taxRate" placeholder="Tax Rate" pattern="\d*">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Tax Rates</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Tax Name</label>
                    <input type="text" class="form-control" formControlName="taxName" [(ngModel)]="taxName" placeholder="Tax Name">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Tax Type</label>
                    <!-- <select class="form-control" formControlName="taxType" [(ngModel)]="taxType" id="inputType">
                        <option value="Fix Amount">Fix Amount</option>
                        <option value="Percentage">Percentage </option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="taxType"
                        formControlName="taxType"
                        [data]="dropDownData"
                        [placeholder]="'Please select a tax type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Tax Rate</label>
                    <input type="text" class="form-control" formControlName="taxRate" [(ngModel)]="taxRate" placeholder="Tax Rate" pattern="\d*">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Tax Rates</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
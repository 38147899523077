import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TutorialService } from './tutorial.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  Form: FormGroup;

  text;
  type;
  link;
  video;
  image;
  id;
  index;
  typeData = [];
  tutorialList = [];
  filesupload = [];
  filesupload1 = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;
  ckeConfig: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private tutorialService: TutorialService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      text: ['-', Validators.required],
      type: ['-', Validators.required],
      link: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();

    this.typeData.push({
      id: "-",
      text: "-- Select Type --",
      disabled: true,
      selected: true
    },
    {
      id: "E-Learner",
      text: "E-Learner",
    },
    {
      id: "Parent",
      text: "Parent",
    },
    {
      id: "Educator",
      text: "Educator",
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.tutorialService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.tutorialService.pagination(data).then(data => this.tutorialList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.filesupload = [];
    this.filesupload1 = [];
    if(this.typeData[0] != undefined && this.typeData[0].id != '-') {
      this.typeData.unshift({
        id: "-",
        text: "-- Select Type --",
        disabled: true,
        selected: true
      });
    }
  }

  fileChangeEvent(event) {
    this.filesupload = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  fileChangeEvent1(event) {
    this.filesupload1 = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload1.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  add() {
    // if(this.filesupload.length > 0) {
      // if(this.filesupload1.length > 0) {
        this.addbtn = true;
        this.tutorialService.add(this.Form.value, this.filesupload, this.filesupload1).then(data => {
          if(data.flag == true) {;
            this.tutorialList.unshift(data.data);
            this.total = (parseInt(this.total) + 1).toString();
            this.toastr.successToastr(data.message, 'Tutorial Added!', { animate: 'slideFromRight', showCloseButton: true });
            this.modalService.dismissAll();
            this.clear();
          }
          else {
            this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
            this.addbtn = false; 
            this.modalService.dismissAll();
          }
        });
      // }
      // else {
      //   this.toastr.errorToastr('Please Upload Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      // }
    // }
    // else {
    //   this.toastr.errorToastr('Please Upload Video!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    // }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.video = data.file.path;
    this.image = data.image.path;
    this.text = data.text;
    this.type = data.type;
    this.link = data.link;
    if(this.typeData[0] != undefined && this.typeData[0].id == '-') {
      this.typeData.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    this.tutorialService.update(this.Form.value, this.id, this.filesupload, this.filesupload1, this.video, this.image).then(data => {
      if(data.flag == true) {
        this.tutorialList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Tutorial Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.tutorialService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.tutorialList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Tutorial Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.tutorialService.active(json, id).then(data => {
      if(data.flag == true) {
        this.tutorialList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ClassesService } from './classes.service';
import { ToastrManager } from 'ng6-toastr-notifications';
// import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { ApiConstant } from '../api.constant';


// @Injectable()
// export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
//   parse(value: string): NgbDateStruct | null {
//     if (value) {
//       const dateParts = value.trim().split('-');

//       let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
//       const dateLabels = Object.keys(dateObj);

//       dateParts.forEach((datePart, idx) => {
//         dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
//       });
//       return dateObj;
//     }
//     return null;
//   }

//   format(date: NgbDateStruct | null): string {
//     return date ?
//         `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
//         '';
//   }
// }

// function padNumber(value: number | null) {
//   if (!isNaN(value) && value !== null) {
//     return `0${value}`.slice(-2);
//   } else {
//     return '';
//   }
// }

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css'],
  // providers: [
  //   {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  //  ]
})
export class ClassesComponent implements OnInit {

  Form: FormGroup;

  name;
  id;
  index;
  classesList = [];
  statusData;
  status = 'InReview';
  // startDate: NgbDateStruct;
  // endDate: NgbDateStruct;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private classesService: ClassesService,
    private toastr: ToastrManager,
    private router: Router,
    private titleService: Title,
    // private calendar: NgbCalendar,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      message: ['', Validators.required]
      // startDate: ['-', Validators.required], 
      // endDate: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
   
    const statusInReview = localStorage.getItem('statusInReview');
    if(statusInReview != null) {
      this.status = statusInReview;
    }
    this.pagination();

    this.statusData = [{
    //   id: "-",
    //   text: "All Status"
    // },
    // {
      id: 'Active',
      text: 'Active',
    },
    {
      id: 'Deactive',
      text: 'Deactive'
    },
    {
      id: 'Draft',
      text: 'Draft'
    },
    {
      id: 'InReview',
      text: 'InReview'
    }];

    // var dateObj = new Date();
    // var month = dateObj.getUTCMonth() + 1; //months from 1-12
    // var day = dateObj.getUTCDate();
    // var year = dateObj.getUTCFullYear();

    // this.startDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
    // this.endDate = {
    //   day: day,
    //   month: month,
    //   year: year
    // }
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    var data = {
      status: this.status
    }

    // if(this.FormEud.value.startDate != '-') {
    //   this.FormEud.value.startDate = this.FormEud.value.startDate.year + '-' + this.FormEud.value.startDate.month + '-' + this.FormEud.value.startDate.day;
    //   this.FormEud.value.startDate = new Date(this.FormEud.value.startDate).toISOString();
    // }
    // if(this.FormEud.value.endDate != '-') {
    //   this.FormEud.value.endDate = this.FormEud.value.endDate.year + '-' + this.FormEud.value.endDate.month + '-' + (this.FormEud.value.endDate.day + 1);
    //   this.FormEud.value.endDate = new Date(this.FormEud.value.endDate).toISOString();
    // }


    this.classesService.total(data).then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit),
      status: this.status
    }
    this.classesService.pagination(data).then(data => {
      this.classesList = data.data
    });
  }

  onType(event) {
    this.status = event;
    localStorage.setItem('statusInReview', this.status);
    this.pagination();
  }

  show(id, type, i) {
    if(type == 'cv') {
      this.router.navigate(['/admin/class-detail-view/' + id]);
      this.titleService.setTitle('Class Detail View' + ' - Meeko');
    }
    else if(type == 'bcv') {
      this.router.navigate(['/admin/batch-detail-view/' + id]);
      this.titleService.setTitle('Batch Detail View' + ' - Meeko');
    }
    else {
      this.id = id;
      this.index = i;
      this.modalService.open(type, { size: 'lg' });
    }
  }

  inReview() {
    this.classesService.inReview(this.id).then(data => {
      if(data.flag) {
        this.classesList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'In Review!', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.modalService.dismissAll();
    });
  }

  rejectFnc() {
    this.classesService.reject(this.Form.value, this.id).then(data => {
      if(data.flag) {
        this.classesList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Reject!', { animate: 'slideFromRight', showCloseButton: true });
        this.Form.reset();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
      this.modalService.dismissAll();
    });
  }
}
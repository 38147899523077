import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferHistoryService } from './refer-history.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ApiConstant } from '../api.constant';


@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
        `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
        '';
  }
}

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Component({
  selector: 'app-refer-history',
  templateUrl: './refer-history.component.html',
  styleUrls: ['./refer-history.component.css'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class ReferHistoryComponent implements OnInit {

  id;
  referHistoryList = [];
  referralEarningList = [];
  statusData;
  status = '';
  search = '';
  search2 = '';
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;

  page2 = 1;
  selectPageLimit2;
  total2 = "0";
  pageIndex2 = 0;

  constructor(
    private modalService: NgbModal,
    private referHistoryService: ReferHistoryService,
    private toastr: ToastrManager,
    private calendar: NgbCalendar,
    private apiConstant: ApiConstant
  ) {
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  ngOnInit(): void {
    this.search = "";
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;

    const status = localStorage.getItem('statusReferHistory');
    if(status != null) {
      this.status = status;
    }
    else {
      this.status = 'Educator';
    }

    this.pagination();

    this.statusData = [{
      id: 'Educator',
      text: 'Educator',
    },
    {
      id: 'Parent',
      text: 'Parent',
    },
    {
      id: 'School',
      text: 'School'
    }];
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  searchBtn() {
    this.pagination();
  }

  pagination() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit),
      status: this.status
    }

    if(this.search == "") {
      this.referHistoryService.pagination(data).then(data => {
        if(data.flag) {
          this.referHistoryList = data.data.filterData;
          this.total = data.data.totalRecord.toString();
        }
      });
    }
    else {
      this.referHistoryService.search(data, this.search).then(data => {
        if(data.flag) {
          this.referHistoryList = data.data.filterData;
          this.total = data.data.totalRecord.toString();
        }
      });
    }
  }

  historyPagination() {
    var json = {
      id: this.id,
      startDate: '-',
      endDate: '-',
      skip: (this.pageIndex2 - 1) * parseInt(this.selectPageLimit2),
      limit: parseInt(this.selectPageLimit2),
      status: this.status,
      search: this.search2
    }

    this.referHistoryService.history(json).then(data => {
      if(data.flag) {
        this.referralEarningList = data.data.filterData;
        this.total2 = data.data.totalRecord.toString();
      }
    });
  }

  filter() {
    var startDate = this.startDate.year + '-' + this.startDate.month + '-' + this.startDate.day;
    startDate = new Date(startDate).toISOString();
    var endDate = this.endDate.year + '-' + this.endDate.month + '-' + this.endDate.day;
    endDate = new Date(endDate).toISOString();

    var json = {
      id: this.id,
      startDate: startDate,
      endDate: endDate,
      skip: (this.pageIndex2 - 1) * parseInt(this.selectPageLimit2),
      limit: parseInt(this.selectPageLimit2),
      status: this.status,
      search: this.search2
    }

    this.referHistoryService.history(json).then(data => {
      if(data.flag) {
        this.referralEarningList = data.data.filterData;
        this.total2 = data.data.totalRecord.toString();
      }
    });
  }

  clear() {
    this.search2 = "";
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day,
      month: month,
      year: year
    }
    this.historyPagination();
  }

  loadPage(event) {
    this.pageIndex = event;
    this.pagination();
  }

  loadPage2(event) {
    this.pageIndex2 = event;
    this.historyPagination();
  }

  onType(event) {
    this.status = event;
    localStorage.setItem('statusReferHistory', this.status);
    this.pagination();
  }

  onStatus(event) {
    this.status = event;
  }

  show(data, type) {
    this.id = data._id;

    this.total2 = "0";
    this.search2 = '';
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit2 = this.apiConstant.pagination;
    this.pageIndex2 = 1;

    this.historyPagination();

    this.modalService.open(type, { size: 'xl' });
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day,
      month: month,
      year: year
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';




import { DashboardComponent } from './dashboard/dashboard.component';

import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Error400Component } from './pages/error400/error400.component';
import { Error403Component } from './pages/error403/error403.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error503Component } from './pages/error503/error503.component';
/* import { BoxplotComponent } from './charts/apex/boxplot/boxplot.component'; */

import { AdminUserComponent } from './admin-user/admin-user.component';
import { DepartmentComponent } from './department/department.component';
import { SubscriptionPackageComponent } from './subscription-package/subscription-package.component';
import { EducatorPackageComponent } from './educator-package/educator-package.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { BadgeLevelComponent } from './badge-level/badge-level.component';
import { BitmojiCategoryComponent } from './bitmoji-category/bitmoji-category.component';
import { BitmojiAccessComponent } from './bitmoji-access/bitmoji-access.component';
import { ELearnerComponent } from './e-learner/e-learner.component';
import { EducatorComponent } from './educator/educator.component';
import { OfferComponent } from './offer/offer.component';
import { PreferenceComponent } from './preference/preference.component';
import { TaxRatesComponent } from './tax-rates/tax-rates.component';
import { LanguageComponent } from './language/language.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationComponent } from './notification/notification.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
// import { ChildUserComponent } from './child-user/child-user.component';
// import { ParentUserComponent } from './parent-user/parent-user.component';
import { EducatorUserComponent } from './educator-user/educator-user.component';
import { ChatbotQuestionnaireComponent } from './chatbot-questionnaire/chatbot-questionnaire.component';
import { ELearnerSliderComponent } from './e-learner-slider/e-learner-slider.component';
import { GiftCoinComponent } from './giftcoin/giftcoin.component';
import { OfferCoinComponent } from './offer-coin/offer-coin.component';
import { CertificateComponent } from './certificate/certificate.component';
import { OfferBannerComponent } from './offer-banner/offer-banner.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { TestimonialEducatorComponent } from './testimonial-educator/testimonial-educator.component';
import { TestimonialParentComponent } from './testimonial-parent/testimonial-parent.component';
import { FAQComponent } from './faq/faq.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { BlogComponent } from './blog/blog.component';
import { EnquireComponent } from './enquire/enquire.component';
import { ReferralFriendComponent } from './referral-friend/referral-friend.component';
import { ChildHelpDeskComponent } from './child-help-desk/child-help-desk.component';
import { ClassesComponent } from './classes/classes.component';
import { ClassDetailViewComponent } from './class-detail-view/class-detail-view.component';
import { BatchDetailViewComponent } from './batch-detail-view/batch-detail-view.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { BeneficiaryListComponent } from './beneficiary-list/beneficiary-list.component';
import { EducatorFinanceComponent } from './educator-finance/educator-finance.component';
import { ELearnerRefundComponent } from './e-learner-refund/e-learner-refund.component';
import { WhatsAppComponent } from './whatsapp/whatsapp.component';
import { BookingComponent } from './booking/booking.component';
import { CategoryClassesComponent } from './category-classes/category-classes.component';
import { EducatorWithdrawalReferComponent } from './educator-withdrawal-refer/educator-withdrawal-refer.component';
import { ParentWithdrawalReferComponent } from './parent-withdrawal-refer/parent-withdrawal-refer.component';
import { SchoolWithdrawalReferComponent } from './school-withdrawal-refer/school-withdrawal-refer.component';
import { SchoolComponent } from './school/school.component';
import { ReferHistoryComponent } from './refer-history/refer-history.component';
import { ReferFriendHistoryComponent } from './refer-friend-history/refer-friend-history.component';
// import { BeneficiaryParentComponent } from './beneficiary-parent/beneficiary-parent.component';
// import { BeneficiaryListParentComponent } from './beneficiary-list-parent/beneficiary-list-parent.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './pages/auth/auth.guard';
import { RoleGuard } from './pages/auth/role.guard';
import { ClassConvenienceFee } from './class-convenience-fee/class-convenience-fee.component';

const routes: Routes = [
                {path: '', redirectTo: 'page-login', pathMatch: 'full' },
                {
                  path: 'admin', component: AdminComponent, canActivate: [AuthGuard, RoleGuard], children: [

                      {path: 'index', component: DashboardComponent},
                      /* {path: 'apex-boxplot', component: BoxplotComponent}, */

                      {path: 'admin-user', component: AdminUserComponent},
                      {path: 'department', component: DepartmentComponent},
                      {path: 'subscription-package', component: SubscriptionPackageComponent},
                      {path: 'educator-package', component: EducatorPackageComponent},
                      {path: 'category', component: CategoryComponent},
                      {path: 'sub-category', component: SubCategoryComponent},
                      {path: 'user-type', component: UserTypeComponent},
                      {path: 'badge-level', component: BadgeLevelComponent},
                      {path: 'bitmoji-category', component: BitmojiCategoryComponent},
                      {path: 'bitmoji-access', component: BitmojiAccessComponent},
                      {path: 'e-learner', component: ELearnerComponent},
                      {path: 'educator', component: EducatorComponent},
                      {path: 'offer', component: OfferComponent},
                      {path: 'preferences', component: PreferenceComponent},
                      {path: 'tax-rates', component: TaxRatesComponent},
                      {path: 'class_convenience_fee', component: ClassConvenienceFee},
                      {path: 'language', component: LanguageComponent},
                      {path: 'profile', component: ProfileComponent},
                      {path: 'notification', component: NotificationComponent},
                      // {path: 'child-user', component: ChildUserComponent},
                      // {path: 'parent-user', component: ParentUserComponent},
                      {path: 'educator-user', component: EducatorUserComponent},
                      {path: 'chatbot-questionnaire', component: ChatbotQuestionnaireComponent},
                      {path: 'e-learner-slider', component: ELearnerSliderComponent},
                      {path: 'giftcoin', component: GiftCoinComponent},
                      {path: 'offer-coin', component: OfferCoinComponent},
                      {path: 'certificate', component: CertificateComponent},
                      {path: 'offer-banner', component: OfferBannerComponent},
                      {path: 'email-configuration', component: EmailConfigurationComponent},
                      {path: 'email-template', component: EmailTemplateComponent},
                      {path: 'testimonial-educator', component: TestimonialEducatorComponent},
                      {path: 'testimonial-parent', component: TestimonialParentComponent},
                      {path: 'faq', component: FAQComponent},
                      {path: 'tutorial', component: TutorialComponent},
                      {path: 'blog', component: BlogComponent},
                      {path: 'enquire', component: EnquireComponent},
                      {path: 'child-help-desk', component: ChildHelpDeskComponent},
                      {path: 'referral-friend', component: ReferralFriendComponent},
                      {path: 'classes', component: ClassesComponent},
                      {path: 'class-detail-view/:id', component: ClassDetailViewComponent},
                      {path: 'batch-detail-view/:id', component: BatchDetailViewComponent},
                      {path: 'beneficiary', component: BeneficiaryComponent},
                      {path: 'beneficiary-list', component: BeneficiaryListComponent},
                      {path: 'educator-finance', component: EducatorFinanceComponent},
                      {path: 'e-learner-refund', component: ELearnerRefundComponent},
                      {path: 'whatsapp', component: WhatsAppComponent},
                      {path: 'booking', component: BookingComponent},
                      {path: 'category-classes', component: CategoryClassesComponent},
                      {path: 'educator-withdrawal-refer', component: EducatorWithdrawalReferComponent},
                      {path: 'parent-withdrawal-refer', component: ParentWithdrawalReferComponent},
                      {path: 'school-withdrawal-refer', component: SchoolWithdrawalReferComponent},
                      {path: 'school', component: SchoolComponent},
                      {path: 'refer-history', component: ReferHistoryComponent},
                      {path: 'refer-friend-history', component: ReferFriendHistoryComponent},
                      // {path: 'beneficiary-parent', component: BeneficiaryParentComponent},
                      // {path: 'beneficiary-list-parent', component: BeneficiaryListParentComponent}
                  ]
                },
                {path: 'page-register', component: RegisterComponent},
                {path: 'page-login', component: LoginComponent},
                {path: 'page-lock-screen', component: LockScreenComponent},
                {path: 'page-forgot-password', component: ForgotPasswordComponent},
                {path: 'page-reset-password/:id', component: ResetPasswordComponent},
                {path: 'page-error-400', component: Error400Component},
                {path: 'page-error-403', component: Error403Component},
                {path: 'page-error-404', component: Error404Component},
                {path: 'page-error-500', component: Error500Component},
                {path: 'page-error-503', component: Error503Component},

                {path: '**', component: Error404Component},



              ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

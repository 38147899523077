import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  total(data, id) {
    return this.httpmanagerService.HttpPost('booking/pagination', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  pagination(data, id) {
    return this.httpmanagerService.HttpPost('booking/', JSON.stringify(data), id).then(data => {
      return data;
    });
  }
}

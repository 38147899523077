import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data, image, bgimage) {
    return this.httpmanagerService.HttpPostMultiImage('certificate', image, bgimage, JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('certificate', "").then(data => {
      return data.data;
    });
  }

  alllist() {
    return this.httpmanagerService.HttpGet('category/list', "").then(data => {
      return data.data;
    });
  }

  update(data, id, image, bgimage, oldimage, oldbgimage) {
    if(image.length > 0 || bgimage.length > 0) {
      data.oldimage = oldimage; 
      data.oldbgimage = oldbgimage; 
      return this.httpmanagerService.HttpPostMultiImage('certificate/update', image, bgimage, JSON.stringify(data), id).then(data => {
        return data;
      });
    }
    else {
      return this.httpmanagerService.HttpPut('certificate', JSON.stringify(data), id).then(data => {
        return data;
      });
    }
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('certificate', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('certificate/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('certificate/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('certificate/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

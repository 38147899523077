import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  title = 'acara';
  navSidebarClass: boolean = true;
  hamburgerClass: boolean = false;

    constructor(
      public sharedService: SharedService,
      private router: Router
    ) {
      
    }

  ngOnInit(): void {
    if(localStorage.getItem('tokenData') == null) {
      this.router.navigate(['/']);
    }
  }

}

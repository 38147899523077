<div class="card-header flex-wrap border-0 pb-0">
	<h4 class="fs-20">Best Selling</h4>
	<mat-form-field class="dashboard-select" appearance="fill">
	  <mat-select [(value)]="selected">
		<mat-option *ngFor="let timeVal of timePeriod" [value]="timeVal.value">
		  {{timeVal.viewValue}}
		</mat-option>
	  </mat-select>
	</mat-form-field>
	
</div>
<div class="card-body">
	<!-- <div id="donutChart"></div> -->
	
	<div class="chart-wrapper">
		<!-- <canvas baseChart 
		[data]="doughnutChartData"
		[labels]="doughnutChartLabels"
		[chartType]="doughnutChartType">
	  </canvas> -->
		<!-- <div [hidden]="!chart">
		  <canvas id="canvas"></canvas>
		</div> -->
		<apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [legend]="chartOptions.legend"
    [plotOptions]="chartOptions.plotOptions"
    [colors]="chartOptions.colors"
    [stroke]="chartOptions.stroke"
    [dataLabels]="chartOptions.dataLabels"
  ></apx-chart>
	</div>
	
	<div class="d-flex justify-content-between mt-4">
		<div class="pr-2">
			<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="20" height="8" rx="4" fill="#214BB8"/>
			</svg>
			<h4 class="fs-18 text-black mb-1 font-w600">21,512</h4>
			<span class="fs-14">Ticket Left</span>
		</div>
		<div class="pr-2">
			<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="20" height="8" rx="4" fill="#FE634E"/>
			</svg>
			<h4 class="fs-18 text-black mb-1 font-w600">45,612</h4>
			<span class="fs-14">Ticket Sold</span>
		</div>
		<div class="">
			<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="20" height="8" rx="4" fill="#45ADDA"/>
			</svg>
			<h4 class="fs-18 text-black mb-1 font-w600">275</h4>
			<span class="fs-14">Event Held</span>
		</div>
	</div>
</div>
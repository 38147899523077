import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SubscriptionPackageService } from './subscription-package.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-subscription-package',
  templateUrl: './subscription-package.component.html',
  styleUrls: ['./subscription-package.component.css']
})
export class SubscriptionPackageComponent implements OnInit {

  Form: FormGroup;
  FormPackage: FormGroup;
  
  tSecond;
  tMinutes;
  packageName;
  totalSecond;
  price;
  validity;
  validityType;
  totalSecondExpire;
  validityDayExpire;
  // referralType;
  // referral;
  connection;
  coin;
  storage;
  id;
  index;
  subscriptionPackageList = [];
  dropDownData = [];
  // referralData = [];

  name;
  value;
  packageData = [];
  packageDetailData = [];
  FDBtn = false;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private subscriptionPackageService: SubscriptionPackageService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();
    this.formPackage();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      packageName: ['-', Validators.required],
      totalSecond: ['-', Validators.required],
      price: ['-', Validators.required],
      validity: ['-', Validators.required],
      validityType: ['-', Validators.required],
      totalSecondExpire: ['-', Validators.required],
      validityDayExpire: ['-', Validators.required],
      connection: ['-', Validators.required],
      coin: ['-', Validators.required],
      storage: ['-', Validators.required],
      // referralType: ['-', Validators.required],
      // referral: ['-', Validators.required]
    });
  }

  formPackage() {
    this.FormPackage = this.formBuilder.group({
      name: ['-', Validators.required],
      value: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData = [
      // {
      //   id: "-",
      //   text: "-- Select --",
      //   disabled: true,
      //   selected: true
      // },
      {
        id: "Days",
        text: "Days"
      },
      {
        id: "Months",
        text: "Months"
      },
      {
        id: "Years",
        text: "Years"
      }
    ];

    this.packageData = [
      {
        id: "-",
        text: "-- Select --",
        disabled: true,
        selected: true
      }
      // {
      //   id: "Days",
      //   text: "Days"
      // },
      // {
      //   id: "Months",
      //   text: "Months"
      // },
      // {
      //   id: "Years",
      //   text: "Years"
      // }
    ];

    this.subscriptionPackageService.packageDetail().then(data => {
      if(data.length > 0) {
        data.forEach(element => {
          this.packageData.push({
            id: element.packageName,
            text: element.packageName
          });
        });
      }
    });

    // this.referralData = [
    //   {
    //     id: "Coin",
    //     text: "Coin"
    //   },
    //   {
    //     id: "Item",
    //     text: "Item"
    //   },
    // ];
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.subscriptionPackageService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.subscriptionPackageService.pagination(data).then(data => this.subscriptionPackageList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.tSecond = 0;
    this.tMinutes = 0;
    this.validityType = 'Days';
    // this.referralType = 'Coin';
    this.packageDetailData = [];
    this.FDCancel()
  }

  onKeypressEvent(event: any){
    this.tMinutes = parseInt(event.target.value) * 60;
    this.tSecond = this.tMinutes * 60;
  }

  FDCancel() {
    this.FormPackage.reset();
    this.FDBtn = false;
    this.index = -1;
    this.name = '-';
  }

  addFD() {
    if(this.FDBtn == true && this.index != undefined && this.index >= 0) {
      this.packageDetailData[this.index] = this.FormPackage.value;
    }
    else {
      this.packageDetailData.push(this.FormPackage.value);
    }
    this.FormPackage.reset();
    this.name = '-';
    this.FDBtn = false;
  }

  actionFD(data, type, i) {
    if(type == 'edit') {
      this.index = i;
      this.FDBtn = true;
      this.name = data.name;
      this.value = data.value;
    }
    else {
      this.packageDetailData.splice(i, 1);
    }
  }

  add() {
    if(this.packageDetailData.length > 0) {
      this.Form.value.packageDetails = this.packageDetailData;
      this.Form.value.totalSecond = this.Form.value.totalSecond * 60 * 60;
      this.Form.value.totalSecondExpire = this.Form.value.totalSecondExpire * 60 * 60;
      this.addbtn = true;
      this.subscriptionPackageService.add(this.Form.value).then(data => {
        if(data.flag == true) {;
          this.subscriptionPackageList.unshift(data.data);
          this.total = (parseInt(this.total) + 1).toString();
          this.toastr.successToastr(data.message, 'Subscription Package Added!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.addbtn = false; 
          this.modalService.dismissAll();
        }
      });
    }
    else {
      this.toastr.errorToastr('Please select package detail!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.packageName = data.packageName;
    this.totalSecond = data.totalSecond / 60 / 60;
    this.tMinutes = data.totalSecond / 60;
    this.tSecond = data.totalSecond;
    this.price = data.price;
    this.validity = data.validity;
    this.validityType = data.validityType;
    this.totalSecondExpire = data.totalSecondExpire / 60 / 60;
    this.validityDayExpire = data.validityDayExpire;
    this.connection = data.connection;
    this.coin = data.coin;
    this.storage = data.storage;
    this.packageDetailData = data.packageDetails != undefined ? data.packageDetails : [];
    // this.referralType = data.referralType;
    // this.referral = data.referral;
  }

  update() {
    if(this.packageDetailData.length > 0) {
      this.Form.value.packageDetails = this.packageDetailData;
      this.Form.value.totalSecond = this.Form.value.totalSecond * 60 * 60;
      this.Form.value.totalSecondExpire = this.Form.value.totalSecondExpire * 60 * 60;
      this.updatebtn = true;
      this.subscriptionPackageService.update(this.Form.value, this.id).then(data => {
        if(data.flag == true) {
          this.subscriptionPackageList[this.index] = data.data;
          this.toastr.successToastr(data.message, 'Subscription Package Updated!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.updatebtn = false;
        }
      });
    }
    else {
      this.toastr.errorToastr('Please select package detail!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  delete() {
    this.deletebtn = true;
    this.subscriptionPackageService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.subscriptionPackageList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Subscription Package Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.subscriptionPackageService.active(json, id).then(data => {
      if(data.flag == true) {
        this.subscriptionPackageList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
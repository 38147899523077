<div class="card-body">
	<div class="d-flex justify-content-between align-items-center">
		<div>
			<p class="fs-14 mb-1">Event Held</p>
			<span class="fs-35 text-black font-w600">856
 			</span>
		</div>
	  <apx-chart class="event-chart"
		[series]="chartOptions.series"
		[chart]="chartOptions.chart"
		[plotOptions]="chartOptions.plotOptions"
		[labels]="chartOptions.labels"
		[legend]="chartOptions.legend"
		[colors]="chartOptions.colors"
	  ></apx-chart>
	</div>
</div>
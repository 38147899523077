<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Blog</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Type</th>
                            <th scope="col" class="text-left px-1 py-2">Title</th>
                            <th scope="col" class="text-left px-1 py-2">SEO Title</th>
                            <th scope="col" class="text-left px-1 py-2">Image</th>
                            <th scope="col" class="text-left px-1 py-2">Description</th>
                            <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th>
                            <th scope="col" class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let blog of blogList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ blog.type }}</td>
                            <td>{{ blog.title }}</td>
                            <td>{{ blog.seoTitle }}</td>
                            <td><img [src]="server + blog.image.path" height="100px" width="150px"></td>
                            <td>{{ blog.description }}</td>
                            <td>{{ blog.createAt | date: 'medium': 'IST' }}</td>
                            <td>{{ blog.admin[0].firstName }} {{ blog.admin[0].lastName }}</td>
                            <td>{{ blog.updateAt | date: 'medium':'IST' }}</td>
                            <td class="text-center">
                                <button [hidden]="blog.active" (click)="active(blog.active, blog._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!blog.active" (click)="active(blog.active, blog._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(blog, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(blog, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Blog</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Type</label>
                    <ng-select2 
                        [(ngModel)]="type"
                        formControlName="type"
                        [data]="typeData"
                        [placeholder]="'Please select a type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Title</label>
                    <input type="text" class="form-control" formControlName="title" placeholder="Title">
                </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                  <label>SEO Title</label>
                  <input type="text" class="form-control" formControlName="seoTitle" placeholder="SEO Title">
              </div>
          </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="formFileMultiple" class="form-label">Image</label>
                    <input class="form-control" type="file" (change)="fileChangeEvent($event)" id="formFileMultiple"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Description</label>
                    <ckeditor
                        formControlName="description"
                        [config]="ckeConfig" 
                        debounce="500">
                    </ckeditor>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Meta Keyword</label>
                    <textarea class="form-control" id="Textarea2" rows="3" formControlName="metaKeyword" placeholder="Meta Keyword"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Meta Description</label>
                    <textarea class="form-control" id="Textarea3" rows="10" formControlName="metaDescription" placeholder="Meta Description"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Blog</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Type</label>
                    <ng-select2 
                        [(ngModel)]="type"
                        formControlName="type"
                        [data]="typeData"
                        [placeholder]="'Please select a type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Title</label>
                    <input type="text" class="form-control" formControlName="title" [(ngModel)]="title" placeholder="Title">
                </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                  <label>SEO Title</label>
                  <input type="text" class="form-control" formControlName="seoTitle" [(ngModel)]="seoTitle" placeholder="SEO Title">
              </div>
          </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="formFileMultiple" class="form-label">Image</label>
                    <input class="form-control" type="file" (change)="fileChangeEvent($event)" id="formFileMultiple"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Description</label>
                    <ckeditor
                        formControlName="description"
                        [(ngModel)]="description"
                        [config]="ckeConfig" 
                        debounce="500">
                    </ckeditor>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Meta Keyword</label>
                    <textarea class="form-control" id="Textarea2" rows="3" formControlName="metaKeyword" [(ngModel)]="metaKeyword" placeholder="Meta Keyword"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Meta Description</label>
                    <textarea class="form-control" id="Textarea3" rows="10" formControlName="metaDescription" [(ngModel)]="metaDescription" placeholder="Meta Description"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Blog</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
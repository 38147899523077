import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }
  
  list() {
    return this.httpmanagerService.HttpGet('beneficiary', "").then(data => {
      return data;
    });
  }

  add(data) {
    return this.httpmanagerService.HttpPost('beneficiary', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

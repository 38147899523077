import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class EducatorFinanceService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }
  
  total(data) {
    return this.httpmanagerService.HttpPost('educatorfinance/total', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('educatorfinance/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  totalSearch(data, id) {
    return this.httpmanagerService.HttpPost('educatorfinance/totalsearch', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  paginationSearch(data, id) {
    return this.httpmanagerService.HttpPost('educatorfinance/search', JSON.stringify(data), id).then(data => {
      return data;
    });
  }
}

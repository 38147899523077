<!--**********************************
            Sidebar start
        ***********************************-->
        <div class="deznav">
            <div class="deznav-scroll" [perfectScrollbar]>
				<!-- <a href="javascript:void(0)" class="add-menu-sidebar" data-toggle="modal" data-target="#addOrderModalside" (click)="open(content)">+ New Event</a> -->
				<ul class="metismenu"  metis-menu>
                    <li><a (click)="setDocTitle( 'Dashboard' )" [routerLink]="['/admin/index']" class="ai-icon" aria-expanded="false">
                            <i class="flaticon-381-networking"></i>
                            <span class="nav-text">Dashboard</span>
                        </a>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                            <i class="flaticon-381-heart"></i>
                            <span class="nav-text">Master</span>
                        </a>
                        <ul aria-expanded="false">
                            <li><a (click)="setDocTitle( 'Staff' )" [routerLink]="['/admin/admin-user']">Staff</a></li>
                            <li><a (click)="setDocTitle( 'Department' )" [routerLink]="['/admin/department']">Department</a></li>
                            <li><a (click)="setDocTitle( 'Subscription Package' )" [routerLink]="['/admin/subscription-package']">Subscription Package</a></li>
                            <li><a (click)="setDocTitle( 'Educator Package' )" [routerLink]="['/admin/educator-package']">Educator Package</a></li>
                            <li><a class="has-arrow" href="javascript:void()" aria-expanded="false">Category</a>
                                <ul aria-expanded="false">
                                    <li><a (click)="setDocTitle( 'Main Category' )" [routerLink]="['/admin/category']">Main Category</a></li>
                                    <li><a (click)="setDocTitle( 'Sub Category' )" [routerLink]="['/admin/sub-category']">Sub Category</a></li>
                                </ul>
                            </li>
                            <li><a (click)="setDocTitle( 'User Type' )" [routerLink]="['/admin/user-type']">User Type</a></li>
                            <li><a (click)="setDocTitle( 'Badge Level' )" [routerLink]="['/admin/badge-level']">Badge Level</a></li>
                            <li><a (click)="setDocTitle( 'Certificate' )" [routerLink]="['/admin/certificate']">Certificate</a></li>
                            <li><a (click)="setDocTitle( 'FAQ' )" [routerLink]="['/admin/faq']">FAQ</a></li>
                            <li><a (click)="setDocTitle( 'Tutorial' )" [routerLink]="['/admin/tutorial']">Tutorial</a></li>
                            <li><a (click)="setDocTitle( 'Blog' )" [routerLink]="['/admin/blog']">Blog</a></li>
                            <li><a (click)="setDocTitle( 'Enquire' )" [routerLink]="['/admin/enquire']">Enquire</a></li>
                            <li><a (click)="setDocTitle( 'Referral Friend' )" [routerLink]="['/admin/referral-friend']">Referral Friend</a></li>
                            <li><a (click)="setDocTitle( 'Classes' )" [routerLink]="['/admin/classes']">Classes</a></li>
                            <li><a (click)="setDocTitle( 'Booking' )" [routerLink]="['/admin/booking']">Booking</a></li>
                            <li><a (click)="setDocTitle( 'Category Classes' )" [routerLink]="['/admin/category-classes']">Category Classes</a></li>
                        </ul>
                    </li>
                    <li><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="fa fa-bank"></i>
                        <span class="nav-text">Beneficiary</span>
                    </a>
                    <ul aria-expanded="false">
                        <li [hidden]="authorization"><a (click)="setDocTitle( 'Add Beneficiary' )" [routerLink]="['/admin/beneficiary']">Add Beneficiary</a></li>
                        <li><a (click)="setDocTitle( 'Beneficiary List' )" [routerLink]="['/admin/beneficiary-list']">Beneficiary List</a></li>
                        <!-- <li [hidden]="authorization"><a (click)="setDocTitle( 'Add Beneficiary Parent' )" [routerLink]="['/admin/beneficiary-parent']">Add Beneficiary Parent</a></li>
                        <li><a (click)="setDocTitle( 'Beneficiary Parent List' )" [routerLink]="['/admin/beneficiary-list-parent']">Beneficiary Parent List</a></li> -->
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="fa fa-balance-scale"></i>
                        <span class="nav-text">Finance</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a (click)="setDocTitle( 'Educator Finance' )" [routerLink]="['/admin/educator-finance']">Educator</a></li>
                        <li><a (click)="setDocTitle( 'E-Learner Refund' )" [routerLink]="['/admin/e-learner-refund']">E-Learner Refund</a></li>
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                      <i class="fa fa-handshake-o"></i>
                      <span class="nav-text">Refer</span>
                  </a>
                  <ul aria-expanded="false">
                    <li><a (click)="setDocTitle( 'Refer History' )" [routerLink]="['/admin/refer-history']">Refer History</a></li>
                    <li><a (click)="setDocTitle( 'Refer Friend History' )" [routerLink]="['/admin/refer-friend-history']">Refer Friend History</a></li>
                    <li><a (click)="setDocTitle( 'Educator' )" [routerLink]="['/admin/educator-withdrawal-refer']">Educator Withdrawal Request</a></li>
                    <li><a (click)="setDocTitle( 'Parent' )" [routerLink]="['/admin/parent-withdrawal-refer']">Parent Withdrawal Request</a></li>
                    <li><a (click)="setDocTitle( 'School' )" [routerLink]="['/admin/school-withdrawal-refer']">School Withdrawal Request</a></li>
                  </ul>
                  </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="fa fa-support"></i>
                        <span class="nav-text">Help Desk</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a (click)="setDocTitle( 'E-Learner Help Desk' )" [routerLink]="['/admin/child-help-desk']">E-Learner</a></li>
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="fa fa-users"></i>
                        <span class="nav-text">User</span>
                    </a>
                    <ul aria-expanded="false">
                        <!-- <li><a (click)="setDocTitle( 'Child' )" [routerLink]="['/admin/child-user']">Child</a></li>
                        <li><a (click)="setDocTitle( 'Parent' )" [routerLink]="['/admin/parent-user']">Parent</a></li> -->
                        <li><a (click)="setDocTitle( 'Educator' )" [routerLink]="['/admin/educator-user']">Educator</a></li>
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="fa fa-graduation-cap"></i>
                        <span class="nav-text">School</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a (click)="setDocTitle( 'School' )" [routerLink]="['/admin/school']">School</a></li>
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="fa fa-quote-left"></i>
                        <span class="nav-text">Testimonial</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a (click)="setDocTitle( 'Testimonial Parent' )" [routerLink]="['/admin/testimonial-parent']">Parent</a></li>
                        <li><a (click)="setDocTitle( 'Testimonial Educator' )" [routerLink]="['/admin/testimonial-educator']">Educator</a></li>
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="flaticon-381-internet"></i>
                        <span class="nav-text">Avatar</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a (click)="setDocTitle( 'Bitmoji Category' )" [routerLink]="['/admin/bitmoji-category']">Bitmoji Category</a></li>
                        <li><a (click)="setDocTitle( 'Bitmoji Access' )" [routerLink]="['/admin/bitmoji-access']">Bitmoji Access</a></li>
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
							<i class="flaticon-381-controls-3"></i>
							<span class="nav-text">Manage Badge</span>
						</a>
                        <ul aria-expanded="false">
                            <li><a (click)="setDocTitle( 'E-Learner' )" [routerLink]="['/admin/e-learner']">E-Learner</a></li>
                            <li><a (click)="setDocTitle( 'Educator' )" [routerLink]="['/admin/educator']">Educator</a></li>
                        </ul>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                        <i class="flaticon-381-television"></i>
                        <span class="nav-text">Slider</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a (click)="setDocTitle( 'E-Learner Slider' )" [routerLink]="['/admin/e-learner-slider']">E-Learner</a></li>
                    </ul>
                    </li>
                    <li [hidden]="authorization"><a (click)="setDocTitle( 'Offer Banner' )" [routerLink]="['/admin/offer-banner']" class="ai-icon" aria-expanded="false">
                        <i class="flaticon-381-layer-1"></i>
                        <span class="nav-text">Offer Banner</span>
                        </a>
                    </li>
                    <li [hidden]="authorization"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                            <i class="flaticon-381-settings-2"></i>
                            <span class="nav-text">Setting</span>
                        </a>
                        <ul aria-expanded="false">
                            <li><a (click)="setDocTitle( 'Chatbot Questionnaire' )" [routerLink]="['/admin/chatbot-questionnaire']">Chatbot</a></li>
                            <li><a (click)="setDocTitle( 'Gift Coin' )" [routerLink]="['/admin/giftcoin']">Gift Coin</a></li>
                            <li><a (click)="setDocTitle( 'Offer' )" [routerLink]="['/admin/offer']">Offer</a></li>
                            <li><a (click)="setDocTitle( 'Offer Coin' )" [routerLink]="['/admin/offer-coin']">Offer Coin</a></li>
                            <li><a (click)="setDocTitle( 'Tax Rates' )" [routerLink]="['/admin/tax-rates']">Tax Rates</a></li>
                            <li><a (click)="setDocTitle( 'Class convenience fee' )" [routerLink]="['/admin/class_convenience_fee']">Class convenience fee</a></li>
                            <li><a (click)="setDocTitle( 'Language' )" [routerLink]="['/admin/language']">Language</a></li>
                            <li><a (click)="setDocTitle( 'Preferences' )" [routerLink]="['/admin/preferences']">Preferences</a></li>
                            <li><a (click)="setDocTitle( 'Email Configuration' )" [routerLink]="['/admin/email-configuration']">Email Configuration</a></li>
                            <li><a (click)="setDocTitle( 'Email Template' )" [routerLink]="['/admin/email-template']">Email Template</a></li>
                            <li><a (click)="setDocTitle( 'Notification' )" [routerLink]="['/admin/notification']">Notification</a></li>
                            <li><a (click)="setDocTitle( 'WhatsApp' )" [routerLink]="['/admin/whatsapp']">WhatsApp</a></li>
                        </ul>
                    </li>
                    <!-- <li><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
							<i class="flaticon-381-layer-1"></i>
							<span class="nav-text">Pages</span>
						</a>
                        <ul aria-expanded="false">
                            <li><a [routerLink]="['/page-register']">Register</a></li>
                            <li><a [routerLink]="['/page-login']">Login</a></li>
                            <li><a class="has-arrow" href="javascript:void()" aria-expanded="false">Error</a>
                                <ul aria-expanded="false">
                                    <li><a [routerLink]="['/page-error-400']">Error 400</a></li>
                                    <li><a [routerLink]="['/page-error-403']">Error 403</a></li>
                                    <li><a [routerLink]="['/page-error-404']">Error 404</a></li>
                                    <li><a [routerLink]="['/page-error-500']">Error 500</a></li>
                                    <li><a [routerLink]="['/page-error-503']">Error 503</a></li>
                                </ul>
                            </li>
                            <li><a [routerLink]="['/page-lock-screen']">Lock Screen</a></li>
                        </ul>
                    </li> -->
                </ul>
				<!-- <div class="meeko-board"> -->
                    <!-- <img src="../../../assets/images/meeko-board.png"> -->
					<!-- <p><strong>Meeko Dashboard</strong> © 2021 All Rights Reserved</p>
					<p>Made with <span [ngClass]="{'': toggleIcon, 'heart-blast': !toggleIcon}" class="heart" (click)="toggleLoveIcon()"></span> by Prime Technosoft
                    </p> -->
				<!-- </div> -->
			</div>
        </div>
        <!--**********************************
            Sidebar end
        ***********************************-->


		<ng-template #content let-modal>
		  <div class="modal-header">
			<h4 class="modal-title" id="modal-basic-title">Add Event</h4>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			  <span aria-hidden="true">&times;</span>
			</button>
		  </div>
		  <div class="modal-body">
			<form>
			<div class="form-group">
					<label class="text-black font-w500">Event Name</label>
					<input type="text" class="form-control">
			</div>
			<div class="form-group">
				<label class="text-black font-w500">Event Date</label>
				<input type="date" class="form-control" ngbDatepicker #dp="ngbDatepicker">
			</div>
			<div class="form-group">
				<label class="text-black font-w500">Description</label>
				<input type="text" class="form-control">
			</div>
			<div class="form-group">
				<button type="button" class="btn btn-primary">Create</button>
			</div>
			</form>
		  </div>

		</ng-template>

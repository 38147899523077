import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { LoginService } from './login.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  AuthForm: FormGroup;
  btn = false;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: LoginService,
    private router: Router,
    private toastr: ToastrManager,
    private titleService: Title
  ) { 
    this.formAuth();
  }

  formAuth() {
    this.AuthForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  signIn() {
    this.btn = true;
    this.authenticationService.signin(this.AuthForm.value).then(data => {
      if(data.flag) {
        this.toastr.successToastr(data.message, 'Login', { animate: 'slideFromRight', showCloseButton: true }); 
        this.storeUserDataAdmin(data.data);
        this.router.navigate(['/admin/index']);
        this.titleService.setTitle('Dashboard' + ' - Meeko');
      }
      else {
        this.btn = false;
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.AuthForm.reset();
      }
    });
  }

  storeUserDataAdmin(data) {
    localStorage.removeItem('tokenData'); 
    localStorage.removeItem('role'); 
    localStorage.removeItem('department'); 
    localStorage.setItem('tokenData', data.token);
    localStorage.setItem('role', data.role);
    localStorage.setItem('department', data.department);
  }

  loggedIn() {
    return localStorage.getItem('tokenData');
  }
}

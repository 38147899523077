import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FAQService } from './faq.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {

  Form: FormGroup;

  number;
  question;
  answer;
  type;
  id;
  index;
  typeData = [];
  faqList = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  // ckeConfig: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private faqService: FAQService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      number:  ['-', Validators.required],
      question: ['-', Validators.required],
      answer: ['-', Validators.required],
      type: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();

    // this.ckeConfig = {
    //   extraPlugins:
    //     "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
    //     "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
    //     "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
    //     "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
    //     "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
    //     "indentblock,indentlist,justify,link,list,liststyle,magicline," +
    //     "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
    //     "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
    //     "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
    //     "tabletools,templates,toolbar,undo,wsc,wysiwygarea"
    // };

    this.typeData.push({
      id: "-",
      text: "-- Select Type --",
      disabled: true,
      selected: true
    },
    {
      id: "E-Learner",
      text: "E-Learner",
    },
    {
      id: "Parent",
      text: "Parent",
    },
    {
      id: "Educator",
      text: "Educator",
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.faqService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.faqService.pagination(data).then(data => this.faqList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    if(this.typeData[0] != undefined && this.typeData[0].id != '-') {
      this.typeData.unshift({
        id: "-",
        text: "-- Select Type --",
        disabled: true,
        selected: true
      });
    }
  }

  add() {
    this.addbtn = true;
    this.faqService.add(this.Form.value).then(data => {
      if(data.flag == true) {;
        this.faqList.unshift(data.data);
        this.total = (parseInt(this.total) + 1).toString();
        this.toastr.successToastr(data.message, 'FAQ Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false; 
        this.modalService.dismissAll();
      }
    });
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.number = data.number;
    this.question = data.question;
    this.answer = data.answer;
    this.type = data.type;
    if(this.typeData[0] != undefined && this.typeData[0].id == '-') {
      this.typeData.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    this.faqService.update(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.faqList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'FAQ Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.faqService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.faqList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'FAQ Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.faqService.active(json, id).then(data => {
      if(data.flag == true) {
        this.faqList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
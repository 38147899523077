import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ClassesService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }
  
  total(data) {
    return this.httpmanagerService.HttpPost('classesinfo/total', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('classesinfo/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  inReview(id) {
    return this.httpmanagerService.HttpGet('classesinfo/inreview', id).then(data => {
      return data;
    });
  }

  reject(data, id) {
    return this.httpmanagerService.HttpPost('classesinfo/reject', JSON.stringify(data), id).then(data => {
      return data;
    });
  }
}

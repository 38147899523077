import { Component, OnInit, Injectable  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { OfferService } from './offer.service';
import { UserTypeService } from '../user-type/user-type.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ApiConstant } from '../api.constant';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
        `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
        '';
  }
}

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class OfferComponent implements OnInit {

  Form: FormGroup;

  userTypeId;
  name;
  offerType;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  type;
  discount;
  usesPerCoupon;
  couponCode;
  id;
  index;
  offerList = [];
  userTypeList = [];
  dropDownData = [];
  dropDownData1 = [];
  dropDownData2 = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private offerService: OfferService,
    private userTypeService: UserTypeService,
    private toastr: ToastrManager,
    private calendar: NgbCalendar,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      userTypeId: ['-', Validators.required],
      name: ['-', Validators.required], 
      offerType: ['-', Validators.required], 
      startDate: ['-', Validators.required], 
      endDate: ['-', Validators.required], 
      type: ['-', Validators.required],
      discount: ['-', Validators.required], 
      usesPerCoupon: ['-', Validators.required], 
      couponCode: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData.push({
      id: "-",
      text: "-- Select User Type --",
      disabled: true,
      selected: true
    })
    this.userTypeService.list().then(data => { 
      this.userTypeList = data;
      for (let index = 0; index < this.userTypeList.length; index++) {
        const element = this.userTypeList[index];
        this.dropDownData.push({
          id: element._id,
          text: element.name
        });
      }
    });
    this.dropDownData1 = [
      {
        id: "-",
        text: "-- Select Offer Type --",
        disabled: true,
        selected: true
      },
      {
        id: "Subscription",
        text: "Subscription"
      },
      {
        id: "Classes",
        text: "Classes"
      }
    ];
    this.dropDownData2 = [
      {
        id: "-",
        text: "-- Select Type --",
        disabled: true,
        selected: true
      },
      {
        id: "Fix Amount",
        text: "Fix Amount"
      },
      {
        id: "Percentage",
        text: "Percentage"
      }
    ];
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.offerService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.offerService.pagination(data).then(data => this.offerList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.userTypeId = null;
    this.offerType = null;
    this.type = null;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id != '-') {
      this.dropDownData.unshift({
        id: "-",
        text: "-- Select User Type --",
        disabled: true,
        selected: true
      });
    }
    if(this.dropDownData1[0] != undefined && this.dropDownData1[0].id != '-') {
      this.dropDownData1.unshift({
        id: "-",
        text: "-- Select Offer Type --",
        disabled: true,
        selected: true
      });
    }
    if(this.dropDownData2[0] != undefined && this.dropDownData2[0].id != '-') {
      this.dropDownData2.unshift({
        id: "-",
        text: "-- Select Type --",
        disabled: true,
        selected: true
      });
    }
    
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day,
      month: month,
      year: year
    }
  }

  add() {
    this.Form.value.startDate = this.Form.value.startDate.year + '-' + this.Form.value.startDate.month + '-' + this.Form.value.startDate.day;
    this.Form.value.endDate = this.Form.value.endDate.year + '-' + this.Form.value.endDate.month + '-' + this.Form.value.endDate.day;
    this.addbtn = true;
    this.offerService.add(this.Form.value).then(data => {
      if(data.flag == true) {;
        this.offerList.unshift(data.data);
        this.total = (parseInt(this.total) + 1).toString();
        this.toastr.successToastr(data.message, 'Offer Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false; 
        this.modalService.dismissAll();
      }
    });
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.userTypeId = data.userTypeId;
    this.name = data.name;
    this.offerType = data.offerType;
    var startDateObj = new Date(data.startDate);
    var month = startDateObj.getMonth() + 1; //months from 1-12
    var day = startDateObj.getDate();
    var year = startDateObj.getFullYear();
    var endDateObj = new Date(data.endDate);
    var month1 = endDateObj.getMonth() + 1; //months from 1-12
    var day1 = endDateObj.getDate();
    var year1 = endDateObj.getFullYear();
    this.startDate = {
      day: day,
      month: month,
      year: year
    }
    this.endDate = {
      day: day1,
      month: month1,
      year: year1
    }
    this.type = data.type;
    this.discount = data.discount;
    this.usesPerCoupon = data.usesPerCoupon;
    this.couponCode = data.couponCode;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id == '-') {
      this.dropDownData.splice(0, 1);
    }
    if(this.dropDownData1[0] != undefined && this.dropDownData1[0].id == '-') {
      this.dropDownData1.splice(0, 1);
    }
    if(this.dropDownData2[0] != undefined && this.dropDownData2[0].id == '-') {
      this.dropDownData2.splice(0, 1);
    }
  }

  update() {
    this.Form.value.startDate = this.Form.value.startDate.year + '-' + this.Form.value.startDate.month + '-' + this.Form.value.startDate.day;
    this.Form.value.endDate = this.Form.value.endDate.year + '-' + this.Form.value.endDate.month + '-' + this.Form.value.endDate.day;
    this.updatebtn = true;
    this.offerService.update(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.offerList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Offer Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.offerService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.offerList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Offer Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.offerService.active(json, id).then(data => {
      if(data.flag == true) {
        this.offerList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class EnquireService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }
  
  total(data) {
    return this.httpmanagerService.HttpPost('enquire/total', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('enquire/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Offer</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">User Type</th>
                            <th scope="col" class="text-left px-1 py-2">Name</th>
                            <th scope="col" class="text-left px-1 py-2">Offer Type</th>
                            <th scope="col" class="text-left px-1 py-2">Start Date</th>
                            <th scope="col" class="text-left px-1 py-2">End Date</th>
                            <th scope="col" class="text-left px-1 py-2">Type</th>
                            <th scope="col" class="text-left px-1 py-2">Discount</th>
                            <th scope="col" class="text-left px-1 py-2">Coupon Code</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col" class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let offer of offerList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ offer.usertype[0].name }}</td>
                            <td>{{ offer.name }}</td>
                            <td>{{ offer.offerType }}</td>
                            <td>{{ offer.startDate | date: 'medium':'IST' }}</td>
                            <td>{{ offer.endDate | date: 'medium':'IST' }}</td>
                            <td>{{ offer.type }}</td>
                            <td>{{ offer.discount | number : '1.2-2' }}</td>
                            <td>{{ offer.couponCode }}</td>
                            <!-- <td>{{ offer.createAt | date }}</td>
                            <td>{{ offer.admin[0].firstName }} {{ offer.admin[0].lastName }}</td>
                            <td>{{ offer.updateAt | date }}</td> -->
                            <td class="text-center">
                                <button [hidden]="offer.active" (click)="active(offer.active, offer._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!offer.active" (click)="active(offer.active, offer._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(offer, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(offer, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Offer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>User Type</label>
                    <!-- <select class="form-control" formControlName="userTypeId" [(ngModel)]="userTypeId" id="inputUserType">
                        <option *ngFor="let userType of userTypeList" value={{userType._id}}>{{userType.name}}</option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="userTypeId"
                        formControlName="userTypeId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a user type...'">		
                    </ng-select2>
                </div>
                <div class="form-group col-md-4">
                    <label>Name</label>
                    <input type="text" class="form-control" formControlName="name" placeholder="Name">
                </div>
                <div class="form-group col-md-4">
                    <label>Offer Type</label>
                    <!-- <select class="form-control" formControlName="offerType" [(ngModel)]="offerType" id="inputOfferType">
                        <option value="Subscription">Subscription</option>
                        <option value="Classes">Classes</option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="offerType"
                        formControlName="offerType"
                        [data]="dropDownData1"
                        [placeholder]="'Please select a offer type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>Start Date</label>
                    <!-- <input type="date" class="form-control" formControlName="startDate" placeholder="DD/MM/YYYY"> -->
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" formControlName="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>End Date</label>
                    <!-- <input type="date" class="form-control" formControlName="endDate" placeholder="DD/MM/YYYY"> -->
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" formControlName="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Type</label>
                    <!-- <select class="form-control" formControlName="type" [(ngModel)]="type" id="inputType">
                        <option value="Fix Amount">Fix Amount</option>
                        <option value="Percentage">Percentage </option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="type"
                        formControlName="type"
                        [data]="dropDownData2"
                        [placeholder]="'Please select a type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>Discount</label>
                    <input type="text" class="form-control" formControlName="discount" placeholder="Discount" pattern="\d*">
                </div>
                <div class="form-group col-md-4">
                    <label>Uses Per Coupon</label>
                    <input type="text" class="form-control" formControlName="usesPerCoupon" placeholder="Uses Per Coupon" pattern="\d*">
                </div>
                <div class="form-group col-md-4">
                    <label>Coupon Code</label>
                    <input type="text" class="form-control" formControlName="couponCode" placeholder="Coupon Code">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Offer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>User Type</label>
                    <!-- <select class="form-control" formControlName="userTypeId" [(ngModel)]="userTypeId" id="inputUserType">
                        <option *ngFor="let userType of userTypeList" value={{userType._id}}>{{userType.name}}</option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="userTypeId"
                        formControlName="userTypeId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a user type...'">		
                    </ng-select2>
                </div>
                <div class="form-group col-md-4">
                    <label>Name</label>
                    <input type="text" class="form-control" formControlName="name" [(ngModel)]="name" placeholder="Name">
                </div>
                <div class="form-group col-md-4">
                    <label>Offer Type</label>
                    <!-- <select class="form-control" formControlName="offerType" [(ngModel)]="offerType" id="inputOfferType">
                        <option value="Subscription">Subscription</option>
                        <option value="Classes">Classes</option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="offerType"
                        formControlName="offerType"
                        [data]="dropDownData1"
                        [placeholder]="'Please select a offer type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>Start Date</label>
                    <!-- <input type="date" class="form-control" formControlName="startDate" placeholder="DD/MM/YYYY"> -->
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" formControlName="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>End Date</label>
                    <!-- <input type="date" class="form-control" formControlName="endDate" placeholder="DD/MM/YYYY"> -->
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" formControlName="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Type</label>
                    <!-- <select class="form-control" formControlName="type" [(ngModel)]="type" id="inputType">
                        <option value="Fix Amount">Fix Amount</option>
                        <option value="Percentage">Percentage </option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="type"
                        formControlName="type"
                        [data]="dropDownData2"
                        [placeholder]="'Please select a type...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>Discount</label>
                    <input type="text" class="form-control" formControlName="discount" [(ngModel)]="discount" placeholder="Discount" pattern="\d*">
                </div>
                <div class="form-group col-md-4">
                    <label>Uses Per Coupon</label>
                    <input type="text" class="form-control" formControlName="usesPerCoupon" [(ngModel)]="usesPerCoupon" placeholder="Uses Per Coupon" pattern="\d*">
                </div>
                <div class="form-group col-md-4">
                    <label>Coupon Code</label>
                    <input type="text" class="form-control" formControlName="couponCode" [(ngModel)]="couponCode" placeholder="Coupon Code">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Offer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
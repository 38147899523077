import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class OfferBannerService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data, image) {
    return this.httpmanagerService.HttpPostImage('offerbanner', image, JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  update(data, id, image, oldimage) {
    if(image.length > 0) {
      data.oldimage = oldimage; 
      return this.httpmanagerService.HttpPostImage('offerbanner/update', image, JSON.stringify(data), id).then(data => {
        return data;
      });
    }
    else {
      return this.httpmanagerService.HttpPut('offerbanner', JSON.stringify(data), id).then(data => {
        return data;
      });
    }
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('offerbanner', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('offerbanner/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('offerbanner/total', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('offerbanner/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-graph-best-selling',
  templateUrl: './graph-best-selling.component.html',
  styleUrls: ['./graph-best-selling.component.css']
})
export class GraphBestSellingComponent implements OnInit {

   

  constructor() {}
    chartOptions = {
      series: [25, 30, 45],
      chart: {
        type: "donut"
      },
      legend: {
		show:false,
      },
	  dataLabels: {
			  formatter(val, opts) {
				const name = opts.w.globals.labels[opts.seriesIndex]
				return [ val.toFixed() + '%']
			  },
			  dropShadow: {
				enabled: false
			  },
			  style: {
				fontSize: '15px',
				colors: ["#fff"],
			  }
		},
      colors:['#FE634E','#45ADDA','#214BB8'],
	  plotOptions: {
		 pie: {
			
			donut: {
				 size: '35%',
			}
		 },
	  },
	  stroke:{
        width:10
	  },
		    
      labels: ["Series-1", "Series-2", "Series-3"],
      /* responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ] */
    };
  ngOnInit(): void {
	  

  }
  timePeriod  = [
    {value: 'week-1', viewValue: 'This Week'},
    {value: 'week-2', viewValue: 'Next Week'},
    {value: 'month-1', viewValue: 'This Month'},
    {value: 'month-2', viewValue: 'Next Month'}
  ];
  
  selected = "week-1";
}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpmanagerService : HttpManagerService,
    private http: HttpClient
  ) { }

  userCounter() {
    return this.httpmanagerService.HttpGet('dashboard/usercounter', "").then(data => {
      return data.data;
    });
  }

  eLearner(data) {
    return this.httpmanagerService.HttpPost('dashboard/eLearner', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  complaint(data) {
    return this.httpmanagerService.HttpPost('dashboard/complaint', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  revenue(data) {
    return this.httpmanagerService.HttpPost('dashboard/revenue', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  educator(data) {
    return this.httpmanagerService.HttpPost('dashboard/educator', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  classes() {
    return this.httpmanagerService.HttpGet('dashboard/classdetail', "").then(data => {
      return data.data;
    });
  }

  popularCategory(data) {
    return this.httpmanagerService.HttpPost('dashboard/popularCategory', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  parentReport(data) {
    return this.httpmanagerService.HttpPost('dashboard/parentReport', JSON.stringify(data), "").then(data => {
      return data.data;
    });
  }

  eLearnerReport(data) {
    return this.httpmanagerService.HttpPost('dashboard/eLearnerReport', JSON.stringify(data), "").then(data => {
      return data.data;
    });
  }

  educatorReport(data) {
    return this.httpmanagerService.HttpPost('dashboard/educatorReport', JSON.stringify(data), "").then(data => {
      return data.data;
    });
  }

  liveStreaming() {
    return this.httpmanagerService.HttpGet('dashboard/liveStreaming', "").then(data => {
      return data.data;
    });
  }

  calender() {
    return this.httpmanagerService.HttpGet('dashboard/calender', "").then(data => {
      return data.data;
    });
  }

  grossTransactionAndrevenue() {
    return this.httpmanagerService.HttpGet('dashboard/grosstransactionandrevenue', "").then(data => {
      return data.data;
    });
  }

  grosstransaction(data) {
    return this.httpmanagerService.HttpPost('dashboard/grosstransaction', JSON.stringify(data), "").then(data => {
      return data.data;
    });
  }

  excelFileUpload(image) {
    return this.httpmanagerService.HttpPostImage('parent/bulk', image, "", "").then(data => {
      return data.data;
    });
  }

  state() {
    return this.http.get('../../assets/state.json');
  }
}

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data) {
    return this.httpmanagerService.HttpPost('admin', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('admin', "").then(data => {
      return data.data;
    });
  }

  update(data, id) {
    return this.httpmanagerService.HttpPut('admin', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('admin', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('admin/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('admin/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('admin/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  findOne() {
    return this.httpmanagerService.HttpGet('admin/findone', "").then(data => {
      return data.data;
    });
  }
}

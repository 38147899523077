<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Testimonial Parent</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <!-- <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button> -->
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Profile Pic</th> -->
                            <th scope="col" class="text-left px-1 py-2">Fullname</th>
                            <th scope="col" class="text-left px-1 py-2">Email</th>
                            <th scope="col" class="text-left px-1 py-2">Date</th>
                            <th scope="col" class="text-left px-1 py-2">Rating</th>
                            <th scope="col" class="text-left px-1 py-2">Message</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Status</th> -->
                            <th scope="col" class="text-center py-2">Status</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let testimonialParent of testimonialParentList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <!-- <td><img [src]="server + testimonialParent.profilePic.path" height="50px" width="50px"></td> -->
                            <td>{{ testimonialParent.firstName }} {{ testimonialParent.lastName }}</td>
                            <td>{{ testimonialParent.email }}</td>
                            <td>{{ testimonialParent.testimonial.date | date: 'medium':'IST' }}</td>
                            <td>{{ testimonialParent.testimonial.rating }}</td>
                            <td>{{ testimonialParent.testimonial.message }}</td>
                            <!-- <td>{{ testimonialParent.testimonial.showStatus }}</td> -->
                            <td class="text-center">
                                <button [hidden]="testimonialParent.testimonial.showStatus" (click)="active(testimonialParent.testimonial.showStatus, testimonialParent.testimonial._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!testimonialParent.testimonial.showStatus" (click)="active(testimonialParent.testimonial.showStatus, testimonialParent.testimonial._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Educator Finance</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-3">
                <ng-select2 
                    [(ngModel)]="status"
                    [data]="statusData"
                    (valueChanged)="onType($event)"
                    [placeholder]="'Please select a status...'">		
                </ng-select2>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-3 search-addbtn">
                <div class="input-group search-area d-xl-inline-flex d-none">
                    <input
                    type="text"
                    class="form-control"
                    id="search"
                    name="search" 
                    [(ngModel)]="search"
                    placeholder="Search here..."
                    />
                    <div class="input-group-append">
                    <span class="input-group-text search_btn"
                        ><button type="button" (click)="searchBtn()"
                        ><i class="flaticon-381-search-2"></i></button
                    ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Full Name</th>
                            <th scope="col" class="text-left px-1 py-2">Email</th>
                            <th scope="col" class="text-left px-1 py-2">Mobile</th>
                            <th scope="col" class="text-left px-1 py-2">Class Name</th>
                            <th scope="col" class="text-left px-1 py-2">Batch Name</th>
                            <th scope="col" class="text-left px-1 py-2">Session Name</th>
                            <th scope="col" class="text-left px-1 py-2">Amount</th>
                            <th scope="col" class="text-left px-1 py-2">Status</th>
                            <th scope="col" class="text-left px-1 py-2">Payment Code</th>
                            <th scope="col" class="text-left px-1 py-2">Date</th>
                            <th scope="col" class="text-left px-1 py-2">Release Date</th>
                            <th scope="col" class="text-left px-1 py-2">Payment Date</th>
                            <th scope="col" class="text-left px-1 py-2">Message Id</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let educatorFinance of educatorFinanceList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ educatorFinance.fullName }}</td>
                            <td>{{ educatorFinance.email }}</td>
                            <td>{{ educatorFinance.mobile }}</td>
                            <td>{{ educatorFinance.className }}</td>
                            <td>{{ educatorFinance.batchName }}</td>
                            <td>{{ educatorFinance.sessionName }}</td>
                            <td>{{ educatorFinance.amount }}</td>
                            <td>{{ educatorFinance.status }}</td>
                            <td>{{ educatorFinance.statusCode }}</td>
                            <td>{{ educatorFinance.date | date: 'medium':'IST' }}</td>
                            <td>{{ educatorFinance.expiryDate | date: 'medium':'IST' }}</td>
                            <td>{{ educatorFinance.paymentDate | date: 'medium':'IST' }}</td>
                            <td>{{ educatorFinance.messageId }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->
import { Component, OnInit, Injectable } from '@angular/core';;
import { BeneficiaryService } from './beneficiary.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.css']
})
export class BeneficiaryComponent implements OnInit {

  beneficiaryList = [];
  addbeneficiaryList = [];
  addbtn;

  constructor(
    private beneficiaryService: BeneficiaryService,
    private toastr: ToastrManager,
  ) { 
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  ngOnInit(): void {
    this.beneficiaryService.list().then(data => this.beneficiaryList = data.data);
    this.addbeneficiaryList = [];
    this.addbtn = true;
  }

  addbeneficiary(isSelected, beneficiary) {
    if(isSelected) {
      this.addbtn = false;
      this.addbeneficiaryList.push(beneficiary);
    }
    else {
      var index = this.addbeneficiaryList.findIndex(x => x._id == beneficiary._id);
      if(index != -1) {
        this.addbeneficiaryList.splice(index, 1);
      }
      if(this.addbeneficiaryList.length == 0) {
        this.addbtn = true;
      }
    }
  }

  add() {
    if(this.addbeneficiaryList.length > 0) {
      this.beneficiaryService.add(this.addbeneficiaryList).then(data => {
        for (let i = 0, p = Promise.resolve(); i < this.addbeneficiaryList.length; i++) {
          p = p.then(_ => new Promise(resolve => {
            var index = this.beneficiaryList.findIndex(x => x._id == this.addbeneficiaryList[i]._id);
            if(index != -1) {
              this.beneficiaryList.splice(index, 1);
            }
            resolve();
          }));
        }
        this.addbtn = true; 
        if(data.flag == true) {
          this.toastr.successToastr(data.message, 'Beneficiary Added!', { animate: 'slideFromRight', showCloseButton: true });
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        }
      });
    }
    else {
      this.toastr.errorToastr('At least one record select!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }
}
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CertificateService } from './certificate.service';
import { CategoryService } from '../category/category.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {

  Form: FormGroup;

  categoryId;
  name;
  image;
  fileName;
  id;
  index;
  certificateList = [];
  categoryList = [];
  categoryData = [];
  filesupload = [];
  filesupload1 = [];
  ckeConfig: any;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private certificateService: CertificateService,
    private categoryService: CategoryService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      categoryId: ['-', Validators.required],
      fileName: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.ckeConfig = {
      extraPlugins:
        "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
        "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
        "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
        "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
        "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
        "indentblock,indentlist,justify,link,list,liststyle,magicline," +
        "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
        "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
        "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
        "tabletools,templates,toolbar,undo,wsc,wysiwygarea"
    };

    this.categoryData.push({
      id: "-",
      text: "-- Select Category --",
      disabled: true,
      selected: true
    });
    this.categoryService.list().then(data => {
      this.categoryList = data;
      for (let index = 0; index < this.categoryList.length; index++) {
        const element = this.categoryList[index];
        this.categoryData.push({
          id: element._id,
          text: element.name
        });
      }
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.certificateService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.certificateService.pagination(data).then(data => this.certificateList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.categoryId = null;
    this.fileName = "";
    this.filesupload = [];
    this.filesupload1 = [];
    if(this.categoryData[0] != undefined && this.categoryData[0].id != '-') {
      this.categoryData.unshift({
        id: "-",
        text: "-- Select Category --",
        disabled: true,
        selected: true
      });
    }
  }

  fileChangeEvent(event) {
    this.filesupload = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  fileChangeEvent1(event) {
    this.filesupload1 = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload1.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  add() {
    if(this.filesupload.length > 0) {
      if(this.filesupload1.length > 0) {
        this.addbtn = true;
        this.certificateService.add(this.Form.value, this.filesupload, this.filesupload1).then(data => {
          if(data.flag == true) {;
            this.certificateList.unshift(data.data);
            this.total = (parseInt(this.total) + 1).toString();
            this.toastr.successToastr(data.message, 'Certificate Added!', { animate: 'slideFromRight', showCloseButton: true });
            this.modalService.dismissAll();
            this.clear();
          }
          else {
            this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
            this.addbtn = false; 
            this.modalService.dismissAll();
          }
        });
      }
      else {
        this.toastr.errorToastr('Please Upload Certificate!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    }
    else {
      this.toastr.errorToastr('Please Upload Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.categoryId = data.categoryId;
    this.fileName = data.fileName;
    this.image = data.image;
    this.name = data.certificate.name;
    if(this.categoryData[0] != undefined && this.categoryData[0].id == '-') {
      this.categoryData.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    this.certificateService.update(this.Form.value, this.id, this.filesupload, this.filesupload1, this.image, this.name).then(data => {
      if(data.flag == true) {
        this.certificateList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Certificate Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.certificateService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.certificateList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Certificate Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.certificateService.active(json, id).then(data => {
      if(data.flag == true) {
        this.certificateList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
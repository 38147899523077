<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Preferences</a></li>
                <!-- <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li> -->
            </ol>
        </div>
        <div class="row">
			<div class="col-xl-12 col-xxl-12">
				<div class="card">
					<div class="card-header d-block">
						<h4 class="card-title">Preferences</h4>
					</div>
					<div class="card-body">
						<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                            <li [ngbNavItem]="1">
                              <a ngbNavLink>Convenience Fees</a>
                              <ng-template ngbNavContent>
                                <form [formGroup]="FormCF" (validSubmit)="updateCF()" novalidate>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label>Convenience Fees (%)</label>
                                            <input type="text" class="form-control" formControlName="convenienceFees" [(ngModel)]="convenienceFees" placeholder="Convenience Fees" pattern="^([0-9][0-9]?|)$">
                                        </div>
                                    </div>
                                    <div class="modal-footer col-md-6">
                                        <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                    </div>
                                </form>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                              <a ngbNavLink>Refer Commission Fees</a>
                              <ng-template ngbNavContent>
                                <form [formGroup]="FormRCF" (validSubmit)="updateRCF()" novalidate>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label>Refer Commission Fees (%)</label>
                                            <input type="text" class="form-control" formControlName="referCommissionFees" [(ngModel)]="referCommissionFees" placeholder="Refer Commission Fees" pattern="^([0-9][0-9]?|)$">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label>Minimum Withdrawal Amount</label>
                                            <input type="number" class="form-control" formControlName="referAmount" [(ngModel)]="referAmount" placeholder="Minimum Withdrawal Amount">
                                        </div>
                                    </div>
                                    <div class="modal-footer col-md-6">
                                        <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                    </div>
                                </form>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="3">
                              <a ngbNavLink>Meeko Coin</a>
                              <ng-template ngbNavContent>
                                <form [formGroup]="FormMC" (validSubmit)="updateMC()" novalidate>
                                    <div class="row">
                                        <div class="form-group col-md-3">
                                            <label>Number Of Coins</label>
                                            <input type="text" class="form-control" formControlName="meekoCoin" [(ngModel)]="meekoCoin" placeholder="Number Of Coin" pattern="\d*">
                                        </div>
                                        <div class="form-group middel_class_cust">
                                           <span>=</span>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Actual Amount</label>
                                            <input type="text" class="form-control" formControlName="amount" [(ngModel)]="amount" placeholder="Actual Amount" pattern="\d*">
                                        </div>
                                    </div>
                                    <div class="modal-footer col-md-6">
                                        <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                    </div>
                                </form>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="4">
                              <a ngbNavLink>Refer Friend</a>
                              <ng-template ngbNavContent>
                                <form [formGroup]="FormRF" (validSubmit)="updateRF()" novalidate>
                                    <div class="row">
                                        <div class="form-group col-md-3">
                                            <label>Set Number Of Coin (E-Learner) : Refer By</label>
                                            <input type="text" class="form-control" formControlName="referByELearner" [(ngModel)]="referByELearner" placeholder="Set Number Of Coin" pattern="\d*">
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Set Number Of Coin (E-Learner) : Refer To</label>
                                            <input type="text" class="form-control" formControlName="referToELearner" [(ngModel)]="referToELearner" placeholder="Set Number Of Coin" pattern="\d*">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-3">
                                            <label>Set Number Of Coin (Educator) : Refer By</label>
                                            <input type="text" class="form-control" formControlName="referByEducator" [(ngModel)]="referByEducator" placeholder="Set Number Of Coin" pattern="\d*">
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Set Number Of Coin (Educator) : Refer To</label>
                                            <input type="text" class="form-control" formControlName="referToEducator" [(ngModel)]="referToEducator" placeholder="Set Number Of Coin" pattern="\d*">
                                        </div>
                                    </div>
                                    <div class="modal-footer col-md-6">
                                        <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                    </div>
                                </form>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="5">
                                <a ngbNavLink>Age Group</a>
                                <ng-template ngbNavContent>
                                  <form [formGroup]="FormAG" (validSubmit)="updateAG()" novalidate>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label>ID</label>
                                                <input type="text" class="form-control" formControlName="id" [(ngModel)]="id" placeholder="ID">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-3">
                                                <label>Boy Body</label>
                                                <input type="text" class="form-control" formControlName="boyPath" [(ngModel)]="boyPath" placeholder="Boy Path">
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>Girl Body</label>
                                                <input type="text" class="form-control" formControlName="girlPath" [(ngModel)]="girlPath" placeholder="Girl Path">
                                            </div>
                                            <!-- <div class="form-group col-md-3">
                                                <label>Boy Top</label>
                                                <input type="text" class="form-control" formControlName="boyTopPath" [(ngModel)]="boyTopPath" placeholder="Boy Top Path">
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>Boy Bottom</label>
                                                <input type="text" class="form-control" formControlName="boyBottomPath" [(ngModel)]="boyBottomPath" placeholder="Girl Bottom Path">
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-3">
                                                <label>Minimum Age</label>
                                                <input type="text" class="form-control" formControlName="minimumAge" [(ngModel)]="minimumAge" placeholder="Minimum Age">
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>Maximum Age</label>
                                                <input type="text" class="form-control" formControlName="maximumAge" [(ngModel)]="maximumAge" placeholder="Maximum Age">
                                            </div>
                                            <!-- <div class="form-group col-md-3">
                                                <label>Girl Top</label>
                                                <input type="text" class="form-control" formControlName="girlTopPath" [(ngModel)]="girlTopPath" placeholder="Girl Top Path">
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>Girl Bottom</label>
                                                <input type="text" class="form-control" formControlName="girlBottomPath" [(ngModel)]="girlBottomPath" placeholder="Girl Bottom Path">
                                            </div> -->
                                        </div>
                                        <div class="modal-footer col-md-6">
                                            <button [hidden]="AGBtn" [disabled]="updatebtn" type="submit" class="btn btn-primary">Add</button>
                                            <button [hidden]="!AGBtn" [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                            <button type="button" class="btn btn-light" (click)="AGCancel()">Cancel</button>
                                        </div>
                                  </form>
                                  <div class="row">
                                    <table class="table table-striped word_break_class">
                                        <thead>
                                          <tr>
                                              <th scope="col" class="text-left py-2 px-3">#</th>
                                              <th scope="col" class="text-left px-1 py-2">ID</th>
                                              <th scope="col" class="text-center py-2">Age Group</th>
                                              <th scope="col" class="text-center py-2">Boy Body</th>
                                              <!-- <th scope="col" class="text-center py-2">Boy Top</th>
                                              <th scope="col" class="text-center py-2">Boy Bottom</th> -->
                                              <th scope="col" class="text-center py-2">Girl Body</th>
                                              <!-- <th scope="col" class="text-center py-2">Girl Top</th>
                                              <th scope="col" class="text-center py-2">Girl Bottom</th> -->
                                              <th scope="col" class="text-center py-2">Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let ageGroup of ageGroupList; index as i">
                                              <td scope="row" class="px-3">{{ i + 1 }}</td>
                                              <td>{{ ageGroup.id }}</td>
                                              <td class="text-center">{{ ageGroup.minimumAge }} - {{ ageGroup.maximumAge }}</td>
                                              <td>{{ ageGroup.boyPath }}</td>
                                              <!-- <td>{{ ageGroup.boyTopPath }}</td>
                                              <td>{{ ageGroup.boyBottomPath }}</td> -->
                                              <td>{{ ageGroup.girlPath }}</td>
                                              <!-- <td>{{ ageGroup.girlTopPath }}</td>
                                              <td>{{ ageGroup.girlBottomPath }}</td> -->
                                              <td class="text-center">
                                                <button type="button" (click)="action(ageGroup, 'edit', i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                                <button type="button" (click)="action(ageGroup, 'del', i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                  </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="6">
                                <a ngbNavLink>Package Detail</a>
                                <ng-template ngbNavContent>
                                    <form [formGroup]="FormFD" (validSubmit)="updateFD()" novalidate>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label>Package Name</label>
                                                <input type="text" class="form-control" formControlName="packageName" [(ngModel)]="packageName" placeholder="Package Name">
                                            </div>
                                        </div>
                                        <div class="modal-footer col-md-6">
                                            <!-- <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button> -->
                                            <button [hidden]="FDBtn" [disabled]="updatebtn" type="submit" class="btn btn-primary">Add</button>
                                            <button [hidden]="!FDBtn" [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                            <button type="button" class="btn btn-light" (click)="FDCancel()">Cancel</button>
                                        </div>
                                    </form>
                                    <div class="row">
                                        <table class="table table-striped word_break_class">
                                            <thead>
                                              <tr>
                                                  <th scope="col" class="text-left py-2 px-3">#</th>
                                                  <th scope="col" class="text-left px-1 py-2">Package Detail</th>
                                                  <th scope="col" class="text-center py-2">Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let package of packageData; index as i">
                                                  <td scope="row" class="px-3">{{ i + 1 }}</td>
                                                  <td>{{ package.packageName }}</td>
                                                  <td class="text-center">
                                                    <button type="button" (click)="actionFD(package, 'edit', i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                                    <button type="button" (click)="actionFD(package, 'del', i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                      </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="7">
                                <a ngbNavLink>Educator Package Detail</a>
                                <ng-template ngbNavContent>
                                    <form [formGroup]="FormPD" (validSubmit)="updatePD()" novalidate>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label>Package Name</label>
                                                <input type="text" class="form-control" formControlName="educatorPackageName" [(ngModel)]="educatorPackageName" placeholder="Package Name">
                                            </div>
                                        </div>
                                        <div class="modal-footer col-md-6">
                                            <!-- <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button> -->
                                            <button [hidden]="PDBtn" [disabled]="updatebtn" type="submit" class="btn btn-primary">Add</button>
                                            <button [hidden]="!PDBtn" [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                            <button type="button" class="btn btn-light" (click)="PDCancel()">Cancel</button>
                                        </div>
                                    </form>
                                    <div class="row">
                                        <table class="table table-striped word_break_class">
                                            <thead>
                                              <tr>
                                                  <th scope="col" class="text-left py-2 px-3">#</th>
                                                  <th scope="col" class="text-left px-1 py-2">Package Detail</th>
                                                  <th scope="col" class="text-center py-2">Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let package of educatorPackageData; index as i">
                                                  <td scope="row" class="px-3">{{ i + 1 }}</td>
                                                  <td>{{ package.educatorPackageName }}</td>
                                                  <td class="text-center">
                                                    <button type="button" (click)="actionPD(package, 'edit', i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                                    <button type="button" (click)="actionPD(package, 'del', i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                      </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="8">
                                <a ngbNavLink>Top Up</a>
                                <ng-template ngbNavContent>
                                  <form [formGroup]="FormTU" (validSubmit)="updateTU()" novalidate>
                                      <div class="row">
                                          <div class="form-group col-md-3">
                                              <label>Hours</label>
                                              <input type="text" class="form-control" formControlName="hours" [(ngModel)]="hours" placeholder="Hours" pattern="\d*">
                                          </div>
                                          <div class="form-group col-md-3">
                                              <label>Hour Price</label>
                                              <input type="text" class="form-control" formControlName="hourPrice" [(ngModel)]="hourPrice" placeholder="Hour Price" pattern="\d*">
                                          </div>
                                      </div>
                                      <div class="row">
                                        <div class="form-group col-md-3">
                                            <label>Drive Space</label>
                                            <input type="text" class="form-control" formControlName="driveSpace" [(ngModel)]="driveSpace" placeholder="Drive Space" pattern="\d*">
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Drive Space Price</label>
                                            <input type="text" class="form-control" formControlName="driveSpacePrice" [(ngModel)]="driveSpacePrice" placeholder="Drive Space Price" pattern="\d*">
                                        </div>
                                    </div>
                                      <div class="modal-footer col-md-6">
                                          <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                                      </div>
                                  </form>
                                </ng-template>
                              </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
					</div>
				</div>
			</div>
        </div>
        <!-- <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
                <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
            </div>
        </div> -->
        <!-- <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div> -->
        <!-- <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Create At</th>
                            <th scope="col">Modify By</th>
                            <th scope="col">Update At</th>
                            <th scope="col">Active</th>
                            <th scope="col" colspan="2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let preference of preferenceList; index as i">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ preference.name }}</td>
                            <td>{{ preference.createAt | date }}</td>
                            <td>{{ preference.admin[0].firstName }} {{ preference.admin[0].lastName }}</td>
                            <td>{{ preference.updateAt | date }}</td>
                            <td>
                                <button [hidden]="preference.active" (click)="active(preference.active, preference._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!preference.active" (click)="active(preference.active, preference._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td><button type="button" (click)="show(preference, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button></td>
                            <td><button type="button" (click)="show(preference, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-12 col-xxl-12">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
                <div class="pagination">
                    <li class="page-item" *ngIf="visiblePaginationBtn">
                        <button (click)="pagePrevious()" class="page-link">
                        <span aria-hidden="true">
                            <i class="fa fa-arrow-left"></i>
                        </span>
                            <span class="sr-only">Previous</span>
                        </button>
                    </li>
                    <li *ngFor="let number of numbers">
                        <div *ngIf="number == pageIndex; then PageSelectblock; else PageUnSelectblock;"></div>
                        <ng-template #PageSelectblock>
                                <li class="page-item active" >
                                <button  class="page-link" (click)="pageNavigateTo(number)">{{number+1}}</button>
                                </li>
                        </ng-template>
                        <ng-template #PageUnSelectblock>
                                <li class="page-item" >
                                <button  class="page-link" (click)="pageNavigateTo(number)">{{number+1}}</button>
                            </li>
                        </ng-template>
                    </li>
                    <li class="page-item" *ngIf="visiblePaginationBtn">
                        <button (click)="pageNext()" class="page-link">
                            <span aria-hidden="true">
                                <i class="fa fa-arrow-right"></i>
                            </span>
                            <span class="sr-only">Next</span>
                        </button>
                    </li>
                </div>
            </div>
            <div class="col-md-4">
            </div>
        </div> -->
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<!-- <ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Preference</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Name</label>
                    <input type="text" class="form-control" formControlName="name" placeholder="Name">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Preference</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Name</label>
                    <input type="text" class="form-control" formControlName="name" [(ngModel)]="name" placeholder="Name">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Preference</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template> -->

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CategoryService } from './category.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  Form: FormGroup;

  name;
  colorCode;
  image;
  bgimage;
  id;
  index;
  filesupload = [];
  bgfilesupload = [];
  categoryList = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      name: ['-', Validators.required],
      colorCode: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination()
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.categoryService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.categoryService.pagination(data).then(data => this.categoryList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.filesupload = [];
    this.bgfilesupload = [];
  }

  fileChangeEvent(event) {
    this.filesupload = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  bgfileChangeEvent(event) {
    this.bgfilesupload = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.bgfilesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  add() {
    if(this.bgfilesupload.length > 0) {
      if(this.filesupload.length > 0) {
        this.addbtn = true;
        this.categoryService.add(this.Form.value, this.filesupload, this.bgfilesupload).then(data => {
          if(data.flag == true) {;
            this.categoryList.unshift(data.data);
            this.total = (parseInt(this.total) + 1).toString();
            this.toastr.successToastr(data.message, 'Category Added!', { animate: 'slideFromRight', showCloseButton: true });
            this.modalService.dismissAll();
            this.clear();
          }
          else {
            this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
            this.addbtn = false; 
            this.modalService.dismissAll();
          }
        });
      }
      else {
        this.toastr.errorToastr('Please Upload Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    }
    else {
      this.toastr.errorToastr('Please Upload Background Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.name = data.name;
    this.colorCode = data.colorCode;
    this.image = data.image;
    this.bgimage = data.bgimage;
  }

  update() {
    this.updatebtn = true;
    this.categoryService.update(this.Form.value, this.id, this.filesupload, this.bgfilesupload, this.image, this.bgimage).then(data => {
      if(data.flag == true) {
        this.categoryList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Category Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.categoryService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.categoryList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Category Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.categoryService.active(json, id).then(data => {
      if(data.flag == true) {
        this.categoryList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoryClassesService } from './category-classes.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {NgbCalendar, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ApiConstant } from '../api.constant';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
        `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
        '';
  }
}

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Component({
  selector: 'app-category-classes',
  templateUrl: './category-classes.component.html',
  styleUrls: ['./category-classes.component.css'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class CategoryClassesComponent implements OnInit {

  categoryClassesList = [];
  classesList = [];
  statusData;
  status = 'Active';

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  footerTemplate;

  constructor(
    private modalService: NgbModal,
    private categoryClassesService: CategoryClassesService,
    private toastr: ToastrManager,
    private calendar: NgbCalendar,
    private apiConstant: ApiConstant
  ) { 
    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  ngOnInit(): void {
    var dateObj = new Date();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: firstDay.getDate(),
      month: month,
      year: year
    }

    this.endDate = {
      day: lastDay.getDate(),
      month: month,
      year: year
    }

    const statusCategoryClass = localStorage.getItem('statusCategoryClass');
    if(statusCategoryClass != null) {
      this.status = statusCategoryClass;
    }

    this.statusData = [{
        id: 'Active',
        text: 'Active',
      },
      {
        id: 'Deactive',
        text: 'Deactive'
      },
      {
        id: 'Draft',
        text: 'Draft'
      }];

    this.pagination();
  }

  pagination() {
    var startDate = this.startDate.year + '-' + this.startDate.month + '-' + this.startDate.day;
    startDate = new Date(startDate).toISOString();
    var endDate = this.endDate.year + '-' + this.endDate.month + '-' + this.endDate.day;
    endDate = new Date(endDate).toISOString();

    var json = {
      startDate: startDate,
      endDate: endDate,
      status: this.status
    }
    
    this.categoryClassesService.pagination(json).then(data => this.categoryClassesList = data.data);
  }

  onType(event) {
    this.status = event;
    localStorage.setItem('statusInReview', this.status);
  }

  filter() {
    this.pagination();
  }

  clear() {
    var dateObj = new Date();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var year = dateObj.getUTCFullYear();

    this.startDate = {
      day: firstDay.getDate(),
      month: month,
      year: year
    }

    this.endDate = {
      day: lastDay.getDate(),
      month: month,
      year: year
    }

    this.pagination();
  }

  show(data, type) {
    this.classesList = data;
    this.modalService.open(type, { size: 'lg' });
  }
}
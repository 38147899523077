<div class="authincation vh-100">
	<div class="container h-100">
		<div class="row justify-content-center h-100 align-items-center">
			<div class="col-md-6">
				<div class="authincation-content">
					<div class="row no-gutters">
						<div class="col-xl-12">
							<div class="auth-form">
								<div class="text-center mb-3">
									<a [routerLink]="['/admin/index']"><img src="assets/images/meeko3.png" alt=""></a>
								</div>
								<h4 class="text-center mb-4 text-white">Sign in your account</h4>
								<form [formGroup]="AuthForm" (validSubmit)="signIn()" novalidate>
									<div class="form-group">
										<label class="mb-1 text-white"><strong>Username</strong></label>
										<input type="text" class="form-control" formControlName="userName" placeholder="Username">
									</div>
									<div class="form-group">
										<label class="mb-1 text-white"><strong>Password</strong></label>
										<input type="password" class="form-control" formControlName="password" placeholder="Password">
									</div>
									<div class="form-row d-flex justify-content-between mt-4 mb-2">
										<div class="form-group">
											<div class="custom-control custom-checkbox ml-1 text-white">
												<input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
												<label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
											</div>
										</div>
										<div class="form-group">
											<a class="text-white" [routerLink]="['/page-forgot-password']">Forgot Password?</a>
										</div>
									</div>
									<div class="text-center">
										<button [disabled]="btn" type="submit" class="btn bg-white text-primary btn-block">Sign Me In</button>
									</div>
								</form>
								<!-- <div class="new-account mt-3">
									<p class="text-white">Don't have an account? <a class="text-white" [routerLink]="['/admin/page-register']">Sign up</a></p>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
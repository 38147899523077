import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ELearnerRefundService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }
  
  total(data) {
    return this.httpmanagerService.HttpPost('elearnerrefund/total', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('elearnerrefund/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  totalSearch(data, id) {
    return this.httpmanagerService.HttpPost('elearnerrefund/totalsearch', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  paginationSearch(data, id) {
    return this.httpmanagerService.HttpPost('elearnerrefund/search', JSON.stringify(data), id).then(data => {
      return data;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ChatbotQuestionnaireService } from './chatbot-questionnaire.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-chatbot-questionnaire',
  templateUrl: './chatbot-questionnaire.component.html',
  styleUrls: ['./chatbot-questionnaire.component.css']
})
export class ChatbotQuestionnaireComponent implements OnInit {

  Form: FormGroup;

  intents;
  answer;
  optionType;
  options;
  question;
  id;
  index;
  chatbotQuestionnaireList = [];
  dropDownData = [];
  intentsData = [];
  answerData = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private chatbotQuestionnaireService: ChatbotQuestionnaireService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      intents: ['-', Validators.nullValidator],
      answer: ['-', Validators.nullValidator],
      optionType: ['-', Validators.required],
      options: ['-', Validators.nullValidator],
      question: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData = [{
      id: "-",
      text: "-- Select Option Type --",
      disabled: true,
      selected: true
    },
    {
      id: "None",
      text: "None"
    },
    {
      id: "DropDownList",
      text: "Drop Down List"
    },
    {
      id: "MultiSelect",
      text: "Multi Select"
    },
    {
      id: "RedioButton",
      text: "Redio Button"
    }]

    this.intentsData = [{
      id: "-",
      text: "-- Select Intents --",
      disabled: true,
      selected: true
    }];
    this.chatbotQuestionnaireService.list().then(data => {
      if(data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.intentsData.push({
            id: element._id,
            text: element.question
          });
        }
      }
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.chatbotQuestionnaireService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.chatbotQuestionnaireService.pagination(data).then(data => this.chatbotQuestionnaireList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.intents = "-";
    this.answer = "";
    this.question = "";
    this.optionType = "-";
    this.options = "";
    // this.answerData = [{
    //   id: "-",
    //   text: "-- Select Answer --",
    //   disabled: true,
    //   selected: true
    // }]
    this.answerData = []
  }

  onIntentsChange(event) {
    // this.answerData = [{
    //   id: "-",
    //   text: "-- Select Answer --",
    //   disabled: true,
    //   selected: true
    // }]
    this.answerData = []
    this.chatbotQuestionnaireService.question(event).then(data => {
      if(data.length > 0) {
        for (let index = 0, p = Promise.resolve(); index < data.length; index++) {
          p = p.then(_ => new Promise(resolve => {
            const element = data[index];
            if(element.options.length > 0) {
              for (let i = 0; i < element.options.length; i++) {
                const e = element.options[i];
                this.answerData.push({
                  id: e,
                  text: e,
                });
                if(element.options.length == (i + 1)) {
                  resolve();
                }
              }
            }
            else {
              resolve();
            }
          }));
        }
      }
    });
  }

  add() {
    var Form = this.Form.value;
    new Promise(function(resolve, reject) {
      if(Form.options != undefined && Form.options.length > 0) {
        var options = [];
        Form.options.forEach((element, index) => {
          options.push(element.value);
          if(Form.options.length == (index + 1)) {
            Form.options = options;
            resolve(0);
          }
        });
      }
      else {
        resolve(0);
      }
    }).then(next => {
      this.addbtn = true;
      this.chatbotQuestionnaireService.add(Form).then(data => {
        if(data.flag == true) {;
          this.intentsData.push({
            id: data.data._id,
            text: data.data.question
          });
          this.chatbotQuestionnaireList.unshift(data.data);
          this.total = (parseInt(this.total) + 1).toString();
          this.toastr.successToastr(data.message, 'Chatbot Questionnaire Added!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.addbtn = false; 
          this.modalService.dismissAll();
        }
      });
    });
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.intents = data.intents;
    this.answer = data.answer;
    this.optionType = data.optionType;
    if(this.intents != undefined && this.intents != "") {
      this.onIntentsChange(this.intents);
    }
    this.question = data.question;
    if(data.options != undefined && data.options.length > 0) {
      var options = [];
      data.options.forEach((element, idx) => {
        options.push({display: element, value: element});
        if(data.options.length == (idx + 1)) {
          this.options = options;
        }
      });
    }
    else {
      this.options = [];
    }
  }

  update() {
    var Form = this.Form.value;
    new Promise(function(resolve, reject) {
      if(Form.options != undefined && Form.options.length > 0) {
        var options = [];
        Form.options.forEach((element, index) => {
          options.push(element.value);
          if(Form.options.length == (index + 1)) {
            Form.options = options;
            resolve(0);
          }
        });
      }
      else {
        resolve(0);
      }
    }).then(next => {
      this.updatebtn = true;
      this.chatbotQuestionnaireService.update(Form, this.id).then(data => {
        if(data.flag == true) {
          this.chatbotQuestionnaireList[this.index] = data.data;
          this.toastr.successToastr(data.message, 'Chatbot Questionnaire Updated!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.updatebtn = false;
        }
      });
    });
  }

  delete() {
    this.deletebtn = true;
    this.chatbotQuestionnaireService.delete(this.id).then(data => {
      if(data.flag == true) {
        var qId = this.chatbotQuestionnaireList[this.index]._id;
        var iindex = this.intentsData.findIndex(x => x.id == qId);
        if(iindex != -1) {
          this.intentsData.splice(iindex, 1);
        }
        this.chatbotQuestionnaireList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Chatbot Questionnaire Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.chatbotQuestionnaireService.active(json, id).then(data => {
      if(data.flag == true) {
        this.chatbotQuestionnaireList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Enquire</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <form [formGroup]="Form" (validSubmit)="filter()" novalidate> -->
                <!-- <div class="col-md-1">
                    <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                    <option selected value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <ng-select2 
                        [data]="statusData"
                        (valueChanged)="onType($event)"
                        [placeholder]="'Please select a status...'">		
                    </ng-select2>
                </div>
                <!-- <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" formControlName="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" formControlName="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-4 search-addbtn">
                    <div class="input-group search-area d-xl-inline-flex d-none">
                        <input type="text" class="form-control" id="search" placeholder="Search here...">
                        <div class="input-group-append">
                            <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                        </div>
                    </div>
                    <div class="ml-3">
                    </div>
                </div>
            <!-- </form> -->
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Date</th>
                            <th scope="col" class="text-left px-1 py-2">Class Name</th>
                            <th scope="col" class="text-left px-1 py-2">Educator Name</th>
                            <th scope="col" class="text-left px-1 py-2">Educator Mobile</th>
                            <th scope="col" class="text-left px-1 py-2">Full Name</th>
                            <th scope="col" class="text-left px-1 py-2">Email</th>
                            <th scope="col" class="text-left px-1 py-2">Mobile</th>
                            <th scope="col" class="text-left px-1 py-2">Message</th>
                            <th scope="col" class="text-left px-1 py-2">Status</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let enquire of enquireList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ enquire.date | date: 'medium':'IST' }}</td>
                            <td>{{ enquire.className }}</td>
                            <td>{{ enquire.educatorName }}</td>
                            <td>{{ enquire.educatorMobile }}</td>
                            <td>{{ enquire.name }}</td>
                            <td>{{ enquire.email }}</td>
                            <td>{{ enquire.mobile }}</td>
                            <td>{{ enquire.message }}</td>
                            <td [hidden]="!enquire.send"><img src="./assets/icons/tick.png" style="width:20px; height: 15px;"></td>
                            <td [hidden]="enquire.send"><img src="./assets/icons/popup-close.png" style="width:20px; height: 20px;"></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->
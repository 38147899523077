<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Educator</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Badge Level</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Total Hours</th> -->
                            <th scope="col" class="text-left px-1 py-2">Total Students</th>
                            <th scope="col" class="text-left px-1 py-2">Students</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col" class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let educator of educatorList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ educator.badgelevel[0].badgeName }}</td>
                            <!-- <td>{{ educator.totalSecond / 60 / 60 }}</td> -->
                            <td>{{ educator.totalStudent }}</td>
                            <td>{{ educator.fromStudent }} - {{ educator.toStudent }}</td>
                            <!-- <td>{{ educator.createAt | date }}</td>
                            <td>{{ educator.admin[0].firstName }} {{ educator.admin[0].lastName }}</td>
                            <td>{{ educator.updateAt | date }}</td> -->
                            <td class="text-center">
                                <button [hidden]="educator.active" (click)="active(educator.active, educator._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!educator.active" (click)="active(educator.active, educator._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(educator, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(educator, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Educator</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Badge Level</label>
                    <!-- <select class="form-control" formControlName="badgeLevelId" [(ngModel)]="badgeLevelId" id="inputBadgeLevel">
                        <option *ngFor="let badgeLevel of badgeLevelList" value={{badgeLevel._id}}>{{badgeLevel.badgeName}}</option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="badgeLevelId"
                        formControlName="badgeLevelId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a badge level...'">		
                    </ng-select2>
                </div>
            </div>
            <!-- <div class="row">
                <div class="form-group col-md-12">
                    <label>Total Hours</label>
                    <input type="text" class="form-control" formControlName="totalSecond" placeholder="Total Hours" pattern="\d*">
                </div>
            </div> -->
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Total Students</label>
                    <input type="text" class="form-control" formControlName="totalStudent" placeholder="Total Student" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>From Students</label>
                    <input type="text" class="form-control" formControlName="fromStudent" placeholder="From Students" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>To Students</label>
                    <input type="text" class="form-control" formControlName="toStudent" placeholder="To Students" pattern="\d*">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Educator</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Badge Level</label>
                    <!-- <select disabled class="form-control" formControlName="badgeLevelId" [(ngModel)]="badgeLevelId" id="inputBadgeLevel">
                        <option *ngFor="let badgeLevel of badgeLevelList" value={{badgeLevel._id}}>{{badgeLevel.badgeName}}</option>
                    </select> -->
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="badgeLevelId"
                        formControlName="badgeLevelId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a badge level...'">		
                    </ng-select2>
                </div>
            </div>
            <!-- <div class="row">
                <div class="form-group col-md-12">
                    <label>Total Hours</label>
                    <input type="text" class="form-control" formControlName="totalSecond" [(ngModel)]="totalSecond" placeholder="Total Hours" pattern="\d*">
                </div>
            </div> -->
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Total Students</label>
                    <input type="text" class="form-control" formControlName="totalStudent" [(ngModel)]="totalStudent" placeholder="Total Student" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>From Students</label>
                    <input type="text" class="form-control" formControlName="fromStudent" [(ngModel)]="fromStudent" placeholder="From Students" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>To Students</label>
                    <input type="text" class="form-control" formControlName="toStudent" [(ngModel)]="toStudent" placeholder="To Students" pattern="\d*">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Educator</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
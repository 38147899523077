import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { EducatorService } from './educator.service';
import { BadgeLevelService } from '../badge-level/badge-level.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-educator.',
  templateUrl: './educator.component.html',
  styleUrls: ['./educator.component.css']
})
export class EducatorComponent implements OnInit {

  Form: FormGroup;

  badgeLevelId;
  fromStudent;
  toStudent;
  // totalSecond;
  totalStudent;
  id;
  index;
  educatorList = [];
  badgeLevelList = [];
  dropDownData = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private educatorService: EducatorService,
    private badgeLevelService: BadgeLevelService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      badgeLevelId: ['-', Validators.required],
      // totalSecond: ['-', Validators.required],
      totalStudent: ['-', Validators.required],
      fromStudent: ['-', Validators.required],
      toStudent: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData.push({
      id: "-",
      text: "-- Select Badge Level --",
      disabled: true,
      selected: true
    })
    this.badgeLevelService.list({id: this.apiConstant.badgeLevelEducatorId}).then(data => { 
      this.badgeLevelList = data;
      for (let index = 0; index < this.badgeLevelList.length; index++) {
        const element = this.badgeLevelList[index];
        this.dropDownData.push({
          id: element._id,
          text: element.badgeName
        });
      }
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.educatorService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.educatorService.pagination(data).then(data => this.educatorList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.badgeLevelId = null;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id != '-') {
      this.dropDownData.unshift({
        id: "-",
        text: "-- Select Badge Level --",
        disabled: true,
        selected: true
      });
    }
  }

  add() {
    this.addbtn = true;
    // this.Form.value.totalSecond = this.Form.value.totalSecond * 60 * 60;
    this.educatorService.add(this.Form.value).then(data => {
      if(data.flag == true) {
        this.educatorList.unshift(data.data);
        this.total = (parseInt(this.total) + 1).toString();
        this.toastr.successToastr(data.message, 'Educator Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false; 
        this.modalService.dismissAll();
      }
    });
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.badgeLevelId = data.badgeLevelId;
    // this.totalSecond = data.totalSecond / 60 / 60;
    this.totalStudent = data.totalStudent;
    this.fromStudent = data.fromStudent;
    this.toStudent = data.toStudent;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id == '-') {
      this.dropDownData.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    // this.Form.value.totalSecond = this.Form.value.totalSecond * 60 * 60;
    this.educatorService.update(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.educatorList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Educator Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.educatorService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.educatorList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Educator Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.educatorService.active(json, id).then(data => {
      if(data.flag == true) {
        this.educatorList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">E-Learner</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
              <div class="col-md-8"></div>
              <div class="col-md-4 search-addbtn">
                  <div class="input-group search-area d-xl-inline-flex d-none">
                      <input type="text" class="form-control" id="search" placeholder="Search here...">
                      <div class="input-group-append">
                          <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                      </div>
                  </div>
                  <div class="ml-3">
                      <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
                  </div>
              </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Badge Level</th>
                            <th scope="col" class="text-left px-1 py-2">Minimum Hours</th>
                            <th scope="col" class="text-left px-1 py-2">Maximum Hours</th>
                            <th scope="col" class="text-left px-1 py-2">Light Code</th>
                            <!-- <th scope="col" class="text-left px-1 py-2">Create At</th>
                            <th scope="col" class="text-left px-1 py-2">Modify By</th>
                            <th scope="col" class="text-left px-1 py-2">Update At</th> -->
                            <th scope="col" class="text-center py-2">Status</th>
                            <th scope="col" class="text-center py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let eLearner of eLearnerList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ eLearner.badgelevel[0].badgeName }}</td>
                            <td>{{ eLearner.minimumSecond  }}</td>
                            <td>{{ eLearner.maxmumSecond }}</td>
                            <td>{{ eLearner.lightCode }}</td>
                            <!-- <td>{{ eLearner.createAt | date }}</td>
                            <td>{{ eLearner.admin[0].firstName }} {{ eLearner.admin[0].lastName }}</td>
                            <td>{{ eLearner.updateAt | date }}</td> -->
                            <td class="text-center">
                                <button [hidden]="eLearner.active" (click)="active(eLearner.active, eLearner._id, i)" type="button" class="btn btn-outline-danger"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                <button [hidden]="!eLearner.active" (click)="active(eLearner.active, eLearner._id, i)" type="button" class="btn btn-outline-success"><i class="fa fa-times-circle-o" aria-hidden="true"></i></button>
                            </td>
                            <td class="text-center">
                                <button type="button" (click)="show(eLearner, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button type="button" (click)="show(eLearner, del, i)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add E-Learner</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <!-- <div style="height:50%; width:50%">
            <input id="file-input" type="file" (change)="mychange($event)" />
            <canvas id="c"></canvas>
        </div> -->
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>Badge Level</label>
                    <!-- <select class="form-control" formControlName="badgeLevelId" [(ngModel)]="badgeLevelId" id="inputBadgeLevel">
                        <option *ngFor="let badgeLevel of badgeLevelList" value={{badgeLevel._id}}>{{badgeLevel.badgeName}}</option>
                    </select> -->
                    <ng-select2 
                        [(ngModel)]="badgeLevelId"
                        formControlName="badgeLevelId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a badge level...'">		
                    </ng-select2>
                </div>
                <div class="form-group col-md-4">
                    <label>Minmum Hours</label>
                    <input type="text" class="form-control" (keyup)="onKeypressEventMini($event)" formControlName="minimumSecond" placeholder="Minmum Hours" pattern="\d*">
                </div>
                <div class="form-group col-md-4">
                    <label>Maximum Hours</label>
                    <input type="text" class="form-control" (keyup)="onKeypressEventMaxi($event)" formControlName="maxmumSecond" placeholder="Maximum Hours" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="formFileMultiple" class="form-label">Add New Spaceship Image</label>
                    <input class="form-control" type="file" (change)="fileChangeEvent($event)" id="formFileMultiple" multiple />
                </div>
                <div class="form-group col-md-4">
                    <label for="formFileMultiple" class="form-label">Achievement Video</label>
                    <input class="form-control" type="file" (change)="fileChangeEventVideo($event)" id="formFileMultiple2" />
                </div>
                <div class="form-group col-md-4">
                    <label for="formFileMultiple" class="form-label">Light Code</label>
                    <input class="form-control" type="text" formControlName="lightCode" placeholder="Light Code" />
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Part Of Image</th>
                                    <th scope="col">Part No.</th>
                                    <th scope="col">Percentage</th>
                                    <th scope="col">Total Hours</th>
                                    <th scope="col">Total Minutes</th>
                                    <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let ss of SpaceshipSetup; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td><img [src]="ss.image" height="100px" width="100px" style="margin: 3px;"></td>
                                    <td><input type="number" (keyup)="onKeypressEventNum($event, ss)" class="form-control" [value]="ss.number" placeholder="Number" pattern="\d*"></td>
                                    <td><input type="number" (keyup)="onKeypressEvent($event, ss)" class="form-control" [value]="ss.percentage" placeholder="Percentage" pattern="\d*"></td>
                                    <td><input disabled type="text" class="form-control" [value]="ss.totalSecond" placeholder="Total Hours"></td>
                                    <td><input disabled type="text" class="form-control" [value]="ss.totalSecond * 60" placeholder="Total Minutes"></td>
                                    <td><button type="button" (click)="deleteImage(ss)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update E-Learner</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>Badge Level</label>
                    <!-- <select disabled class="form-control" formControlName="badgeLevelId" [(ngModel)]="badgeLevelId" id="inputBadgeLevel">
                        <option *ngFor="let badgeLevel of badgeLevelList" value={{badgeLevel._id}}>{{badgeLevel.badgeName}}</option>
                    </select> -->
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="badgeLevelId"
                        formControlName="badgeLevelId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a badge level...'">		
                    </ng-select2>
                </div>
                <div class="form-group col-md-4">
                    <label>Minmum Hours</label>
                    <input type="text" class="form-control" (keyup)="onKeypressEventMini($event)" formControlName="minimumSecond" [(ngModel)]="minimumSecond" placeholder="Minmum Hours" pattern="\d*">
                </div>
                <div class="form-group col-md-4">
                    <label>Maximum Hours</label>
                    <input type="text" class="form-control" (keyup)="onKeypressEventMaxi($event)" formControlName="maxmumSecond" [(ngModel)]="maxmumSecond" placeholder="Maximum Hours" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="formFileMultiple" class="form-label">Add New Spaceship Image</label>
                    <input class="form-control" type="file" (change)="fileChangeEvent($event)" id="formFileMultiple" multiple />
                </div>
                <div class="form-group col-md-4">
                    <label for="formFileMultiple" class="form-label">Achievement Video</label>
                    <input class="form-control" type="file" (change)="fileChangeEventVideo($event)" id="formFileMultiple2" />
                </div>
                <div class="form-group col-md-4">
                    <label for="formFileMultiple" class="form-label">Light Code</label>
                    <input class="form-control" type="text" [(ngModel)]="lightCode" formControlName="lightCode" placeholder="Light Code" />
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Part Of Image</th>
                                    <th scope="col">Part No.</th>
                                    <th scope="col">Percentage</th>
                                    <th scope="col">Total Hours</th>
                                    <th scope="col">Total Minutes</th>
                                    <th scope="col">Edit</th>
                                    <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let ss of SpaceshipSetup; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td [hidden]="ss.tp"><img [src]="ss.image" height="100px" width="100px" style="margin: 3px;"></td>
                                    <td [hidden]="!ss.tp"><img [src]="ss.path" height="100px" width="100px" style="margin: 3px;"></td>
                                    <!-- <td>
                                        {{server + folder + '/' + ss.image}}
                                    </td> -->
                                    <td><input type="number" (keyup)="onKeypressEventNum($event, ss)" class="form-control" [value]="ss.number" placeholder="Number"></td>
                                    <td><input type="number" (keyup)="onKeypressEvent($event, ss)" class="form-control" [value]="ss.percentage" placeholder="Percentage"></td>
                                    <td><input disabled type="text" class="form-control" [value]="ss.totalSecond" placeholder="Total Hours"></td>
                                    <td><input disabled type="text" class="form-control" [value]="ss.totalSecond * 60" placeholder="Total Minutes"></td>
                                    <td>
                                        <!-- <label for="formFile"><i class="fa fa-pencil" aria-hidden="true"></i></label> -->
                                        <!-- <input class="form-control" type="file" (change)="fileChangeEventEdit($event, ss, i)" id="formFile" /> -->
                                        <div class="custome_upload_maindiv">
                                            <span class="upload-custome"><i class="fa fa-upload color-success"></i></span>
                                            <input class="form-control" type="file" (change)="fileChangeEventEdit($event, ss, i)" />
                                        </div>
                                    </td>
                                    <td><button type="button" (click)="deleteImage(ss)" class="btn_red_cur_remove btn btn-outline-danger"><i class="feather icon-trash"></i></button></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete E-Learner</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ELearnerService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data, images, video) {
    return this.httpmanagerService.HttpPostMultiFile('badgeelearner', images, video, JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('badgeelearner', "").then(data => {
      return data.data;
    });
  }

  update(data, images, video, id) {
    if(images.length > 0 || video.length > 0) {
      return this.httpmanagerService.HttpPostMultiFile('badgeelearner/update', images, video, JSON.stringify(data), id).then(data => {
        return data;
      });
    }
    else {
      return this.httpmanagerService.HttpPut('badgeelearner', JSON.stringify(data), id).then(data => {
        return data;
      });
    }
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('badgeelearner', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('badgeelearner/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('badgeelearner/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('badgeelearner/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BadgeLevelService } from './badge-level.service';
import { UserTypeService } from '../user-type/user-type.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-badge-level',
  templateUrl: './badge-level.component.html',
  styleUrls: ['./badge-level.component.css']
})
export class BadgeLevelComponent implements OnInit {

  Form: FormGroup;

  badgeName;
  userTypeId;
  image;
  video;
  bgImage;
  colorCode;
  bgBase64Image;
  id;
  index;
  badgeLevelList = [];
  userTypeList = [];
  dropDownData = [];
  filesupload = [];
  filesupload1 = [];
  filesupload2 = [];
  eLearnerId;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;
  server2;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private badgeLevelService: BadgeLevelService,
    private userTypeService: UserTypeService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      badgeName: ['-', Validators.required],
      userTypeId: ['-', Validators.required],
      colorCode: ['-', Validators.nullValidator]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData.push({
      id: "-",
      text: "-- Select User Type --",
      disabled: true,
      selected: true
    })
    this.userTypeService.list().then(data => { 
      this.userTypeList = data;
      for (let index = 0; index < this.userTypeList.length; index++) {
        const element = this.userTypeList[index];
        if(element.name == 'E-Learner') {
          this.eLearnerId = element._id;
        }
        this.dropDownData.push({
          id: element._id,
          text: element.name
        });
      }
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.badgeLevelService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.badgeLevelService.pagination(data).then(data => this.badgeLevelList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.userTypeId = null;
    this.bgBase64Image = "";
    this.filesupload = [];
    this.filesupload1 = [];
    this.filesupload2 = [];
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id != '-') {
      this.dropDownData.unshift({
        id: "-",
        text: "-- Select User Type --",
        disabled: true,
        selected: true
      });
    }
  }

  fileChangeEvent(event) {
    this.filesupload = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  fileChangeEvent1(event) {
    this.filesupload1 = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload1.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }
  }

  fileChangeEvent2(event) {
    this.filesupload2 = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      var filename = element.name.split('.');
      this.filesupload2.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    }

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.bgBase64Image = 'data:image/png;base64,' + btoa(e.target.result);
  }

  add() {
    if((this.eLearnerId == this.Form.value.userTypeId && this.filesupload.length > 0 && this.filesupload[0].file.type.split("/")[0] == '') || this.eLearnerId != this.Form.value.userTypeId) {
      if((this.eLearnerId == this.Form.value.userTypeId && this.filesupload1.length > 0 && this.filesupload1[0].file.type.split("/")[0] == 'video') || this.eLearnerId != this.Form.value.userTypeId) {
        if(this.filesupload2.length > 0 && this.filesupload2[0].file.type.split("/")[0] == 'image') {
          this.addbtn = true;
          var fileData = [];
          if(this.eLearnerId == this.Form.value.userTypeId) {
            fileData.push(this.filesupload[0]);
            fileData.push(this.filesupload1[0]);
          }
          fileData.push(this.filesupload2[0]);
          if(this.Form.value.colorCode == null) {
            this.Form.value.colorCode = "";
          }
          if(this.bgBase64Image != "" && this.eLearnerId != this.Form.value.userTypeId) {
            this.Form.value.bgBase64Image = this.bgBase64Image;
          }
          this.badgeLevelService.add(this.Form.value, fileData).then(data => {
            if(data.flag == true) {
              this.badgeLevelList.unshift(data.data);
              this.total = (parseInt(this.total) + 1).toString();
              this.toastr.successToastr(data.message, 'Badge Level Added!', { animate: 'slideFromRight', showCloseButton: true });
              this.modalService.dismissAll();
              this.clear();
            }
            else {
              this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
              this.addbtn = false; 
              this.modalService.dismissAll();
            }
          });
        }
        else {
          this.toastr.errorToastr(this.filesupload2.length > 0 ? 'Please upload only image!' : 'Please Upload Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        }
      }
      else {
        this.toastr.errorToastr(this.filesupload1.length > 0 ? 'Please upload only video!' : 'Please Upload Video!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    }
    else {
      this.toastr.errorToastr(this.filesupload.length > 0 ? 'Please upload only GLTF!' : 'Please Upload GLTF!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.badgeName = data.badgeName;
    this.userTypeId = data.userTypeId;
    this.image = data.image;
    this.video = data.video;
    this.bgImage = data.bgImage;
    this.colorCode = data.colorCode;
    this.bgBase64Image = data.bgBase64Image;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id == '-') {
      this.dropDownData.splice(0, 1);
    }
  }

  update() {
    if((this.eLearnerId == this.Form.value.userTypeId && this.filesupload.length > 0 && this.filesupload[0].file.type.split("/")[0] == '') || this.eLearnerId != this.Form.value.userTypeId || this.filesupload.length == 0) {
      if((this.eLearnerId == this.Form.value.userTypeId && this.filesupload1.length > 0 && this.filesupload1[0].file.type.split("/")[0] == 'video') || this.eLearnerId != this.Form.value.userTypeId || this.filesupload1.length == 0) {
        if(this.filesupload2.length > 0 && this.filesupload2[0].file.type.split("/")[0] == 'image' || this.filesupload2.length == 0) {
          this.updatebtn = true;
          var fileData = [];
          if(this.eLearnerId == this.Form.value.userTypeId && this.filesupload.length > 0) {
            fileData.push(this.filesupload[0]);
          }
          if(this.eLearnerId == this.Form.value.userTypeId && this.filesupload1.length > 0) {
            fileData.push(this.filesupload1[0]);
          }
          if(this.filesupload2.length > 0) {
            fileData.push(this.filesupload2[0]);
            if(this.bgBase64Image != "" && this.eLearnerId != this.Form.value.userTypeId) {
              this.Form.value.bgBase64Image = this.bgBase64Image;
            }
          }
          if(this.Form.value.colorCode == null) {
            this.Form.value.colorCode = "";
          }
         
          var Form = this.Form.value;
          var image = this.image;
          var video = this.video;
          var bgImage = this.bgImage;
          new Promise(function(resolve, reject) {
            if(fileData.length > 0) {
              fileData.forEach((element, index) => {
                if(element.file.type.split("/")[0] == '') {
                  Form.oldimage = image;
                }
                else  if(element.file.type.split("/")[0] == 'video') {
                  Form.oldvideo = video;
                }
                else {
                  Form.oldbgimage = bgImage;
                }
                if(fileData.length == (index + 1)) {
                  resolve(0);
                }
              });
            }
            else {
              resolve(0);
            }
          }).then(next => {
            this.badgeLevelService.update(Form, this.id, fileData).then(data => {
              if(data.flag == true) {
                this.badgeLevelList[this.index] = data.data;
                this.toastr.successToastr(data.message, 'Badge Level Updated!', { animate: 'slideFromRight', showCloseButton: true });
                this.modalService.dismissAll();
                this.clear();
              }
              else {
                this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
                this.modalService.dismissAll();
                this.updatebtn = false;
              }
            });
          });
        }
        else {
          this.toastr.errorToastr(this.filesupload2.length > 0 ? 'Please upload only image!' : 'Please Upload Image!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        } 
      }
      else {
        this.toastr.errorToastr(this.filesupload1.length > 0 ? 'Please upload only video!' : 'Please Upload Video!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    }
    else {
      this.toastr.errorToastr(this.filesupload.length > 0 ? 'Please upload only GLTF!' : 'Please Upload Image!', 'GLTF!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  delete() {
    this.deletebtn = true;
    this.badgeLevelService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.badgeLevelList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Badge Level Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.badgeLevelService.active(json, id).then(data => {
      if(data.flag == true) {
        this.badgeLevelList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
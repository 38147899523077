import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ReferFriendHistoryService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  pagination(data) {
    return this.httpmanagerService.HttpPost('referfriendhistory/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  search(data, search) {
    return this.httpmanagerService.HttpPost('referfriendhistory/search', JSON.stringify(data), search).then(data => {
      return data;
    });
  }

  history(data) {
    return this.httpmanagerService.HttpPost('referfriendhistory/history', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

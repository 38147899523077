import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from './notification.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  Form: FormGroup;

  name;
  type;
  template;
  id;
  index;
  notificationList = [];
  typeList = [];

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      name: ['-', Validators.required],
      type: ['-', Validators.required],
      template: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();

    this.typeList = [
      {
        id: "-",
        text: "-- Select Type --",
        disabled: true,
        selected: true
      },
      {
        id: "ELearner",
        text: "ELearner",
      },
      {
        id: "Parent",
        text: "Parent",
      },
      {
        id: "Educator",
        text: "Educator",
      }
    ]
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.notificationService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.notificationService.pagination(data).then(data => this.notificationList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    if(this.typeList[0] != undefined && this.typeList[0].id != '-') {
      this.typeList.unshift({
        id: "-",
        text: "-- Select Type --",
        disabled: true,
        selected: true
      });
    }
  }

  add() {
    this.addbtn = true;
    this.notificationService.add(this.Form.value).then(data => {
      if(data.flag == true) {;
        this.notificationList.unshift(data.data);
        this.total = (parseInt(this.total) + 1).toString();
        this.toastr.successToastr(data.message, 'Notification Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false; 
        this.modalService.dismissAll();
      }
    });
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.name = data.name;
    this.type = data.type;
    this.template = data.template;
    if(this.typeList[0] != undefined && this.typeList[0].id == '-') {
      this.typeList.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    this.notificationService.update(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.notificationList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Notification Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.notificationService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.notificationList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Notification Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.notificationService.active(json, id).then(data => {
      if(data.flag == true) {
        this.notificationList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">E-Learner Help Desk</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <form [formGroup]="Form" (validSubmit)="filter()" novalidate> -->
                <!-- <div class="col-md-1">
                    <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                    <option selected value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <!-- <ng-select2
                        [data]="statusData"
                        (valueChanged)="onType($event)"
                        [placeholder]="'Please select a status...'">
                    </ng-select2> -->
                </div>
                <!-- <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" formControlName="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" formControlName="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-4 search-addbtn">
                    <div class="input-group search-area d-xl-inline-flex d-none">
                        <input type="text" class="form-control" id="search" placeholder="Search here...">
                        <div class="input-group-append">
                            <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
                        </div>
                    </div>
                    <div class="ml-3">
                    </div>
                </div>
            <!-- </form> -->
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Date</th>
                            <th scope="col" class="text-left px-1 py-2">Ticket No</th>
                            <th scope="col" class="text-left px-1 py-2">Class Name</th>
                            <th scope="col" class="text-left px-1 py-2">Batch Name</th>
                            <th scope="col" class="text-left px-1 py-2">Session Name</th>
                            <th scope="col" class="text-left px-1 py-2">Child Name</th>
                            <th scope="col" class="text-left px-1 py-2">Educator Name</th>
                            <th scope="col" class="text-left px-1 py-2">Type</th>
                            <th scope="col" class="text-left px-1 py-2">Reason</th>
                            <th scope="col" class="text-left px-1 py-2">Other</th>
                            <th scope="col" class="text-left px-1 py-2">Status</th>
                            <th scope="col" class="text-left px-1 py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody class="Tbody">
                        <tr *ngFor="let childHelpDesk of childHelpDeskList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ childHelpDesk.date | date: 'medium':'IST' }}</td>
                            <td>{{ childHelpDesk.ticketNo }}</td>
                            <td>{{ childHelpDesk.className }}</td>
                            <td>{{ childHelpDesk.batchName }}</td>
                            <td>{{ childHelpDesk.sessionName }}</td>
                            <td>{{ childHelpDesk.childName }}</td>
                            <td>{{ childHelpDesk.educatorName }}</td>
                            <td>{{ childHelpDesk.type }}</td>
                            <td>{{ childHelpDesk.reason }}</td>
                            <td>{{ childHelpDesk.other }}</td>
                            <td class="text-center">{{ childHelpDesk.status }}</td>
                            <td class="text-center">
                                <button type="button" (click)="show(childHelpDesk, edit, i)" class="btn btn-outline-info"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Send Ticket</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="ticket_detail_section">
            <div class="ticket_detail_div">
                <div class="ticket_title">

                </div>

                <div class="main_div">
                    <div class="message_box_div">
                        <div class="message_box" *ngFor="let item of collections">
                            <div class="message_user_detail">
                                <div class="profile_head">

                                    <div class="profile-section">
                                        <img [src]="item.senderId == childId ? server + childProfilePic : item.senderId == educatorId ? server + educatorProfilePic : server + 'mekko-icon.png'" class="user-img" alt="user">
                                        <p>{{item.senderId == childId ? childName : item.senderId == educatorId ? educatorName : 'Admin'}}</p>
                                    </div>
                                    <div class="ticket_date_section">
                                        <p>{{item.date | date: 'medium':'IST'}}</p>
                                    </div>
                                </div>

                                <div class="message_chat">
                                    <span [innerHTML]="item.message"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="status_div">
                        <b>Status</b>
                        <p>{{status}}</p>
                        <div>
                            <div [hidden]="type == 'Refund'">
                                <button [hidden]="status == 'Close'" type="button" (click)="ticketClose()">Close Ticket</button>
                            </div>
                            <div [hidden]="type != 'Refund'">
                                <!-- <div [hidden]="!bankDetail"> -->
                                  <button style="margin: 3px 0px; color: green; border-color: green;" [hidden]="status == 'Approved' || status == 'Rejected'" type="button" (click)="ticketApproved()">Approved</button>
                                <!-- </div>
                                <div [hidden]="bankDetail">
                                  <button style="margin: 3px 0px; color: blue; border-color: blue;" [hidden]="status == 'Approved' || status == 'Rejected'" type="button" (click)="beneficiary()">Beneficiary</button>
                                </div> -->
                                <button style="margin: 3px 0px;" [hidden]="status == 'Approved' || status == 'Rejected'" type="button" (click)="ticketRejected()">Rejected</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form [hidden]="status == 'Close'" [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Message</label>
                    <ckeditor
                        [(ngModel)]="message"
                        #ckeditor
                        formControlName="message"
                        [config]="ckeConfig"
                        debounce="500">
                    </ckeditor>
                </div>
            </div>
            <!-- <div class="row">
                <div class="form-group col-md-12">
                    <label>Status</label>
                    <ng-select2
                        [(ngModel)]="status"
                        formControlName="status"
                        [data]="statusData"
                        (valueChanged)="onType($event)"
                        [placeholder]="'Please select a status...'">
                    </ng-select2>
                </div>
            </div> -->
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Add</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

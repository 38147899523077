import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data) {
    return this.httpmanagerService.HttpPost('usertype', JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('usertype', "").then(data => {
      return data.data;
    });
  }

  update(data, id) {
    return this.httpmanagerService.HttpPut('usertype', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('usertype', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('usertype/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('usertype/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('usertype/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

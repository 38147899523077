import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SubCategoryService } from './sub-category.service';
import { CategoryService } from '../category/category.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {

  Form: FormGroup;
  FormIcon: FormGroup;

  name;
  categoryId;
  iconName;
  id;
  index;
  editId;
  editIndex;
  subCategoryList = [];
  categoryList = [];
  dropDownData = [];
  iconupload = [];
  filesupload = [];
  iconData = [];
  // deleteId = [];
  updateId = [];
  updateIconFlag = false;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;
  server;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private subCategoryService: SubCategoryService,
    private categoryService: CategoryService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) {
    this.form();
    this.formIcon();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      name: ['-', Validators.required],
      categoryId: ['-', Validators.required],
      // iconName: ['-', Validators.nullValidator]
    });
  }

  formIcon() {
    this.FormIcon = this.formBuilder.group({
      iconName: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.server = this.apiConstant.Server2;
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();
    this.dropDownData.push({
      id: "-",
      text: "-- Select Main Category --",
      disabled: true,
      selected: true
    });
    this.categoryService.list().then(data => {
      this.categoryList = data;
      for (let index = 0; index < this.categoryList.length; index++) {
        const element = this.categoryList[index];
        this.dropDownData.push({
          id: element._id,
          text: element.name
        });
      }
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.subCategoryService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.subCategoryService.pagination(data).then(data => this.subCategoryList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'lg' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.categoryId = null;
    this.filesupload = [];
    this.iconData = [];
    this.iconName = "";
    this.clearIcon();
    this.FormIcon.reset();

    if(this.dropDownData[0] != undefined && this.dropDownData[0].id != '-') {
      this.dropDownData.unshift({
        id: "-",
        text: "-- Select Main Category --",
        disabled: true,
        selected: true
      });
    }
  }

  fileChangeEvent(event) {
    // this.filesupload = [];
    // for (let index = 0; index < event.target.files.length; index++) {
    //   const element = event.target.files[index];
    //   var filename = element.name.split('.');
    //   this.filesupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1]});
    // }

    if (event.target.files.length > 0) {
      // if(this.Form.value.iconName != "" && this.Form.value.iconName != "-") {
        const element = event.target.files[0];
        var filename = element.name.split('.');
        var reader = new FileReader();
        reader.onload = (event:any) => {
          // this.iconData.push({image: event.target.result, name: this.Form.value.iconName, id: this.filesupload.length});
          this.iconupload.push({file: element, filename: Date.now() + '.' + filename[filename.length - 1], image: event.target.result});
          // this.iconName = "";
        }
        reader.readAsDataURL(element);
      // }
      // else {
      //   this.toastr.errorToastr('please enter icon name!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      // }
    }
    else {
      this.toastr.errorToastr('please upload icon!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  addIcon() {
    if(this.updateIconFlag) {
      if(this.editId != undefined) {
        var index = this.iconData.findIndex(x => x._id != undefined && x._id.toString() == this.editId.toString());
        if(index != -1) {
          if(this.iconupload.length > 0) {
            var file = this.iconupload[this.iconupload.length - 1];
            this.iconData[index].image = file.image;
            this.iconData[index].name = this.FormIcon.value.iconName;
            this.iconData[index].type = false;
            var index3 = this.filesupload.findIndex(x => x.id != undefined && x.id.toString() == this.editId.toString());
            if(index3 != -1) {
              var ind = this.updateId.findIndex(x => x == this.editId);
              if(ind == -1) {
                this.updateId.push(this.editId);
              }
              this.filesupload[index3].file = file.file;
              this.filesupload[index3].filename = file.filename;
              this.clearIcon();
            }
            else {
              this.clearIcon();
            }
          }
          else {
            this.iconData[index].name = this.FormIcon.value.iconName;
            this.clearIcon();
          }
        }
        else {
          var index2 = this.iconData.findIndex(x => x.id != undefined && x.id.toString() == this.editId.toString());
          if(index2 != -1) {
            if(this.iconupload.length > 0) {
              var file = this.iconupload[this.iconupload.length - 1];
              this.iconData[index2].image = file.image;
              this.iconData[index].name = this.FormIcon.value.iconName;
              var index3 = this.filesupload.findIndex(x => x.id != undefined && x.id.toString() == this.editId.toString());
              if(index3 != -1) {
                this.filesupload[index3].file = file.file;
                this.filesupload[index3].filename = file.filename;
              }
              this.clearIcon();
            }
            else {
              this.iconData[index2].name = this.FormIcon.value.iconName;
              this.clearIcon();
            }
          }
          else {
            this.clearIcon();
          }
        }
      }
      else {
        this.clearIcon();
      }
    }
    else {
      if(this.iconupload.length > 0) {
        var file = this.iconupload[this.iconupload.length - 1];
        if(file != undefined) {
          this.iconData.push({image: file.image, name: this.FormIcon.value.iconName, id: this.filesupload.length});
          this.filesupload.push({file: file.file, filename: file.filename, id: this.filesupload.length, type: false});
        }
        this.clearIcon();
      }
      else {
        this.toastr.errorToastr('please upload icon!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.clearIcon();
      }
    }
  }

  editIcon(id) {
    this.editId = id;
    this.iconupload = [];
    var index = this.iconData.findIndex(x => x._id != undefined && x._id.toString() == id.toString());
    if(index != -1) {
      this.iconName = this.iconData[index].name;
      this.editIndex = index;
      this.updateIconFlag = true;
    }
    else {
      var index2 = this.iconData.findIndex(x => x.id != undefined && x.id.toString() == id.toString());
      if(index2 != -1) {
        this.iconName = this.iconData[index2].name;
        this.updateIconFlag = true;
      }
      this.editIndex = index2;
    }
  }

  deleteIcon(id) {
    var index = this.iconData.findIndex(x => x._id != undefined && x._id.toString() == id.toString());
    if(index != -1) {
      this.iconData.splice(index, 1);
      // this.deleteId.push(id);
    }
    else {
      var index2 = this.iconData.findIndex(x => x.id != undefined && x.id.toString() == id.toString());
      if(index2 != -1) {
        this.iconData.splice(index2, 1);
      }
      var index3 = this.filesupload.findIndex(x => x.id != undefined && x.id.toString() == id.toString());
      if(index3 != -1) {
        this.filesupload.splice(index3, 1);
      }
    }

    this.clearIcon();
  }

  clearIcon() {
    this.editIndex = -1;
    this.editId = undefined;
    this.iconupload = [];
    this.updateIconFlag = false;
    this.FormIcon.reset();
  }

  add() {
    this.addbtn = true;
    if(this.iconData.length > 0) {
      this.iconData.forEach(element => {
        delete element.image;
        delete element.type;
      });

      this.Form.value.icon = this.iconData;
      this.subCategoryService.add(this.Form.value, this.filesupload).then(data => {
        if(data.flag == true) {
          this.subCategoryList.unshift(data.data);
          this.total = (parseInt(this.total) + 1).toString();
          this.toastr.successToastr(data.message, 'Sub Category Added!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.addbtn = false;
          this.modalService.dismissAll();
        }
      });
    }
    else {
      this.toastr.errorToastr('please select atlest one icon!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  show(data, type, i) {
    data = JSON.parse(JSON.stringify(data));
    this.clearIcon();
    this.updatebtn = false;
    this.deletebtn = false;
    // this.deleteId = [];
    this.updateId = [];
    this.iconData = [];
    this.filesupload = [];
    this.iconName = "";
    this.modalService.open(type, { size: 'lg' });
    this.index = i;
    this.id = data._id;
    this.name = data.name;
    if(data.icon.length > 0) {
      data.icon.forEach((element, index) => {
        this.filesupload.push({id: element._id});
        data.icon[index].type = true;
        if(data.icon.length == (index + 1)) {
          this.iconData = data.icon;
        }
      });
    }
    else {
      this.iconData = data.icon;
    }
    this.categoryId = data.categoryId;
    if(this.dropDownData[0] != undefined && this.dropDownData[0].id == '-') {
      this.dropDownData.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    if(this.iconData.length > 0) {
      this.filesupload = this.filesupload.filter(x => x.file != undefined && x.filename != undefined);
      this.iconData.forEach(element => {
        var ind = this.filesupload.findIndex(x => x.id == element._id || x.id == element.id);
        if(ind != -1) {
          element.id = ind;
        }
        delete element.image;
        delete element.type;
      });

      this.Form.value.icon = this.iconData;
      // this.Form.value.deleteId = this.deleteId;
      this.Form.value.updateId = this.updateId;

      this.subCategoryService.update(this.Form.value, this.id, this.filesupload).then(data => {
        if(data.flag == true) {
          this.subCategoryList[this.index] = data.data;
          this.toastr.successToastr(data.message, 'Sub Category Updated!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.clear();
        }
        else {
          this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
          this.modalService.dismissAll();
          this.updatebtn = false;
        }
      });
    }
    else {
      this.toastr.errorToastr('please select atlest one icon!', 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
    }
  }

  delete() {
    this.deletebtn = true;
    this.subCategoryService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.subCategoryList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Sub Category Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  }

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.subCategoryService.active(json, id).then(data => {
      if(data.flag == true) {
        this.subCategoryList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}

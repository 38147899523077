<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Bitmoji Access</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <div class="col-md-1">
                <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> -->
            <div class="col-md-10"></div>
            <div class="col-md-2 search-addbtn">
                <button class="btn btn-outline-primary" (click)="insert(addmodel)">Add New</button>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <!-- <div class="row" *ngFor="let bitmojiAccess of bitmojiAccessList">
			<div class="col-lg-12">
				<div class="card card_custome_width">
					<div class="card-header">
						<h4 class="card-title">{{bitmojiAccess.title}}</h4>
					</div>
                    <div class="card-body pb-1" *ngFor="let value of bitmojiAccess.list; index as i">
                        <div class="card-f-j-a-c">
                            <div><h5>Badge Name: {{value.badgelevel[0].badgeName}}</h5></div>
                            <div><h5>Gender: {{value.gender}}</h5></div> -->
                            <!-- <div><h5>Body: {{value.ageGroup}}</h5></div> -->
                        <!-- </div>
                        
                        <hr>
                        <div id="lightgallery" class="row" *ngFor="let item1 of value.avatars">
                            <a class="col-lg-3 col-md-6 mb-4" href="javascript:void(0);" *ngFor="let item of item1.variation">
                                <img [src]="server + item.path" style="width:150px; height: 150px;">
                                <div class="btn_class"><button type="button" (click)="show(value, item, item1, del, i)" class="btn btn-danger"><i class="fa fa-times-circle"></i></button></div>
                                <div class="left_btn_class"><button type="button" (click)="show(value, item, item1, edit, i)" class="btn btn-primary"><i class="fa fa-pencil"></i></button></div> -->
                                <!-- <div class="btn_class"><button type="button" (click)="show(value, item, '', edit, i)" class="btn btn-primary"><i class="fa fa-edit"></i></button></div> -->
                                <!-- <div><b class="center_cust">{{item.ageGroup}}</b></div>
                            </a>
                            <div class="center_button_class"><button class="btn btn-outline-primary mb-2 mr-2" (click)="show(value, item1._id, '', addavatar, i)">Add New {{bitmojiAccess.title}}</button></div>
                        </div>
                    </div>
                </div>
			</div>
		</div> -->


        <div class="row" *ngFor="let bitmojiAccess of bitmojiAccessList">
            <div class="col-lg-12">
                <div class="card card_custome_width">
                    <div class="card-header">
						<h2>{{bitmojiAccess.badgeLevel}}</h2>
					</div>
                    <div class="card-header" *ngFor="let bitmojiCategory of bitmojiAccess.bitmojiCategory; index as i">
						<h4 class="card-title">{{bitmojiCategory.bitmojicategoryName}}</h4>
                        <div class="col-lg-12">
                            <div class="card card_custome_width card_width">
                                <div class="card-body pb-1" *ngFor="let avatar of bitmojiCategory.avatars; index as i">
                                    <div class="card-f-j-a-c">
                                        <div><h5>Gender: {{avatar.gender}}</h5>
                                            <div id="lightgallery" class="row">
                                                <a class="col-lg-2 col-md-6 mb-4" href="javascript:void(0);" *ngFor="let item of avatar.variation">
                                                    <img [src]="server + item.item.variation[0].path" style="width:150px; height: 150px;">
                                                    <h6>Meekoin : {{item.meekoin == 0 ? 'Free' : item.meekoin}}</h6>
                                                    <div class="btn_class">
                                                        <button type="button" (click)="show(item, item.item.variation[0], item.item, editM, i)" class="btn btn-info">
                                                            <i class="fa fa-edit"></i>
                                                        </button>

                                                        <button type="button" (click)="show(item, item.item.variation[0], item.item, del, i)" class="btn btn-danger">
                                                            <i class="fa fa-times-circle"></i>
                                                        </button>
                                                                                                       
                                                        <button type="button" (click)="show(item, item.item.variation[0], item.item, edit, i)" class="btn btn-primary">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                    </div>

                                                    <!-- <div class="btn_class"><button type="button" (click)="show(value, item, '', edit, i)" class="btn btn-primary"><i class="fa fa-edit"></i></button></div> -->
                                                    <!-- <div><b class="center_cust">{{item.ageGroup}}</b></div> -->
                                                </a>
                                                <!-- <div class="center_button_class"><button class="btn btn-outline-primary mb-2 mr-2" (click)="show(bitmojiCategory, avatar._id, '', addavatar, i)">Add New {{bitmojiAccess.title}}</button></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <!-- <div class="row" *ngFor="let bitmojiAccess of bitmojiAccessList">
			<div class="col-lg-12">
				<div class="card card_custome_width">
					<div class="card-header">
						<h4 class="card-title">Bitmoji Category: {{bitmojiAccess.bitmojicategory[0].name}}</h4>
                        <button class="btn btn-outline-primary mb-2 mr-2" (click)="show(bitmojiAccess, '', addavatar, i)">Add New Bitmoji</button>
                        <h4 class="card-title">Badge Level: {{bitmojiAccess.badgelevel[0].badgeName}}</h4>
					</div>
					<div class="card-body pb-1">
						<div id="lightgallery" class="row">
                            <a class="col-lg-3 col-md-6 mb-4" href="javascript:void(0);" *ngFor="let item of bitmojiAccess.avatars">
                                <img [src]="server + bitmojiAccess.folder + '/' + item.image" style="width:150px; height: 150px;">
                                <div class="btn_class"><button type="button" (click)="show(bitmojiAccess, item, del, i)" class="btn btn-danger"><i class="fa fa-times-circle"></i></button></div>
                            </a>
						</div>
					</div>
				</div>
			</div>
		</div> -->
        <!-- <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div> -->
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #addmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Bitmoji Access</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="add()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Bitmoji Category</label>
                    <ng-select2 
                        [(ngModel)]="bitmojiCategoryId"
                        formControlName="bitmojiCategoryId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a bitmoji category...'">		
                    </ng-select2>
                    <!-- <select class="form-control" formControlName="bitmojiCategoryId" [(ngModel)]="bitmojiCategoryId" id="inputBitmojiCategory">
                        <option *ngFor="let bitmojiCategory of bitmojiCategoryList" value={{bitmojiCategory._id}}>{{bitmojiCategory.name}}</option>
                    </select> -->
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Badge Level</label>
                    <ng-select2 
                        [(ngModel)]="badgeLevelId"
                        formControlName="badgeLevelId"
                        [data]="dropDownData1"
                        [placeholder]="'Please select a badge level...'">		
                    </ng-select2>
                    <!-- <select class="form-control" formControlName="badgeLevelId" [(ngModel)]="badgeLevelId" id="inputBadgeLevel">
                        <option *ngFor="let badgeLevel of badgeLevelList" value={{badgeLevel._id}}>{{badgeLevel.badgeName}}</option>
                    </select> -->
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Gender</label>
                    <ng-select2 
                        [(ngModel)]="gender"
                        formControlName="gender"
                        [data]="genderDropDownData"
                        [placeholder]="'Please select a gender...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Meekoin</label>
                    <input type="text" [(ngModel)]="meekoin" class="form-control" formControlName="meekoin" placeholder="Meekoin" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Age Group</label>
                    <ng-select2 
                        [(ngModel)]="ageGroup"
                        formControlName="ageGroup"
                        [data]="ageGroupData"
                        [placeholder]="'Please select a age group...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="formGLTFFileMultiple" class="form-label">Avatars (GLTF)</label>
                    <input #fileGLTF class="form-control" type="file" (change)="fileGLTFChangeEvent($event, fileGLTF, fileAvatars)" formControlName="avatarsGLTF" id="formGLTFFileMultiple" />
                </div>
                <div class="form-group col-md-6">
                    <label for="formFileMultiple" class="form-label">Avatars (Image)</label>
                    <input #fileAvatars class="form-control" type="file" (change)="fileChangeEvent($event, fileGLTF, fileAvatars)" formControlName="avatars" id="formFileMultiple" />
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Age Group</th>
                                    <th scope="col">GLTF</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let avatar of ageGroupList; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>{{avatar.ageGroup}}</td>
                                    <td><img [src]="avatar.gltf" height="100px" width="100px" style="margin: 3px; object-fit: contain;"></td>
                                    <td><img [src]="avatar.image" height="100px" width="100px" style="margin: 3px; object-fit: contain;"></td>
                                    <td><button type="button" (click)="deleteImage(avatar.id)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <div class="custom-control custom-checkbox mb-3">
                        <input type="checkbox" class="custom-control-input" formControlName="default" (change)="defaultEvent($event.target.checked)" id="customCheckBox1">
                        <label class="custom-control-label flot_right" for="customCheckBox1">Default</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="addbtn" type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Bitmoji</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <!-- <form [formGroup]="Form" (validSubmit)="updateimg()" novalidate> -->
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="formGLTFFileMultiple" class="form-label">Avatars (GLTF)</label>
                    <input #fileGLTF1 class="form-control" type="file" (change)="gltfChangeEvent($event)" id="formGLTFFileMultiple" />
                </div>
                <div class="form-group col-md-6">
                    <label for="formFileMultiple" class="form-label">Avatars (Image)</label>
                    <input #fileAvatars1 class="form-control" type="file" (change)="imageChangeEvent($event)" id="formFileMultiple" />
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" (click)="updateimg()" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        <!-- </form> -->
    </div>
</ng-template>

<ng-template #editM let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Meekoin</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="FormM" (validSubmit)="updateMeekoin()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Meekoin</label>
                    <input type="text" [(ngModel)]="meekoin" formControlName="meekoin" class="form-control" placeholder="Meekoin" pattern="\d*">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #addavatar let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add New Bitmoji</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="addimg()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Bitmoji Category</label>
                    <!-- <select disabled class="form-control" formControlName="bitmojiCategoryId" [(ngModel)]="bitmojiCategoryId" id="inputBitmojiCategory">
                        <option *ngFor="let bitmojiCategory of bitmojiCategoryList" value={{bitmojiCategory._id}}>{{bitmojiCategory.name}}</option>
                    </select> -->
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="bitmojiCategoryId"
                        formControlName="bitmojiCategoryId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a bitmoji category...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Badge Level</label>
                    <!-- <select disabled class="form-control" formControlName="badgeLevelId" [(ngModel)]="badgeLevelId" id="inputBadgeLevel">
                        <option *ngFor="let badgeLevel of badgeLevelList" value={{badgeLevel._id}}>{{badgeLevel.badgeName}}</option>
                    </select> -->
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="badgeLevelId"
                        formControlName="badgeLevelId"
                        [data]="dropDownData1"
                        [placeholder]="'Please select a badge level...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Gender</label>
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="gender"
                        formControlName="gender"
                        [data]="genderDropDownData"
                        [placeholder]="'Please select a gender...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Meekoin</label>
                    <input type="text" [(ngModel)]="meekoin" class="form-control" formControlName="meekoin" placeholder="Meekoin" pattern="\d*">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Age Group</label>
                    <ng-select2 
                        [(ngModel)]="ageGroup"
                        formControlName="ageGroup"
                        [data]="ageGroupData"
                        [placeholder]="'Please select a age group...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="formGLTFFileMultiple" class="form-label">Avatars (GLTF)</label>
                    <input #fileGLTF1 class="form-control" type="file" (change)="fileGLTFChangeEvent($event, fileGLTF1, fileAvatars1)" id="formGLTFFileMultiple" />
                </div>
                <div class="form-group col-md-6">
                    <label for="formFileMultiple" class="form-label">Avatars (Image)</label>
                    <input #fileAvatars1 class="form-control" type="file" (change)="fileChangeEvent($event, fileGLTF1, fileAvatars1)" id="formFileMultiple" />
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Age Group</th>
                                    <th scope="col">GLTF</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let avatar of ageGroupList; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>{{avatar.ageGroup}}</td>
                                    <td><img [src]="avatar.gltf" height="100px" width="100px" style="margin: 3px; object-fit: contain;"></td>
                                    <td><img [src]="avatar.image" height="100px" width="100px" style="margin: 3px; object-fit: contain;"></td>
                                    <td><button type="button" (click)="deleteImage(avatar.id)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <div class="custom-control custom-checkbox mb-3">
                        <input type="checkbox" class="custom-control-input" formControlName="default" [(ngModel)]="default" (change)="defaultEvent($event.target.checked)" id="customCheckBox1">
                        <label class="custom-control-label flot_right" for="customCheckBox1">Default</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Add</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<!-- <ng-template #edit let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update Bitmoji Access</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="update()" novalidate>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Bitmoji Category</label>
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="bitmojiCategoryId"
                        formControlName="bitmojiCategoryId"
                        [data]="dropDownData"
                        [placeholder]="'Please select a bitmoji category...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Badge Level</label>
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="badgeLevelId"
                        formControlName="badgeLevelId"
                        [data]="dropDownData1"
                        [placeholder]="'Please select a badge level...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Gender</label>
                    <ng-select2 
                        [disabled]="true"
                        [(ngModel)]="gender"
                        formControlName="gender"
                        [data]="genderDropDownData"
                        [placeholder]="'Please select a gender...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Age Group</label>
                    <ng-select2 
                        [(ngModel)]="ageGroup"
                        formControlName="ageGroup"
                        [data]="ageGroupData"
                        [placeholder]="'Please select a age group...'">		
                    </ng-select2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="formGLTFFileMultiple" class="form-label">Avatars (GLTF)</label>
                    <input #fileGLTF1 class="form-control" type="file" (change)="fileGLTFChangeEvent($event, fileGLTF1, fileAvatars1)" id="formGLTFFileMultiple" />
                </div>
                <div class="form-group col-md-6">
                    <label for="formFileMultiple" class="form-label">Avatars (Image)</label>
                    <input #fileAvatars1 class="form-control" type="file" (change)="fileChangeEvent($event, fileGLTF1, fileAvatars1)" id="formFileMultiple" />
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Age Group</th>
                                    <th scope="col">GLTF</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let avatar of ageGroupList; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>{{avatar.ageGroup}}</td>
                                    <td><img [src]="avatar.gltf" height="100px" width="100px" style="margin: 3px; object-fit: contain;"></td>
                                    <td><img [src]="avatar.image" height="100px" width="100px" style="margin: 3px; object-fit: contain;"></td>
                                    <td><button type="button" (click)="deleteImage(avatar.id)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <div class="custom-control custom-checkbox mb-3">
                        <input type="checkbox" class="custom-control-input" formControlName="default" [(ngModel)]="default" (change)="defaultEvent($event.target.checked)" id="customCheckBox1">
                        <label class="custom-control-label flot_right" for="customCheckBox1">Default</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="updatebtn" type="submit" class="btn btn-primary">Update</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template> -->

<ng-template #del let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Delete Bitmoji Access</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Are you sure??</p>
    </div>
    <div class="modal-footer">
        <button [disabled]="deletebtn" type="submit" (click)="delete()" class="btn btn-primary">Delete</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
<div class="authincation vh-100">
	<div class="container h-100">
		<div class="row justify-content-center h-100 align-items-center">
			<div class="col-md-6">
				<div class="authincation-content">
					<div class="row no-gutters">
						<div class="col-xl-12">
							<div class="auth-form">
								<div class="text-center mb-3">
									<a [routerLink]="['/admin/index']"><img src="assets/images/logo-full.png" alt=""></a>
								</div>
								<h4 class="text-center mb-4 text-white">Account Locked</h4>
								<form action="[/admin/index]">
									<div class="form-group">
										<label class="text-white"><strong>Password</strong></label>
										<input type="password" class="form-control" value="Password">
									</div>
									<div class="text-center">
										<button type="submit" class="btn bg-white text-primary btn-block">Unlock</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class TestimonialParentService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  active(data, id) {
    return this.httpmanagerService.HttpPut('parent/testimonialactive', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('parent/testimonialpagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('parent/testimonialpagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

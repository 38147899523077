import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { EmailTemplateService } from './email-template.service';
import { EmailConfigurationService } from '../email-configuration/email-configuration.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiConstant } from '../api.constant';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {

  Form: FormGroup;

  emailConfigurationId;
  name;
  subject;
  template;
  id;
  index;
  emailTemplateList = [];
  emailConfigurationList = [];
  ckeConfig: any;

  page = 1;
  selectPageLimit;
  total = "0";
  pageIndex = 0;
  addbtn;
  updatebtn;
  deletebtn;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private emailTemplateService: EmailTemplateService,
    private emailConfigurationService: EmailConfigurationService,
    private toastr: ToastrManager,
    private apiConstant: ApiConstant
  ) { 
    this.form();

    var local = document.createElement("script");
    local.setAttribute("id", "local");
    local.setAttribute("src", "./assets/local.js");
    document.body.appendChild(local);
  }

  form() {
    this.Form = this.formBuilder.group({
      emailConfigurationId: ['-', Validators.required],
      name: ['-', Validators.required],
      subject: ['-', Validators.required],
      template: ['-', Validators.required]
    });
  }

  ngOnInit(): void {
    this.total = "0";
    // this.selectPageLimit = $("#SELECTPAGES").val();
    this.selectPageLimit = this.apiConstant.pagination;
    this.pageIndex = 1;
    this.pagination();

    this.ckeConfig = {
      extraPlugins:
        "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
        "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
        "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
        "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
        "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
        "indentblock,indentlist,justify,link,list,liststyle,magicline," +
        "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
        "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
        "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
        "tabletools,templates,toolbar,undo,wsc,wysiwygarea"
    };

    this.ckeConfig.allowedContent = true;

    this.emailConfigurationList.push({
      id: "-",
      text: "-- Select Email Configuration --",
      disabled: true,
      selected: true
    })
    this.emailConfigurationService.list().then(data => { 
      if(data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.emailConfigurationList.push({
            id: element._id,
            text: element.email
          });
        }
      }
    });
  }

  // onPaginationChange(pagelimit) {
  //   this.pageIndex = 0;
  //   this.selectPageLimit = pagelimit;
  //   this.pagination();
  // }

  pagination() {
    this.emailTemplateService.total().then(data => {
        this.total = data.total;
        this.list();
    });
  }

  loadPage(event) {
    this.pageIndex = event;
    this.list();
  }

  list() {
    var data = {
      skip: (this.pageIndex - 1) * parseInt(this.selectPageLimit),
      limit: parseInt(this.selectPageLimit)
    }
    this.emailTemplateService.pagination(data).then(data => this.emailTemplateList = data.data);
  }

  insert(addmodel) {
    this.modalService.open(addmodel, { size: 'xl' });
    this.clear();
  }

  clear() {
    this.Form.reset();
    this.addbtn = false;
    this.updatebtn = false;
    this.deletebtn = false;
    this.emailConfigurationId = null;
    if(this.emailConfigurationList[0] != undefined && this.emailConfigurationList[0].id != '-') {
      this.emailConfigurationList.unshift({
        id: "-",
        text: "-- Select Email Configuration --",
        disabled: true,
        selected: true
      });
    }
  }

  add() {
    this.addbtn = true;
    this.emailTemplateService.add(this.Form.value).then(data => {
      if(data.flag == true) {;
        this.emailTemplateList.unshift(data.data);
        this.total = (parseInt(this.total) + 1).toString();
        this.toastr.successToastr(data.message, 'Email Template Added!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.addbtn = false; 
        this.modalService.dismissAll();
      }
    });
  }

  show(data, type, i) {
    this.updatebtn = false;
    this.deletebtn = false;
    this.modalService.open(type, { size: 'xl' });
    this.index = i;
    this.id = data._id;
    this.emailConfigurationId = data.emailConfigurationId;
    this.name = data.name;
    this.subject = data.subject;
    this.template = data.template;
    if(this.emailConfigurationList[0] != undefined && this.emailConfigurationList[0].id == '-') {
      this.emailConfigurationList.splice(0, 1);
    }
  }

  update() {
    this.updatebtn = true;
    this.emailTemplateService.update(this.Form.value, this.id).then(data => {
      if(data.flag == true) {
        this.emailTemplateList[this.index] = data.data;
        this.toastr.successToastr(data.message, 'Email Template Updated!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.updatebtn = false;
      }
    });
  }

  delete() {
    this.deletebtn = true;
    this.emailTemplateService.delete(this.id).then(data => {
      if(data.flag == true) {
        this.emailTemplateList.splice(this.index, 1);
        this.total = (parseInt(this.total) - 1).toString();
        this.toastr.successToastr(data.message, 'Email Template Deleted!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.clear();
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
        this.modalService.dismissAll();
        this.deletebtn = false;
      }
    });
  } 

  active(act, id, i) {
    var active = false;
    if(act == false) {
      active = true;
    }

    var json = {
      active: active
    }
    this.emailTemplateService.active(json, id).then(data => {
      if(data.flag == true) {
        this.emailTemplateList[i].active = data.data;
        this.toastr.successToastr(data.message, '', { animate: 'slideFromRight', showCloseButton: true });
      }
      else {
        this.toastr.errorToastr(data.message, 'Opps!', { animate: 'slideFromRight', showCloseButton: true });
      }
    });
  }
}
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="page-titles">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Parent Withdrawal Refer</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">List</a></li>
            </ol>
        </div>
        <div class="row">
            <!-- <form [formGroup]="Form" (validSubmit)="filter()" novalidate> -->
                <!-- <div class="col-md-1">
                    <select class="form-control" (change)="onPaginationChange($event.target.value)" data-placeholder="Select Page" id="SELECTPAGES">
                    <option selected value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <ng-select2
                        [(ngModel)]="statusDDL"
                        [data]="statusData"
                        (valueChanged)="onType($event)"
                        [placeholder]="'Please select a status...'">
                    </ng-select2>
                </div>
                <!-- <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="startDate" formControlName="startDate" ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class=" default-calendar">
                        <div class="form-group">
                            <div class="input-group">
                            <input (click)="d1.toggle()" class="form-control" placeholder="dd-mm-yyyy"
                                    name="dp" [(ngModel)]="endDate" formControlName="endDate" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-3"></div>
                <div class="col-md-4 search-addbtn">
                    <div class="input-group search-area d-xl-inline-flex d-none">
                        <input type="text" class="form-control" id="search" name="search" [(ngModel)]="search" placeholder="Search here...">
                        <div class="input-group-append">
                          <span class="input-group-text search_btn">
                            <button type="button" (click)="searchBtn()"><i class="flaticon-381-search-2"></i></button>
                          </span>
                        </div>
                    </div>
                    <div class="ml-3">
                    </div>
                </div>
            <!-- </form> -->
        </div>
        <div class="col-xl-12 col-xxl-12">
            &nbsp;
        </div>
        <div class="col-xl-12 col-xxl-12">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                            <th scope="col" class="text-left py-2 px-3">#</th>
                            <th scope="col" class="text-left px-1 py-2">Date</th>
                            <th scope="col" class="text-left px-1 py-2">Full Name</th>
                            <th scope="col" class="text-left px-1 py-2">Amount</th>
                            <th scope="col" class="text-left px-1 py-2">Status</th>
                            <th scope="col" class="text-left px-1 py-2">Message</th>
                            <th scope="col" class="text-left px-1 py-2">Withdrawal Request</th>
                        </tr>
                      </thead>
                      <!-- <tbody class="Tbody"> -->
                      <tbody>
                        <tr *ngFor="let withdrawalRefer of withdrawalReferList; index as i">
                            <td scope="row" class="px-3">{{ i + 1 }}</td>
                            <td>{{ withdrawalRefer.date | date: 'medium':'IST' }}</td>
                            <td>{{ withdrawalRefer.fullName }}</td>
                            <td>{{ withdrawalRefer.amount | number : '1.2-2' }}</td>
                            <td>{{ withdrawalRefer.status }}</td>
                            <td>{{ withdrawalRefer.message }}</td>
                            <td class="text-center">
                                <button [disabled]="withdrawalRefer.status != 'Pending' && withdrawalRefer.status != 'In Progress'"
                                    type="button"
                                    (click)="show(withdrawalRefer, withdrawal, i)"
                                    class="btn btn-outline-secondary"
                                >
                                    <i class="fa fa-money" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-xxl-12">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="selectPageLimit" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->

<ng-template #withdrawal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Withdrawal Request</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="Form" (validSubmit)="Withdrawal()" novalidate>
          <div class="row">
            <div class="form-group col-md-12">
                <label>Status</label>
                <ng-select2
                  formControlName="status"
                  [(ngModel)]="status"
                  [data]="statusData2"
                  (valueChanged)="onStatus($event)"
                  [placeholder]="'Please select a status...'">
            </ng-select2>
            </div>
        </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Message</label>
                    <textarea class="form-control" rows="4" formControlName="message" [(ngModel)]="message" id="message"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Submit</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </form>
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import {HttpManagerService}  from '../httpmanager.service';

@Injectable({
  providedIn: 'root'
})
export class BitmojiCategoryService {

  constructor(
    private httpmanagerService : HttpManagerService
  ) { }

  add(data, image) {
    return this.httpmanagerService.HttpPostImage('bitmojicategory', image, JSON.stringify(data), "").then(data => {
      return data;
    });
  }

  list() {
    return this.httpmanagerService.HttpGet('bitmojicategory', "").then(data => {
      return data.data;
    });
  }

  update(data, id, image, oldimage) {
    if(image.length > 0) {
      data.oldimage = oldimage; 
      return this.httpmanagerService.HttpPostImage('bitmojicategory/update', image, JSON.stringify(data), id).then(data => {
        return data;
      });
    }
    else {
      return this.httpmanagerService.HttpPut('bitmojicategory', JSON.stringify(data), id).then(data => {
        return data;
      });
    }
  }

  delete(id) {
    return this.httpmanagerService.HttpDelete('bitmojicategory', id).then(data => {
      return data;
    });
  }

  active(data, id) {
    return this.httpmanagerService.HttpPut('bitmojicategory/active', JSON.stringify(data), id).then(data => {
      return data;
    });
  }

  total() {
    return this.httpmanagerService.HttpGet('bitmojicategory/pagination', "").then(data => {
      return data;
    });
  }

  pagination(data) {
    return this.httpmanagerService.HttpPost('bitmojicategory/pagination', JSON.stringify(data), "").then(data => {
      return data;
    });
  }
}

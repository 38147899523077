import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
/* import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar'; */

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  
  closeResult = '';
  role = '';
  authorization = false;
  constructor(
    private modalService: NgbModal,
    private titleService: Title
    ) {}
  
  status: boolean = false;
  
  toggleIcon: boolean = true;
  
  /* public config: PerfectScrollbarConfigInterface = {}; */
  
  ngOnInit(): void {
    if(localStorage.getItem('department') != null && localStorage.getItem('department') != undefined) {
      this.role = localStorage.getItem('department')
    }

    if(this.role != '') {
      if(this.role == 'Accounting') {
        this.authorization = true;
      }
    }
  }
  
  setDocTitle(title: string) {
    this.titleService.setTitle(title + ' - Meeko');
  }
  
	toggleSideMenu(eventvVar:any) {
	}
	
	open(content) {
		this.modalService.open(content);
	}
	
	toggleLoveIcon() {
		this.toggleIcon = !this.toggleIcon;
	}
	

}
